import React from "react";
import { Select, MenuItem, InputLabel } from "@mui/material";


const index = ({ ClassName, Lable, Error, isRequired, SelectValue, HandleChange, Options, Name, keyName, onBlur, width, variant, multiple, valueKey, disabled, message, extrakey }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: ClassName ? ClassName : 250,
            },
        },
    };
    return (
        // <div className={`col-lg-3 ps-5 my-4  ${ClassName ? ClassName : ''}`}>
        //     <label htmlFor={Lable}>{Lable} {isRequired && <span className="text-danger">*</span>}</label>
        <div className={`${ClassName}`}>
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>{Lable}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
            <Select
                required={isRequired}
                // label={Lable}
                multiple={multiple}
                variant={variant ? variant : "standard"}
                className="w-100"
                size="small"
                value={SelectValue || "" || []}
                onChange={HandleChange}
                name={Name}
                onBlur={onBlur}
                MenuProps={MenuProps}
                disabled={disabled}
            >
                {
                    (Array.isArray(Options) && !!Options.length) &&
                    Options?.map((item, index) => {
                        return <MenuItem label={Lable} key={Name + Lable + index} value={item?._id || item?.id ||
                            // `${item[valueKey]}` ||

                            // item?.value} className='text-capitalize'>  {item[keyName] || item?.name}  {item[extrakey] && <b> - {message||item[extrakey]}</b>}</MenuItem>
                            item?.value} className='text-capitalize'>  {item[keyName] || item?.name}  {item[extrakey]}</MenuItem>
                    }
                    )
                }
            </Select>
            {Error && <p className="text-danger">{Error}</p>}
        </div>
        // </div>
    );
};
export default (index)
