import React, { useState } from 'react'
import Table from '../../../components/Table/Table';
import { Pagination } from '../../../components/pagination/Pagination';
import UseBreakReport from './Hooks/useBreakReport';
import BreakFilter from './Hooks/breakFilter';
import { Spinner } from '../../../components/Spinner/Spinner';
import moment from 'moment';
import UseDateTimeFormat from '../../../hooks/useDateTimeFormat';
import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import DesktopDateTimePicker from '../../../components/DesktopDateTimePicker'
import Modal from '../../../components/Modal'
import EditIcon from '@mui/icons-material/Edit';
import { ToastContainer } from 'react-toastify';
import TimeInput from "../../../components/TimeInput"

const BreakReport = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
  const { data, methods } = UseBreakReport()
  const { spinner, breaksData, controller, filterDate, totalCount,
    breakModal,
    breakForm,
    user
  } = data
  const { handlePagination,
    Export,
    setBreakModal,
    setBreakForm,
    breakUpdate,
  } = methods
  const Edit_Permission = user?.userInfo?.permission?.breaks?.edit?.value

  const TableHead = [
    { label: 'Name', id: '' },
    { label: 'Date', id: '' },
    { label: 'Morning Tea Break', id: '' },
    { label: 'Lunch Break', id: '' },
    { label: 'Afternoon Tea Break', id: '' },
  ]
  // const formatBreakTime = (breaks, breakType) => {
  //   const breakInfo = breaks?.find(breakItem => breakItem?.name?.includes(breakType));
  //   if (breakInfo && breakInfo.breakstartTime && breakInfo.breakendTime) {
  //     const startTime = moment(breakInfo.breakstartTime).format('LT');
  //     const endTime = moment(breakInfo.breakendTime).format('LT');
  //     return `${startTime} - ${endTime}`;
  //   }
  //   return '--';
  // };

  // const formatBreakTime = (breaks, breakType) => {
  //   const breakInfo = breaks?.find(breakItem => breakItem?.name?.includes(breakType));
  //   if (breakInfo && breakInfo.breakstartTime && breakInfo.breakendTime) {
  //     const startTime = moment(breakInfo.breakstartTime);
  //     const endTime = moment(breakInfo.breakendTime);

  //     // Round down to the nearest minute for comparison
  //     const actualDuration = moment.duration(endTime.startOf('minute').diff(startTime.startOf('minute'))).asMinutes();
  //     const specifiedDuration = breakInfo.duration;

  //     const startTimeFormatted = startTime.format('LT');
  //     const endTimeFormatted = endTime.format('LT');

  //     setBreakForm({
  //       startTime: startTime,
  //       endTime: endTime,
  //       breakId: breakInfo?.id
  //     })

  //     const textColor = actualDuration > specifiedDuration ? 'red' : 'black';

  //     return (
  //       <span style={{ color: textColor }}>
  //         {`${startTimeFormatted} - ${endTimeFormatted} (${actualDuration} Minutes)`}
  //       </span>
  //     );
  //   }
  //   return '--';
  // };

  const formatBreakTime = (breaks, breakType) => {
    const breakInfo = breaks?.find((breakItem) => breakItem?.name?.includes(breakType));
    if (breakInfo && breakInfo.breakstartTime && breakInfo.breakendTime) {
      const startTime = moment(breakInfo.breakstartTime);
      const endTime = moment(breakInfo.breakendTime);

      // Round down to the nearest minute for comparison
      const actualDuration = moment
        .duration(endTime.startOf('minute').diff(startTime.startOf('minute')))
        .asMinutes();
      const specifiedDuration = breakInfo.duration;

      const startTimeFormatted = startTime.format('LT');
      const endTimeFormatted = endTime.format('LT');

      const textColor = actualDuration > specifiedDuration ? 'red' : 'black';
      return {
        formattedText: (
          <span style={{ color: textColor }}>
            {`${startTimeFormatted} - ${endTimeFormatted} (${actualDuration} Minutes)`}
          </span>
        ),
        breakForm: {
          startTime: breakInfo.breakstartTime || null,
          endTime: breakInfo.breakendTime || null,
          breakId: breakInfo?.breakId || '',
          shiftBreak_id: breakInfo?.id
        },
      };
    }
    return {
      formattedText: '--',
      breakForm: {
        startTime: null,
        endTime: null,
        breakId: '',
        shiftBreak_id: breakInfo?.id
      }
    };
  };
  // console.log(breakForm, 'breakForm')
  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className="container-fluid py-3">
        <div className='d-flex justify-content-end mb-2'>
          <Button startIcon={<ExitToAppIcon />} variant="contained"
            onClick={() => Export()}
          >Export</Button>
        </div>
        <div className='row'>
          <div className='col-lg-10'>
            <div className="mt-2">
              <table className="table">
                <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                <tbody>
                  {breaksData?.map((item, index) => {
                    return (
                      <tr
                        key={item._id + index}
                        className={item.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}>
                        <td > {item?.name || '--'} </td>
                        <td> {getFormattedDateTime(item.breakDate, 'DD-MM-YYYY') || '--'}</td>
                        <td>
                          {(() => {
                            const { formattedText, breakForm } = formatBreakTime(item.shiftBreaks, 'Morning Tea Break');
                            return (
                              <>
                                {formattedText}
                                {
                                  Edit_Permission &&
                                  <EditIcon className='ms-1' color='primary'
                                    onClick={() => {
                                      setBreakModal((prev) => ({ ...prev, open: true }));
                                      if (breakForm) {
                                        setBreakForm({
                                          startTime: breakForm.startTime ? new Date(breakForm.startTime) : null,
                                          endTime: breakForm.endTime ? new Date(breakForm.endTime) : null,
                                          breakId: breakForm.breakId,
                                          shiftBreak_id: breakForm?.shiftBreak_id,
                                          userId: item?.userId,

                                          breakDate: item?.breakDate
                                        });
                                      }
                                    }}
                                    sx={{ fontSize: '20px', cursor: 'pointer' }}
                                  />
                                }
                              </>
                            );
                          })()}
                        </td>
                        <td>
                          {(() => {
                            const { formattedText, breakForm } = formatBreakTime(item.shiftBreaks, 'Lunch Break');
                            return (
                              <>
                                {formattedText}
                                {
                                  Edit_Permission &&
                                  <EditIcon className='ms-1' color='primary'
                                    onClick={() => {
                                      setBreakModal((prev) => ({ ...prev, open: true }));
                                      if (breakForm) {
                                        setBreakForm({
                                          startTime: breakForm.startTime ? new Date(breakForm.startTime) : null,
                                          endTime: breakForm.endTime ? new Date(breakForm.endTime) : null,
                                          breakId: breakForm.breakId,
                                          shiftBreak_id: breakForm?.shiftBreak_id,
                                          userId: item?.userId,
                                          breakDate: item?.breakDate
                                        });
                                      }
                                    }}
                                    sx={{ fontSize: '20px', cursor: 'pointer' }}
                                  />
                                }
                              </>
                            );
                          })()}
                        </td>
                        <td>
                          {(() => {
                            const { formattedText, breakForm } = formatBreakTime(item.shiftBreaks, 'Afternoon Tea Break');
                            return (
                              <>
                                {formattedText}
                                {
                                  Edit_Permission &&
                                  <EditIcon className='ms-1' color='primary'
                                    onClick={() => {
                                      setBreakModal((prev) => ({ ...prev, open: true }));
                                      if (breakForm) {
                                        setBreakForm({
                                          startTime: breakForm.startTime ? new Date(breakForm.startTime) : null,
                                          endTime: breakForm.endTime ? new Date(breakForm.endTime) : null,
                                          breakId: breakForm.breakId,
                                          shiftBreak_id: breakForm?.shiftBreak_id,
                                          userId: item?.userId,
                                          breakDate: item?.breakDate
                                        });
                                      }
                                    }}
                                    sx={{ fontSize: '20px', cursor: 'pointer' }}
                                  />
                                }
                              </>
                            );
                          })()}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {!breaksData.length && <div className="text-center">No record found</div>}
              <Pagination count={totalCount} controller={controller} setController={handlePagination} />
            </div>

          </div>
          <div className='col-lg-2'>
            <BreakFilter data={data} methods={methods} />
          </div>
        </div>

        {/* Edit Breaks Modal */}
        < Modal
          size="md"
          show={breakModal?.open}
          onHide={() => {
            setBreakModal((prev) => ({ ...prev, open: false }));
            setBreakForm({ startTime: null, endTime: null, shiftBreak_id: '', userId: '' })
          }}
          headerContent={< h5 className="py-2" > {"Edit Break"}</h5 >}
          bodyContent={
            <div className="">
              <div className='row'>
                <div className="col-lg-6 my-2">
                  <TimeInput
                    className="w-100 form-field-design"
                    label={"Start Time"}
                    value={breakForm?.startTime}
                    handleChange={(date) => setBreakForm((pre) => ({ ...pre, startTime: date }))}
                    isRequired={true}
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <TimeInput
                    className="w-100 form-field-design"
                    label={"Start Time"}
                    value={breakForm?.endTime}
                    handleChange={(date) => setBreakForm((pre) => ({ ...pre, endTime: date }))}
                    isRequired={true}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <Button variant="outlined" onClick={() => setBreakModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                  Cancel
                </Button>
                <Button variant="contained" className="ms-2" onClick={breakUpdate}>
                  Update
                </Button>
              </div>
            </div >
          }
        />
      </div>
    </>
  )
}

export default BreakReport
