import { useEffect, useState } from "react"
import { getUserBreaks, getUserBreaksReport } from "../../../../repository/leavePolicyRepo"
import { userFullList } from "../../../../repository/teamManagementRepo"
import { getShiftList, useToGetShiftList } from "../../../../repository/shiftRepo"
import moment from "moment"
import { toast } from "react-toastify"
import { updateBreak } from "../../../../repository/taskRepo"
import useReduxStore from "../../../../hooks/useReduxStore"



const UseBreakReport = () => {
    const [breaksData, setBreaksData] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [agentList, setAgentList] = useState([])
    // const [shiftList, setshiftList] = useState([])
    const [isReset, setIsReset] = useState(false);
    const [filterDate, setFilterDate] = useState('')
    const [shiftList, setShiftList] = useState([])
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
    const [totalCount, setTotalCount] = useState(0)
    const { selector: user } = useReduxStore('auth')

    const handlePagination = (obj) => {
        setController(obj)
    }
    const [breakModal, setBreakModal] = useState({
        open: false,
        data: ""
    })
    const [breakForm, setBreakForm] = useState({
        startTime: null,
        endTime: null,
        shiftBreak_id: '',
        userId: ''
    })
    // const cacheTime = { cacheTime: 10000 }
    // const {
    //     data: shiftList,
    //     error: shiftError,
    //     isFetching: shiftIsFetching,
    //     refetch,
    // } = useToGetShiftList({}, {
    //     enabled: false,
    //     ...cacheTime,
    // })

    useEffect(() => {
        // refetch()
        getAgentList()
        shift()


    }, [])
    // console.log(shiftList?.data?.[0]?._id,'shiftList')
    const [filterInput, setFilterInput] = useState({ agent: [], date: moment().subtract(1, 'days').startOf('day'), endDate: null, shift: '' })
    // console.log(filterInput, 'filterInput')

    useEffect(() => {
        getBreakList()
    }, [isReset, controller.page, controller.rowsPerPage])


    function convertToMidnight(dateString) {
        let date = moment(dateString).utc();
        date.add(1, 'days').startOf('day');
        return date.toISOString();
    }

    // const originalDate = "2024-07-22T18:30:00.000Z";
    const convertedDate = convertToMidnight(filterInput?.date);
    const convertedEndDate = convertToMidnight(filterInput?.endDate);


    const getBreakList = async (id) => {
        try {
            const payLoad = {
                userId: filterInput?.agent?.length ? filterInput?.agent?.map((item) => item?._id) : [],
                startDate: convertedDate,
                endDate: convertedEndDate,
                shiftId: id ? id : filterInput?.shift[0]?._id,
                count: controller.rowsPerPage,
                page: controller.page,
            }
            setSpinner(true)
            let response = await getUserBreaks(payLoad)
            setBreaksData(response.data)
            setFilterDate(filterInput?.date)
            setTotalCount(response.totalCount)
        } catch (error) {
            setBreaksData([])
        }
        setSpinner(false)
    }
    const shift = async () => {

        try {
            let response = await getShiftList({})
            setShiftList(response)
            getBreakList(response.data?.[1]?._id)
            setFilterInput((pre) => ({ ...pre, shift: [response.data?.[1]] }))
        } catch (error) {
            setShiftList([])
        }
    }
    const resetFilter = () => {
        setFilterInput({ agent: '', date: moment().subtract(1, 'days').startOf('day'), endDate: null, shift: '', })
        setIsReset(true)

    }
    const getAgentList = async (groupId) => {
        // const teamIds = groupId?.map(item => item._id);
        // let payload = {
        //     team_id: teamIds,
        // };
        try {
            setSpinner(true)
            let response = await userFullList({})
            setAgentList(response.data)

            //   response?.data?.forEach((item) => {
            //     if (item?._id === user?.userInfo?._id) {
            //       setTaskListInput((prevProps) => ({ ...prevProps, agent: [item] }));
            //     }
            //   });
        } catch (error) {
            setAgentList([])
            //   setTaskListInput((prevProps) => ({ ...prevProps, agent: '' }))
        }
        setSpinner(false)
    }
    const Export = async () => {
        const payload = {
            userId: filterInput?.agent?.length ? filterInput?.agent?.map((item) => item?._id) : [],
            startDate: convertedDate,
            endDate: convertedEndDate,
            shiftId: filterInput?.shift[0]?._id,
            // count: controller.rowsPerPage,
            // page: controller.page,
        }
        try {
            setSpinner(true)
            let response = await getUserBreaksReport(payload)
            window.open(response.data.fileName);
            let tempLink = document.createElement('a');
            tempLink.setAttribute('download', response.data.fileName);
            tempLink.click();

        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    const breakUpdate = async () => {
        if (!breakForm?.startTime && !breakForm?.endTime) return toast.warn('Please select start time or end time')
        if (!breakForm?.startTime) return toast.warn('Please select start time')
        if (!breakForm?.endTime) return toast.warn('Please select end time')
        if (breakForm?.startTime > breakForm?.endTime) return toast.warn('Break end time should be greater than break start time')

        let startTime = moment.utc(breakForm?.startTime).format("HH:mm")
        let endTime = moment.utc(breakForm?.endTime).format("HH:mm")
        const fullStartTime = moment.utc(breakForm?.breakDate).set({ hour: startTime.split(":")[0], minutes: startTime.split(":")[1] })
        const fullEndTime = moment.utc(breakForm?.breakDate).set({ hour: endTime.split(":")[0], minutes: endTime.split(":")[1] })

        const payLoad = {
            // breakId: breakForm?.breakId,
            // startTime: moment(breakForm?.startTime).utc(),
            // endTime: moment(breakForm?.endTime).utc(),
            startTime: fullStartTime,
            endTime: fullEndTime,

            shiftBreak_id: breakForm?.shiftBreak_id,
            user_id: breakForm?.userId
        }
        if (breakForm?.breakId) {
            payLoad.breakId = breakForm?.breakId
        }

        // console.log(payLoad, 'payLoad')
        // return
        try {
            setSpinner(true)
            let response = await updateBreak(payLoad)
            toast.success(response.message);
            getBreakList()
            setBreakModal((prev) => ({ ...prev, open: false }))
            setBreakForm({ startTime: null, endTime: null, shiftBreak_id: '', userId: '' })


        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    return {
        data: {
            spinner,
            breaksData,
            controller,
            agentList,
            filterInput,
            shiftList,
            filterDate,
            totalCount,
            breakModal,
            breakForm,
            user
        },
        methods: {
            handlePagination,
            setFilterInput,
            resetFilter,
            getBreakList,
            Export,
            setBreakModal,
            setBreakForm,
            breakUpdate
        }
    }
}

export default UseBreakReport
