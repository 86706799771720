import { toast } from "react-toastify"
import { getTeamMemberList, JobList, UpdateJobDetails } from "../../../repository/teamManagementRepo"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { requiredMessage, requiredSelect } from "../../../utils"
import { getAllShiftList } from "../../../repository/shiftRepo"
import { getAllRoleList } from "../../../repository/auth"
import moment from "moment"
import useReduxStore from "../../../hooks/useReduxStore"


const UseJobDetails = () => {
    const [jobDetailsModal, setJobDetailsModal] = useState({
        open: false,
        data: ""
    })
    const [jobDetailsInputs, setJobDetailsInputs] = useState({
        designation: "",
        dateOfJoining: '',
        elCalculate: false,
        shift: "",
        empType: '',
        role_id: '',
        reportingManager: null,
        action: "",
        probationDays: '',
    })
    const [error, setError] = useState({})
    const [spinner, setSpinner] = useState(false)
    const [allShiftList, setAllShiftList] = useState([])
    const [allRolesList, setAllRoleList] = useState([])
    const [reportManagerData, setReportManagerData] = useState([])

    const [jobDetailsList, setJobDetailsList] = useState([])
    const { agentId } = useParams()
    const { pathname } = useLocation()
    const splitLocation = pathname.split('/')
    const { selector: user } = useReduxStore('auth')



    useEffect(() => {
        if (splitLocation?.[3] === 'job-details') {
            getDetails(agentId)
        }
        AllShiftList()
        getAllRolesList()
        getReportManagers()

    }, [])
    // console.log(jobDetailsList?.[0], 'jobDetailsList')
    const AllShiftList = async () => {
        try {
            setSpinner(true)
            let response = await getAllShiftList()
            setAllShiftList(response.data)
        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false)
    }
    const getAllRolesList = async () => {
        try {
            setSpinner(true)
            let response = await getAllRoleList()
            setAllRoleList(response.data)
        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false)
    }
    const getReportManagers = async () => {
        try {
            setSpinner(true)
            let response = await getTeamMemberList({ count: 1000 })
            setReportManagerData(response?.data)
        } catch (error) {
            //  toast.error(error.message)
        }
        setSpinner(false)
    }
    const getDetails = async (agentId) => {
        const payload = {
            userId: agentId
        }
        try {
            setSpinner(true)
            let response = await JobList(payload)
            setJobDetailsList(response?.data)

            if (splitLocation?.[3] !== 'job-details') {
                const { designation, dateOfJoining, isStartElCalculate, shift_id, employeeType, role_id, reportingManager_id, probationDays } = response?.data?.[0]
                setJobDetailsInputs({
                    designation: designation,
                    dateOfJoining: dateOfJoining ? new Date(dateOfJoining) : null,
                    elCalculate: isStartElCalculate,
                    shift: shift_id,
                    empType: employeeType,
                    role_id: role_id,
                    reportingManager: reportingManager_id,
                    probationDays: probationDays,
                })
            }

        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false)
    }
    const clear = () => {
        setJobDetailsInputs({
            designation: "",
            dateOfJoining: '',
            elCalculate: false,
            shift: "",
            empType: '',
            role_id: '',
            reportingManager: null,
            action: "",
            probationDays: '',
        })
    }
    const getJobDetails = (id) => {

        if (splitLocation?.[3] === 'job-details') {
            const { designation, dateOfJoining, isStartElCalculate, shift_id, employeeType, role_id, reportingManager_id, probationDays } = jobDetailsList?.[0]
            setJobDetailsInputs({
                designation: designation,
                dateOfJoining: dateOfJoining ? new Date(dateOfJoining) : null,
                elCalculate: isStartElCalculate,
                shift: shift_id,
                empType: employeeType,
                role_id: role_id,
                reportingManager: reportingManager_id,
                probationDays: probationDays,
            })
            setJobDetailsModal((prev) => ({ ...prev, open: true }))
        }
        else {
            getDetails(id)
            setJobDetailsModal((prev) => ({ ...prev, open: true }))
        }

    }
    const handleBlur = (event) => {
        const { name, value } = event.target
        // Check if value is a string before calling .trim()
        if (typeof value === 'string' && !value.trim()?.length) {
            setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
        }
        // !value?.trim()?.length && setError(pre => ({ ...pre, [name]: errorMessage[name] }))
    }
    const errorMessage = {
        role_id: requiredSelect('role'),
        dateOfJoining: requiredSelect('date of joining'),
        probationDays: requiredMessage('probation days'),
        // status: requiredMessage('status'),
        empType: requiredMessage('employee type'),
        shift: requiredSelect('shift'),


    }
    const jobDetailisValid = () => {
        const errorObj = {}
        if (!jobDetailsInputs.dateOfJoining) {
            errorObj['dateOfJoining'] = errorMessage['dateOfJoining']
        }
        if (!jobDetailsInputs.empType) {
            errorObj['empType'] = errorMessage['empType']
        }
        if (!jobDetailsInputs.role_id) {
            errorObj['role_id'] = errorMessage['role_id']
        }
        if (!jobDetailsInputs.probationDays && jobDetailsInputs.probationDays !== 0) {
            errorObj['probationDays'] = errorMessage['probationDays'];
        }
        if (!jobDetailsInputs.shift) {
            errorObj['shift'] = errorMessage['shift']
        }
        setError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const handleInputChange = (event, index = null, arrayName = null) => {
        const { name, value } = event.target;
        // console.log(name, value, 'LLLLLLLL')

        setJobDetailsInputs((prevProps) => {
            if (arrayName !== null && index !== null) {
                // Handle array of objects
                const newArray = [...prevProps[arrayName]];
                newArray[index] = {
                    ...newArray[index],
                    [name]: value,
                };
                return {
                    ...prevProps,
                    [arrayName]: newArray,
                };
            } else {
                // Handle simple fields
                return {
                    ...prevProps,
                    [name]: value,
                };
            }
        });

        if (typeof value === 'string' && value.trim()?.length > 0) {
            setError((prevErrors) => {
                if (arrayName !== null && index !== null) {
                    if (prevErrors[arrayName] && prevErrors[arrayName][index]) {
                        delete prevErrors[arrayName][index][name];
                        if (Object.keys(prevErrors[arrayName][index])?.length === 0) {
                            delete prevErrors[arrayName][index];
                        }
                    }
                } else {
                    delete prevErrors[name];
                }
                return { ...prevErrors };
            });
        }
    };
    const handleJobDetailupdate = async (id) => {
        if (!jobDetailisValid()) return
        const payload = {
            _id: id,
            designation: jobDetailsInputs.designation,
            dateOfJoining: moment(jobDetailsInputs?.dateOfJoining).format('MM-DD-YYYY'),
            shift_id: jobDetailsInputs.shift,
            role_id: jobDetailsInputs.role_id,
            reportingManager_id: jobDetailsInputs.reportingManager,
            probationDays: jobDetailsInputs?.probationDays,
            employeeType: jobDetailsInputs?.empType,
            isStartElCalculate: jobDetailsInputs?.elCalculate,
        }
        // console.log(payload, 'payload')
        // return
        setSpinner(true)
        try {
            let response = await UpdateJobDetails(payload)
            toast.success(response.message)
            getDetails(id)
            clear()
            setJobDetailsModal((prev) => ({ ...prev, open: false }))
        } catch (err) {

            toast.error(err.message)
        }
        setSpinner(false)
    }
    const handleDateChange = (date, field, index = null, arrayName = null) => {
        setJobDetailsInputs((prev) => {
            if (arrayName && index !== null) {
                // Handle date change for array of objects
                const updatedArray = [...prev[arrayName]];
                updatedArray[index] = {
                    ...updatedArray[index],
                    [field]: date
                };
                return {
                    ...prev,
                    [arrayName]: updatedArray
                };
            } else {
                // Handle date change for simple fields
                return {
                    ...prev,
                    [field]: date
                };
            }
        });
    };
    return {
        data: {
            jobDetailsModal,
            spinner,
            jobDetailsList,
            jobDetailsInputs,
            error,
            allShiftList,
            allRolesList,
            reportManagerData,
            user,
            splitLocation
        },
        methods: {
            handleBlur,
            setJobDetailsInputs,
            setError,
            setJobDetailsModal,
            handleInputChange,
            handleDateChange,
            handleJobDetailupdate,
            clear,
            getJobDetails
        }
    }
}

export default UseJobDetails