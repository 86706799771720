import React, { useState } from 'react'
import useShiftAddUpdateHook from './Hooks/useShiftAddUpdateHook'
import moment from 'moment'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { Outlet, useLocation, NavLink } from 'react-router-dom'
import { Tabs, Tab, AppBar, } from '@mui/material';

import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import { HolidayType } from '../../../constants'
import EditIcon from '@mui/icons-material/Edit';
import { Spinner } from '../../../components/Spinner/Spinner'

const ShiftDetails = () => {
  const { data, methods } = useShiftAddUpdateHook()
  const {
    spinner,
    shiftForm,
    CompDaysObj,
    pubHoliDaysObj,
    restDaysObj,
    offDaysObj,
    calendars,
    value,
    calendarViewData
  } = data

  const {
    handleCheckDays,
    focusHandle,
    bgHandle,
    setDayDetailModal,
    calculateWorkingDays,
    setValue,
    createCalendarForView

  } = methods


  const naviagte = useNavigate()


  // console.log(shiftForm, 'shiftForm')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log(calendars,'calendars')
  // const renderData = (dataType) => {
  //   let hasRecords = false;
  //   // console.log(calendarData, 'calendarData')
  //   return (
  //     <div>
  //       {calendarData?.map((item, index) =>
  //         item?.days?.map((inner, innerIndex) => {
  //           if (inner?.details &&
  //             ((dataType === 'publicHoliday' && inner?.publicHoliday) ||
  //               (dataType === 'compensationDay' && inner?.compensationDay))) {
  //             hasRecords = true;
  //             return (
  //               <div key={`${index}-${innerIndex}`}>
  //                 <b dangerouslySetInnerHTML={{ __html: `${inner.date} <sup>${inner.dayOfWeek}</sup> ${item.monthName} ${item.year}` }} />
  //                 <p dangerouslySetInnerHTML={{ __html: inner.details }}></p>
  //               </div>
  //             );
  //           }
  //           return null;
  //         })
  //       )}
  //       {!hasRecords && (<div><b>N/A</b></div>)}
  //     </div>
  //   );
  // };
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const handleYear = (year) => {
    createCalendarForView(year?.calendarData)
  }
  // console.log(calendars?.length,'lllllllllllllllllllll')
  return (
    <>
    {spinner && <Spinner />}
      <div className="contact">
        <div className="container bg">
          <div className="row">
            <div className="col-lg-12 pt-2">
              <div className="col-lg-12 ms-4">
                {/* Basic Details  */}
                <div className="row my-5">
                  <div className="col-lg-5 ">
                    <p>
                      <span className="fw-bold">Shift Name :</span> <span className="text-muted">{shiftForm?.shiftName ? shiftForm?.shiftName : '--'}</span>
                    </p>

                    <p>
                      <span className="fw-bold">Time Zone :</span> <span className="text-muted">{shiftForm?.timeZone?.[0]?.timezone || "--"}</span>
                    </p>
                    <p>
                      <span className="fw-bold">Start Time :</span> <span className="text-muted">{moment(shiftForm?.startTiming).format('LT') || '--'}</span>
                    </p>
                    <p>
                      <span className="fw-bold">End Time  :</span> <span className="text-muted">{moment(shiftForm?.endTiming).format('LT') || "--"}</span>
                    </p>
                  </div>
                  <div className="col-lg-5 ">
                    <div className='d-flex'>
                      <span className="fw-bold">Description :</span>
                      <span className="text-muted ms-1 text-wrap" dangerouslySetInnerHTML={{ __html: shiftForm?.description }}></span>
                    </div>
                    <p>
                      <span className="fw-bold">Grace In :</span> <span className="text-muted">{shiftForm?.graceIn ? shiftForm?.graceIn : "--"}</span>
                    </p>
                    <p>
                      <span className="fw-bold">Grace Out :</span> <span className="text-muted">{shiftForm?.graceOut ? shiftForm?.graceOut : "--"}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-end m-3'>
                <Button className="dropDownButtons11" onClick={() => naviagte('add-calendar')}>
                  <AddIcon /> Add Calendar
                </Button>
              </div>
            </div>
          </div>
        </div>
        {
          calendars?.length>0 &&
        <div className="container bg my-4">
          <div className="border" style={{ backgroundColor: 'white' }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {
                      calendars?.map((item) =>
                        <Tab label={item?.year} value={item?.year} onClick={() => handleYear(item)} />
                      )
                    }
                  </TabList>
                </Box>
                {
                  calendars?.map((item) =>
                    <TabPanel value={item?.year}>
                      <div className='row'>
                        <div className="col-12 text-end">
                          <EditIcon className='fs-4  pointer' onClick={() => naviagte(`${item?._id}`)} color="primary" titleAccess='Edit' />
                        </div>
                        <div className="col-12">
                          <div className="row">

                            {/* {
                               calendars?.map((item, index) =>{
                                if(item?.year===value)
                                  
                                  item?.calendarData?.map((item, index) =>


                                    <>
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xlg-4 p-3">
                                        <div className="border rounded h-100 pb-5">
                                          <div className='calendar-header p-2'>
                                            <span className='fw-bold'>{item?.year}</span>
                                            <span className='fw-bold'>{item?.monthName}</span>
                                          </div>
                                          <div className='d-flex flex-column justify-content-center'>
                                            <div className='calendar-week-header mt-2'>
                                              {
                                                weekdays.map((weekday, index) => {
                                                  return <div className="text-center fw-bold" key={index}><p className='m-0 p-0'>{weekday}</p></div>
                                                })
                                              }
                                            </div>
                                            <div className='d-flex flex-wrap'>
                                              {
                                                item?.days?.map((innerItem, innerIndex) => (
                                                  <div className='calendar-boxx' key={`checkbox-${index}-${innerIndex}`}>
                                                    {innerItem.isDay ? <div></div> :
                                                      <>
                                                        <input
                                                          type="checkbox"
                                                          id={`checkbox-${index}-${innerIndex}`}
                                                          onChange={(e) => {
                                                            if (!innerItem?.publicHoliday && pubHoliDaysObj.pubHoliDay) {
                                                              if (!innerItem?.compensationDay && !innerItem?.restDay && !innerItem?.offDay) {
                                                                setDayDetailModal((prev) => ({
                                                                  ...prev,
                                                                  open: true,
                                                                  type: "PublicHoliDay",
                                                                  index,
                                                                  innerIndex,
                                                                  event: e,
                                                                }));
                                                              }
                                                            }
                                                            if (!innerItem?.compensationDay && CompDaysObj.compDay) {
                                                              if (!innerItem?.publicHoliday && !innerItem?.restDay && !innerItem?.offDay) {
                                                                setDayDetailModal((prev) => ({
                                                                  ...prev,
                                                                  open: true,
                                                                  type: "CompensationDay",
                                                                  index,
                                                                  innerIndex,
                                                                  event: e,
                                                                }));
                                                              }
                                                            }
                                                            handleCheckDays(e, index, innerIndex)
                                                          }}
                                                          style={{ display: 'none' }}
                                                          disabled={
                                                            !(
                                                              restDaysObj.restDay ||
                                                              offDaysObj.offDay ||
                                                              CompDaysObj.compDay ||
                                                              pubHoliDaysObj.pubHoliDay
                                                            )
                                                          }
                                                          checked={(
                                                            (innerItem?.compensationDay || CompDaysObj.compDay) ||
                                                            (innerItem?.publicHoliday || pubHoliDaysObj.pubHoliDay) ||
                                                            innerItem?.restDay ||
                                                            innerItem?.offDay
                                                          )}
                                                        />
                                                        <label
                                                          htmlFor={`checkbox-${index}-${innerIndex}`}
                                                          className="calendar-custom-date-box"
                                                          style={{
                                                            backgroundColor: bgHandle(innerItem),
                                                            border: focusHandle(),
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          <span className=''>{innerItem.date}</span>
                                                        </label>
                                                      </>
                                                    }
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div className='position-relative'>
                                          <div className='fw-bold position-absolute bottom-0 start-0 ms-2 mb-2'>Working Days - {monthWorking(item?.monthName)}</div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                               }
                            )
                          } */}




                            {calendars?.map((calendarItem, calendarIndex) => {
                              if (calendarItem?.year === value) {
                                //  updateOffDays(calendarItem?.calendarData); 
                                // createCalendar(calendarItem?.calendarData)
                                return calendarViewData?.map((item, index) => (
                                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xlg-4 p-3">
                                    <div className="border rounded h-100 pb-5">
                                      <div className='calendar-header p-2'>
                                        <span className='fw-bold'>{item?.year}</span>
                                        <span className='fw-bold'>{item?.monthName}</span>
                                      </div>
                                      <div className='d-flex flex-column justify-content-center'>
                                        <div className='calendar-week-header mt-2'>
                                          {
                                            weekdays.map((weekday, index) => {
                                              return <div className="text-center fw-bold" key={index}><p className='m-0 p-0'>{weekday}</p></div>
                                            })
                                          }
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                          {
                                            item?.days?.map((innerItem, innerIndex) => (
                                              <div className='calendar-boxx' key={`checkbox-${index}-${innerIndex}`}>
                                                {innerItem.isDay ? <div></div> :
                                                  <>
                                                    <input
                                                      type="checkbox"
                                                      id={`checkbox-${index}-${innerIndex}`}
                                                      onChange={(e) => {
                                                        if (!innerItem?.publicHoliday && pubHoliDaysObj.pubHoliDay) {
                                                          if (!innerItem?.compensationDay && !innerItem?.restDay && !innerItem?.offDay) {
                                                            setDayDetailModal((prev) => ({
                                                              ...prev,
                                                              open: true,
                                                              type: "PublicHoliDay",
                                                              index,
                                                              innerIndex,
                                                              event: e,
                                                            }));
                                                          }
                                                        }
                                                        if (!innerItem?.compensationDay && CompDaysObj.compDay) {
                                                          if (!innerItem?.publicHoliday && !innerItem?.restDay && !innerItem?.offDay) {
                                                            setDayDetailModal((prev) => ({
                                                              ...prev,
                                                              open: true,
                                                              type: "CompensationDay",
                                                              index,
                                                              innerIndex,
                                                              event: e,
                                                            }));
                                                          }
                                                        }
                                                        handleCheckDays(e, index, innerIndex)
                                                      }}
                                                      style={{ display: 'none' }}
                                                      disabled={
                                                        !(
                                                          restDaysObj.restDay ||
                                                          offDaysObj.offDay ||
                                                          CompDaysObj.compDay ||
                                                          pubHoliDaysObj.pubHoliDay
                                                        )
                                                      }
                                                      checked={(
                                                        (innerItem?.compensationDay || CompDaysObj.compDay) ||
                                                        (innerItem?.publicHoliday || pubHoliDaysObj.pubHoliDay) ||
                                                        innerItem?.restDay ||
                                                        innerItem?.offDay
                                                      )}
                                                    />
                                                    <label
                                                      htmlFor={`checkbox-${index}-${innerIndex}`}
                                                      className="calendar-custom-date-box"
                                                      style={{
                                                        backgroundColor: bgHandle(innerItem),
                                                        border: focusHandle(),
                                                        // cursor: 'pointer',
                                                      }}
                                                    >
                                                      <span className=''>{innerItem.date}</span>
                                                    </label>
                                                  </>
                                                }
                                              </div>
                                            ))
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='position-relative'>
                                      <div className='fw-bold position-absolute bottom-0 start-0 ms-2 mb-2'>Working Days - {calculateWorkingDays(calendarItem?.calendarData, item?.monthName)}</div>
                                    </div>
                                  </div>
                                ));
                              }
                              return null; // Ensure no empty elements are returned for unmatched conditions.
                            })}

                          </div>
                        </div>
                      </div>
                    </TabPanel>

                  )
                }

                {/* <TabPanel value={2024}>Item Two</TabPanel>
                <TabPanel value={2025}>Item Three</TabPanel> */}
              </TabContext>
            </Box>

          </div>
        </div>
        }
      </div>
    </>
  )
}

export default ShiftDetails