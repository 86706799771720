import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";



export const getTeamMemberList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/user/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetTeamMemberList(params, options = {}) {
    return useQuery(['GET_TeamMember_LIST',], () => getTeamMemberList(params), options)
}

// Add TeamMember and Invite user
export const InviteUser = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/inviteUser`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

//  User Toggle Status
export const userStatus = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/toggleStatus`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Delete User
export const deleteUser = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/toggleDelete`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}
export const removeEl = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/removeEarnedLeave`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}



//  User Full List
export const userFullList = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/fullList`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

export function useToGetUserFullList(params, options = {}) {
    return useQuery(['GET_UserFull_LIST',], () => userFullList(params), options)
}

// User Merge 
export const MergeReportingUser = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/user/mergeReportingUser`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

//  Get TeamMember Details,and Update user Api Available in Auth repo



// User Export
export const ExportUserList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/user/export`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};


export const CountryList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/country/list`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const StateList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/state/list`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const JobList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/user/jobDetailsList`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const UpdateJobDetails = async params => {
    const response = await axiosInstance.put(`${baseUrl}/user/updateJobDetails`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

