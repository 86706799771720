import React from 'react'
import { useOutletContext } from 'react-router-dom';
import { Pagination } from '../../components/pagination/Pagination'
import moment from 'moment';
import UseDateTimeFormat from '../../hooks/useDateTimeFormat';

const TimeLogList = () => {
    const { getFormattedDateTime } = UseDateTimeFormat()
    const { data, methods, ApprovalButtons, navigate } = useOutletContext();
    const { controller, totalCount, TimeLogList } = data
    const { setController } = methods

    const logstatusType = (type) => {
        if (type === "APPROVED") {
            return "text-success"
        }
        if (type === "UNAPPROVED") {
            return "text-secondary"
        }
        return "text-info"
    }
    return (
        <div className="">
            <div className="mt-2">
                {/* <Table TableHeadData={TableHead} /> */}
                {/* <table className="table">
                    <tbody> */}
                {TimeLogList?.map((item) => {
                    const duration = moment.duration(item?.workDuration, 'minutes');
                    console.log(item, 'itemmmmmmmmmm')
                    const hours = Math.floor(duration.asHours());
                    const remainingMinutes = duration.minutes();
                    let workDuration = '';
                    if (hours > 0) {
                        workDuration += `${hours} hour${hours > 1 ? 's' : ''}`;
                    }
                    if (remainingMinutes > 0) {
                        workDuration += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
                    }
                    return (
                        <div key={item._id}>
                            <div className="d-flex flex-column p-2 pointer"
                            // onClick={(event) => clickHandler(event, item._id)}
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <b>{item.taskName}</b>
                                    </span>
                                    <span>
                                        <b>Logged Date :</b> {getFormattedDateTime(item?.reportDate, 'DD MMM YYYY')}
                                    </span>
                                </div>
                                <div className="">
                                    <span dangerouslySetInnerHTML={{ __html: item.description?.substr(0, 100) }}></span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="table-link"
                                        onClick={() => navigate(`/projects/${item?.project_id}/ticket`, { state: { projectId: item?.project_id } })}
                                    >
                                        {item.project}
                                    </span>
                                    <span>
                                        <b>Total Worked Time :</b> {workDuration || "--"}
                                    </span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span className='mt-2'>
                                        <b>Actual Status :</b> <span>{item?.actutalStatus}</span>
                                    </span>
                                    <span className='mt-2'>
                                        <b>Hours Type  :</b> <span>{item?.hoursType}</span>
                                    </span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span className='mt-2'>
                                        <b>Status :</b> <span className={`${logstatusType(item?.status)}`}>{item?.status}</span>
                                    </span>
                                    {ApprovalButtons(item)}
                                </div>
                            </div>
                            <hr style={{ margin: '0.01rem' }} />
                        </div>
                    )
                })}
                {/* </tbody>
                  </table> */}
                {!TimeLogList.length && <div className="text-center pt-3">No record found</div>}
                <Pagination count={totalCount} controller={controller} setController={setController} />
            </div>
        </div>
    )
}

export default TimeLogList