import React from 'react'
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined'
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigate } from 'react-router-dom'
import CampaignIcon from '@mui/icons-material/Campaign';
// import AssessmentIcon from '@mui/icons-material/Assessment';
import useReduxStore from '../../hooks/useReduxStore'
const Setting = () => {
  const Navigate = useNavigate()
  const { selector: user } = useReduxStore('auth')
  const { group, leavePolicy, report, workflow, shift, announcement, editWebsiteSceduling, viewWebsiteSceduling } = user?.userInfo?.permission?.setting
  // console.log(user?.userInfo?.permission, 'PPPPPPPP')

  return (
    <>
      <div className="container pt-5">
        <div className="row pt-5 mt-5 cursor-pointer">
          {group?.value &&
            <div className="col-lg-6 ticet mt-5">
              <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/group`)}>
                <Diversity3OutlinedIcon /> Group
              </div>
            </div>
          }
          {
            workflow?.value &&
            <div className="col-lg-6 ticet mt-5 cursor-pointer">
              <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/workflow/59421`)}>
                <AddHomeWorkOutlinedIcon /> Workflow
              </div>
            </div>
          }
          {
            shift?.value &&
            <div className="col-lg-6 ticet mt-5 cursor-pointer">
              <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/shifts`)}>
                <WorkHistoryIcon /> Shift
              </div>
            </div>
          }
          {
            leavePolicy?.value &&
            <div className="col-lg-6 ticet mt-5 cursor-pointer">
              <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/leave-policy`)}>
                <ReceiptLongIcon /> Leave Policy
              </div>
            </div>
          }
          {
            announcement?.value &&
            <div className="col-lg-6 ticet mt-5 cursor-pointer">
              <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/announcement`)}>
                <CampaignIcon /> Announcement
              </div>
            </div>
          }
          {
            viewWebsiteSceduling?.value &&
            <div className="col-lg-6 ticet mt-5 cursor-pointer">
              <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/website-sceduling`)}>
                <WorkHistoryIcon /> Website Sceduling
              </div>
            </div>
          }
          {/* {
            report?.value &&
            <div className="col-lg-6 ticet mt-5 cursor-pointer">
              <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/reports`)}>
                <AssessmentIcon /> Reports
              </div>
            </div>
          } */}
          {/* <div className="col-lg-6 ticet mt-5">
            <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/channel/email`)}>
              <WifiChannelOutlinedIcon /> Channel
            </div>
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col-lg-6 ticet mt-4">
            <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/setting/workflow/59421`)}>
              <AddHomeWorkOutlinedIcon /> Workflow
            </div>
          </div>
          <div className="col-lg-6 ticet mt-4">
            <div className="text-center p-5 bg raduus shadow bordr-right" onClick={() => Navigate(`/accounts/54231`)}>
              <ManageAccountsOutlinedIcon /> Accounts
            </div>
          </div>
        </div> */}
      </div>
      {/* <Outlet /> */}
    </>
  )
}

export default Setting
