import React from 'react'
import { Button } from '@mui/material'
import useWebsiteSceduling from './Hooks/useWebsiteSceduling';
import Modal from '../../../components/Modal'
import DesktopDateTimePicker from '../../../components/DesktopDateTimePicker'
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner/Spinner';
import EditIcon from '@mui/icons-material/Edit';
import SelectInput from '../../../components/SelectInput'
import UseDateTimeFormat from '../../../hooks/useDateTimeFormat';
import { TagsInput } from 'react-tag-input-component';
import useReduxStore from '../../../hooks/useReduxStore';
import TimeInput from "../../../components/TimeInput"

const WebsiteSceduling = () => {
    const { selector: user } = useReduxStore('auth')
    const { editWebsiteSceduling } = user?.userInfo?.permission?.setting
    const { getFormattedDateTime } = UseDateTimeFormat()
    const { methods, data } = useWebsiteSceduling()
    const { setWebScedulingModal,
        handleInputChange,
        handleAddSubmit,
        setWebScedulingInputs,
        clearForm,
        setError,
        handleOptionChange,
    } = methods
    const { webScedulingModal,
        error,
        spinner,
        webScedulingInputs,
        shiftList,
    } = data


    const getDetails = (item) => {
        setWebScedulingInputs((pre) => ({
            ...pre,
            startDateTime: new Date(item?.startTime),
            endDateTime: new Date(item?.endTime),
            shift: item?.shiftApplicable?._id,
            slotDuration: item?.slotDuration,
            email: item?.email,
        }))
    }

    const SlotDurationList = [
        { title: '30 (Minutes)', value: 30 },
        { title: '60 (Minutes)', value: 60 },
    ]
    return (
        <div>
            <ToastContainer />
            {spinner && <Spinner />}
            <div className="container-fluid py-3">
                <div className='wesite-scheduling-page-box'>
                    <div className='d-flex justify-content-between p-2'>
                        <div>Zimo Website Scheduling</div>
                        {editWebsiteSceduling && <span><EditIcon color="primary" className='pointer' onClick={() => {
                            setWebScedulingModal((prev) => ({
                                ...prev, data: data?.listData
                                , open: true
                            })); getDetails(data?.listData
                            )
                        }} /></span>}
                    </div>
                    <hr />
                    <div className='d-flex justify-content-around'>
                        <div className='d-flex flex-column'>
                            <span><b>Available Timings</b></span>
                            <span>{getFormattedDateTime(data?.listData?.startTime, 'LT')} - {getFormattedDateTime(data?.listData?.endTime, 'LT')}</span>
                        </div>
                        <div className='d-flex flex-column'>
                            <span><b>Available Days</b></span>
                            <span>{data?.listData?.shiftApplicable?.name}</span>
                        </div>
                        <div className='d-flex flex-column'>
                            <span><b>Slot Duration</b></span>
                            <span>{data?.listData?.slotDuration}</span>
                        </div>
                        <div className='d-flex flex-column'>
                            <span><b>Emails</b></span>
                            <span>{data?.listData?.email?.map((item) => <div>{item}</div>)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                size="md"
                show={webScedulingModal.open}
                onHide={() => { clearForm(); setWebScedulingModal((prev) => ({ ...prev, open: false })) }}
                headerContent={<h5 className="py-2">{webScedulingModal?.data?._id ? 'Edit' : 'Add'} Website Sceduling</h5>}
                bodyContent={
                    <div className="row p-3">

                        <div className="col-lg-6 mt-3">
                            {/* <DesktopDateTimePicker
                                className="w-100 form-field-design"
                                isRequired={true}
                                Label={'Start Date Time'}
                                handleDateChange={(date) => {
                                    setWebScedulingInputs((prevProps) => ({ ...prevProps, startDateTime: date }))
                                    setError((prev) => ({ ...prev, reportDate: '' }))
                                }}
                                SelectedDate={webScedulingInputs?.startDateTime}
                            /> */}
                            <TimeInput
                                className="w-100 form-field-design"
                                label={"Start Time"}
                                value={webScedulingInputs?.startDateTime}
                                handleChange={(date) => {
                                    setWebScedulingInputs((prevProps) => ({ ...prevProps, startDateTime: date }))
                                    setError((prev) => ({ ...prev, startDateTime: '' }))
                                }}
                                isRequired={true}
                            />
                            {error?.startDateTime && <p className='text-danger'>{error?.startDateTime}</p>}
                        </div>
                        <div className="col-lg-6 mt-3">
                            {/* <DesktopDateTimePicker
                                className="w-100 form-field-design"
                                isRequired={true}
                                Label={'End Date Time'}
                                handleDateChange={(date) => {
                                    setWebScedulingInputs((prevProps) => ({ ...prevProps, endDateTime: date }))
                                    setError((prev) => ({ ...prev, reportDate: '' }))
                                }}
                                SelectedDate={webScedulingInputs?.endDateTime}
                            /> */}
                            <TimeInput
                                className="w-100 form-field-design"
                                label={"End Time"}
                                value={webScedulingInputs?.endDateTime}
                                handleChange={(date) => {
                                    setWebScedulingInputs((prevProps) => ({ ...prevProps, endDateTime: date }))
                                    setError((prev) => ({ ...prev, endDateTime: '' }))
                                }}
                                isRequired={true}
                            />
                            {error?.endDateTime && <p className='text-danger'>{error?.endDateTime}</p>}
                        </div>
                        <div className="col-lg-12 my-2">
                            <SelectInput
                                Lable={'Shift'}
                                Options={shiftList}
                                keyName={'name'}
                                className="form-field-design"
                                Name="shift"
                                SelectValue={webScedulingInputs.shift}
                                HandleChange={(e) => { handleInputChange(e) }}
                                Error={error.shift}
                                variant={'outlined'}
                                isRequired={true}
                            />
                        </div>
                        <div className="col-lg-12 my-2">
                            <SelectInput
                                Lable={'Slot Duration'}
                                Options={SlotDurationList}
                                keyName={'title'}
                                className="form-field-design"
                                Name="slotDuration"
                                SelectValue={webScedulingInputs.slotDuration}
                                HandleChange={(e) => { handleInputChange(e) }}
                                Error={error?.slotDuration}
                                variant={'outlined'}
                                isRequired={true}
                            />
                        </div>
                        <div className="Word-Break mt-4">
                            <TagsInput
                                autocomplete
                                // delimiters={delimiters}
                                value={webScedulingInputs?.email}
                                onChange={(e) => handleOptionChange(e)}
                                name="email"
                                placeHolder="Enter email"
                                classNames={"Word-Break"}
                            />
                        </div>
                        {error.email && <p className="text-danger">{error.email}</p>}

                        <div className='d-flex justify-content-end mt-5'>
                            <Button variant="outlined" onClick={() => { clearForm(); setWebScedulingModal((prev) => ({ ...prev, open: false })) }}>Cancel</Button>
                            {
                                webScedulingModal?.data?._id ?
                                    <Button variant="contained" className='ms-2' onClick={handleAddSubmit}>Update</Button>
                                    :
                                    <Button variant="contained" className='ms-2' onClick={handleAddSubmit}>Submit</Button>
                            }
                        </div>

                    </div>
                }
            />
        </div>
    )
}

export default WebsiteSceduling
