import { Button } from '@mui/material';
import React from 'react'
import Modal from '../../components/Modal'
import TextInput from '../../components/TextField'
import SelectInput from '../../components/SelectInput'
import DateInput from '../../components/DateInput'
import { EmployeeType } from '../../constants'
import { Switch } from '@mui/material'


const EditJobDetailsModal = ({data,methods,id}) => {
  // const { jobDetailsModal,
  //   error, teamMemberInputs, allRolesList, allShiftList, reportManagerData
  // } = data
  // const { setJobDetailsModal,
  //   handleInputChange, handleBlur, handleDateChange, setError
  // } = methods
  // console.log(EmployeeType,'EmployeeType')

  const {
    jobDetailsModal,
    jobDetailsInputs,
    error,
    allShiftList,
    allRolesList,
    reportManagerData,
  }=data
const {
    handleDateChange,
    handleJobDetailupdate,
    handleBlur,
    setError,
    setJobDetailsModal,
    handleInputChange,
    setJobDetailsInputs,
    clear
  } = methods
 
  return (
    <div>
      <Modal
        size="lg"
        show={jobDetailsModal.open}
        onHide={() => {clear(); setJobDetailsModal((prev) => ({ ...prev, open: false })) }}
        headerContent={< h5 className="py-2" >Edit Job Details</h5 >}
        bodyContent={
          <div>
            <div className="row">
              <div className="col-lg-4 mt-4">
                <TextInput
                  Lable="Designation"
                  Name="designation"
                  variant="outlined"
                  ClassName="form-field-design"
                  HandleInputChange={handleInputChange}
                  // onBlur={handleBlur}
                  Value={jobDetailsInputs?.designation}
                  width={100}
                  // Require={true}
                  Error={error?.designation}
                />
              </div>
              <div className="col-lg-4 mt-4">
                <DateInput
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Date of Joining'}
                  handleDateChange={(date) => { handleDateChange(date, 'dateOfJoining'); setError((pre) => ({ ...pre, dateOfJoining: '' })) }}
                  SelectedDate={jobDetailsInputs?.dateOfJoining}
                />
                {error?.dateOfJoining && <span className='text-danger' style={{ fontSize: "11px" }}>{error?.dateOfJoining}</span>}
              </div>
              <div className="col-lg-4 mt-4">
                <SelectInput
                  Lable={'Employee Type'}
                  width={'310px'}
                  Options={EmployeeType}
                  keyName={'title'}
                  className="form-field-design"
                  Name="empType"
                  SelectValue={jobDetailsInputs?.empType}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error?.empType}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-4 mt-4">
                <SelectInput
                  Lable={'Shift'}
                  width={'310px'}
                  Options={allShiftList}
                  keyName={'name'}
                  className="form-field-design"
                  Name="shift"
                  SelectValue={jobDetailsInputs?.shift}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error?.shift}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-4 mt-4">
                <SelectInput
                  Lable={'Roles'}
                  Options={allRolesList}
                  keyName={'roleTitle'}
                  className="form-field-design"
                  Name="role_id"
                  SelectValue={jobDetailsInputs?.role_id}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error?.role_id}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-4 mt-4">
                <SelectInput
                  Lable={'Reporting Manager'}
                  width={'310px'}
                  Options={reportManagerData}
                  keyName={'firstName'}
                  className="form-field-design"
                  Name="reportingManager"
                  SelectValue={jobDetailsInputs?.reportingManager}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error?.reportingManager}
                  variant={'outlined'}
                />
                {jobDetailsInputs?.reportingManager?.length ? <p className='float-end text-danger pointer mt-1' onClick={() => setJobDetailsInputs((pre) => ({ ...pre, reportingManager: null }))}>Remove Reporting Manager</p> : null}
              </div>
              <div className="col-lg-4 mt-4">
                <TextInput
                  Lable="Probation Days"
                  Name="probationDays"
                  variant="outlined"
                  ClassName="form-field-design"
                  HandleInputChange={handleInputChange}
                  onBlur={handleBlur}
                  Value={jobDetailsInputs?.probationDays}
                  width={100}
                  Require={true}
                  type={'number'}
                  Error={error?.probationDays}
                />
              </div>
              <div className="col-lg-4 mt-5">
                <label htmlFor="" style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.6)' }}>Earn leave caluculate</label>
                <Switch checked={jobDetailsInputs?.elCalculate} onChange={(e) => setJobDetailsInputs((pre) => ({ ...pre, elCalculate: e.target.checked }))} />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setJobDetailsModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                Cancel
              </Button>
              <Button variant="contained" className="ms-2" onClick={()=>handleJobDetailupdate(id)}>
                Update
              </Button>
            </div>
          </div >
        }
      />
    </div>
  )
}

export default EditJobDetailsModal