/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { requiredMessage, requiredSelect } from '../../../utils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ReSendInvetation, getAllRoleList, getUserProfile, updateUserProfile, profilePath, personalDoc } from '../../../repository/auth'
import { CountryList, InviteUser, StateList, deleteUser, getTeamMemberList } from '../../../repository/teamManagementRepo'
import { getAllSkillList } from '../../../repository/skillsRepo'
import { getAllTeamList, getTeamActivity } from '../../../repository/teamRepo'
import { useToGetTicketList } from '../../../repository/ticketRepo'
import { initialPermissions, timeZonelist } from '../../../constants'
import useReduxStore from '../../../hooks/useReduxStore'
import { getAllShiftList } from '../../../repository/shiftRepo'
import { LeaveCountsList } from '../../../repository/leavePolicyRepo'
import { uploadFile } from '../../../repository/attendanceRepo'
import moment from 'moment/moment'

const useTamManagementAddUpdate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { selector: user } = useReduxStore('auth')
  const { state } = useLocation()
  const navigate = useNavigate()
  const { agentId } = useParams()
  // console.log(agentId,'agentId')

  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
  const cacheTime = { cacheTime: 10000 }
  const [allRolesList, setAllRoleList] = useState([])
  const [allTeamList, setAllTeamList] = useState([])
  const [allSkillList, setAllSkillList] = useState([])
  const [allShiftList, setAllShiftList] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [userAlreadyFound, setUserAlreadyFound] = useState(false)
  const [slotId, setslotID] = useState(null)
  const [permissions, setPermissions] = useState(initialPermissions)
  const [activityData, setActivityData] = useState([])
  // const [profileImage, setProfileImage] = useState([])
  const [imageFile, setImageFile] = useState([]);
  const [countryOrState, setcountryOrState] = useState({
    country: [],
    state: []
  });

  const [teamMemberInputs, setTeamMemberInputs] = useState({
    // basic Input
    empId: "",
    biometricId: "",
    firstName: '',
    lastName: '',
    email: '',
    dob: null,
    teams: [],
    skills: [],
    timezone: null,
    profileImage: [],
    mobile_no: '',
    // Job Details
    designation: "",
    dateOfJoining: '',
    elCalculate: false,
    shift: "",
    empType: '',
    role_id: '',
    reportingManager: null,
    action: "",
    probationDays: '',


    // Personal Details
    perAddress1: '',
    perAddress2: '',
    perCountry: null,
    perState: null,
    perCity: '',
    perPincode: '',
    sameAddress: false,

    currAddress1: '',
    currAddress2: '',
    currCountry: null,
    currState: null,
    currCity: '',
    currPincode: '',

    document: [{
      docType: '',
      number: '',
      file: []
    }],
    familyDetails: [{
      name: '',
      relation: '',
      contactNo: ''
    }],

    // Other Details
    previousExperience: [{
      company: '',
      designation: '',
      startDate: null,
      endDate: null,
      duration: '',
      reasonToResign: '',
      file: []
    }],

    educationQualification: [{
      course: '',
      specialization: '',
      school_University: '',
      year: null,
      file: []
    }],
    allDetails: []

  })

  const [jobDetailsModal, setJobDetailsModal] = useState({
    open: false,
    data: ""
  })
  // console.log(teamMemberInputs, 'teamMemberInputs')
  const [count, setCount] = useState(10)

  const [error, setError] = useState({})
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  // console.log(error, 'error')
  // console.log(permissions,'permissions')
  const [leavePolicyCount, setLeavePolicyCount] = useState([])
  const [reportManagerData, setReportManagerData] = useState([])
  const currentLocation = useLocation()
  const splitLocation = currentLocation.pathname.split('/')

  useEffect(() => {
    getAllRolesList()
    getAllTeamsList()
    getAllSkillsList()
    AllShiftList()
    if (agentId !== "add") {
      getDetails()
      getLeaveCount()
    }
    getReportManagers()
    getAllCountryList()
  }, [])
  useEffect(() => {
    if (splitLocation[3] === 'timeline' || splitLocation[3] === 'activity') {
      getActivity(splitLocation[3])
    }
  }, [splitLocation[3] === 'timeline', splitLocation[3] === 'activity', count])


  const getLeaveCount = async () => {
    try {
      setSpinner(true)
      let response = await LeaveCountsList(agentId)
      setLeavePolicyCount(response?.data)
    } catch (error) {
      //  toast.error(error.message)
    }
    setSpinner(false)
  }

  const getReportManagers = async () => {
    try {
      setSpinner(true)
      let response = await getTeamMemberList({ count: 1000 })
      setReportManagerData(response?.data)
    } catch (error) {
      //  toast.error(error.message)
    }
    setSpinner(false)
  }


  // console.log(leavePolicyCount,'leavePolicyCount')
  // Listing payLoad
  let payload = {
    agent_id: agentId,
    count: controller.rowsPerPage,
    page: controller.page,
  }
  // if (typeof statusType == "boolean") payload.isActive = statusType;

  // Fetch api
  const {
    data: ticketList,
    error: ticketError,
    isFetching: ticketIsFetching,
    refetch: refetchTicket,
  } = useToGetTicketList(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetchTicket()
  }, [controller])

  // Get All Country List
  const getAllCountryList = async () => {
    try {
      setSpinner(true)
      let response = await CountryList({ isActive: true, isDeleted: false })
      setcountryOrState((pre) => ({ ...pre, country: response.data }))
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  // Get All State List
  const getAllStateList = async (id) => {
    try {
      setSpinner(true)
      let response = await StateList({ countryId: id, count: 100 })
      setcountryOrState((pre) => ({ ...pre, state: response.data }))
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  // Get All Role List
  const getAllRolesList = async () => {
    try {
      setSpinner(true)
      let response = await getAllRoleList()
      setAllRoleList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  // Get All Team List
  const getAllTeamsList = async () => {
    try {
      setSpinner(true)
      let response = await getAllTeamList()
      setAllTeamList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  // Get All Skill List
  const getAllSkillsList = async () => {
    try {
      setSpinner(true)
      let response = await getAllSkillList()
      setAllSkillList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Shift List
  const AllShiftList = async () => {
    try {
      setSpinner(true)
      let response = await getAllShiftList()
      setAllShiftList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  const getDetails = async () => {
    try {
      setSpinner(true)
      let response = await getUserProfile(agentId)
      const { email, firstName, lastName, role_id, teams, skills, timezone, mobile_no, empId, biometricId, permission, designation, shift_id, reportingManager_id, dob, dateOfJoining,
        employeedetails, sameAddress, image, probationDays, employeeType, isStartElCalculate } = response.data
      // Extract and set teams and skills
      const teamIds = teams.map((team) => team._id)
      const teamName = teams.map((team) => team.name)

      const skillIds = skills.map((skill) => skill._id)
      const currentTimeZone = timeZonelist?.find((item) => item.timezone === timezone)
      const mergedPermissions = {};
      for (const key in permissions) {
        if (Object.hasOwnProperty.call(permissions, key)) {
          mergedPermissions[key] = { ...permissions[key] };

          if (permission[key]) {
            for (const action in permissions[key]) {
              if (Object.hasOwnProperty.call(permissions[key], action)) {
                if (permission[key][action]) {
                  mergedPermissions[key][action] = {
                    ...permissions[key][action],
                    value: permission[key][action].value,
                  };
                }
              }
            }
          }
        }
      }

      setPermissions(mergedPermissions)
      setTeamMemberInputs((pre) => ({
        ...pre,
        empId: empId,
        biometricId: biometricId,
        profileImage: image,
        email: email,
        firstName: firstName,
        lastName: lastName,
        designation: designation,
        dob: dob ? new Date(dob) : null,
        dateOfJoining: dateOfJoining ? new Date(dateOfJoining) : null,
        probationDays: probationDays,
        role_id: role_id,
        teams: teamIds,
        skills: skillIds,
        groupName: teamName,
        timezone: currentTimeZone,
        shift: shift_id,
        fullDetails: response?.data,
        reportingManager: reportingManager_id,
        empType: employeeType,
        elCalculate: isStartElCalculate,
        mobile_no: mobile_no,
        // Personal Details
        // document: employeedetails?.personalDocuments,
        document: employeedetails?.personalDocuments?.length ? employeedetails?.personalDocuments?.map(({ _id, ...rest }) => rest) : teamMemberInputs?.document,

        currAddress1: employeedetails?.currentAddress?.addLine1,
        currAddress2: employeedetails?.currentAddress?.addLine2,
        currCountry: employeedetails?.currentAddress?.country,
        currState: employeedetails?.currentAddress?.state,
        currCity: employeedetails?.currentAddress?.city,
        currPincode: employeedetails?.currentAddress?.pinCode,

        perAddress1: employeedetails?.permanentAddress?.addLine1,
        perAddress2: employeedetails?.permanentAddress?.addLine2,
        perCountry: employeedetails?.permanentAddress?.country,
        perState: employeedetails?.permanentAddress?.state,
        perCity: employeedetails?.permanentAddress?.city,
        perPincode: employeedetails?.permanentAddress?.pinCode,
        sameAddress: sameAddress,

        //  Other Details
        // previousExperience: employeedetails?.previousExperience,
        previousExperience: employeedetails?.previousExperience ? employeedetails?.previousExperience?.map(({ _id, ...rest }) => rest) : teamMemberInputs?.previousExperience,
        // educationQualification: employeedetails?.educationQualification
        educationQualification: employeedetails?.educationQualification ? employeedetails?.educationQualification?.map(({ _id, ...rest }) => rest) : teamMemberInputs?.educationQualification,
        familyDetails: employeedetails?.familyDetails ? employeedetails?.familyDetails?.map(({ _id, ...rest }) => rest) : teamMemberInputs?.familyDetails,
        allDetails: response?.data,

      }))


      getAllStateList(employeedetails?.permanentAddress?.country)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  const getActivity = async (type) => {
    // const type=splitLocation[3]=='timeline'?'timeline':'activity'
    const payLoad = {
      user_id: agentId,
      types: type,
      page: 1,
      count: count
    }
    try {
      setSpinner(true)
      let response = await getTeamActivity(payLoad)
      setActivityData(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  const fetchMoreData = () => setCount((prev) => prev + 10);

  //Handle Input value
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target
  //   setTeamMemberInputs((prevProps) => ({ ...prevProps, [name]: value }))
  //   // Check if value is a string before calling .trim()
  //   if (typeof value === 'string' && value.trim().length > 0) {
  //     setError((pre) => {
  //       delete pre[name]
  //       return { ...pre }
  //     })
  //   }
  // }

  const handleInputChange = (event, index = null, arrayName = null) => {
    const { name, value } = event.target;

    setTeamMemberInputs((prevProps) => {
      if (arrayName !== null && index !== null) {
        // Handle array of objects
        const newArray = [...prevProps[arrayName]];
        newArray[index] = {
          ...newArray[index],
          [name]: value,
        };
        return {
          ...prevProps,
          [arrayName]: newArray,
        };
      } else {
        // Handle simple fields
        return {
          ...prevProps,
          [name]: value,
        };
      }
    });

    if (typeof value === 'string' && value.trim()?.length > 0) {
      setError((prevErrors) => {
        if (arrayName !== null && index !== null) {
          if (prevErrors[arrayName] && prevErrors[arrayName][index]) {
            delete prevErrors[arrayName][index][name];
            if (Object.keys(prevErrors[arrayName][index])?.length === 0) {
              delete prevErrors[arrayName][index];
            }
          }
        } else {
          delete prevErrors[name];
        }
        return { ...prevErrors };
      });
    }
  };


  // const handleDateChange = (date, field) => {
  //   setTeamMemberInputs((prev) => {
  //     const updatedShiftForm = { ...prev }
  //     updatedShiftForm[field] = date
  //     return updatedShiftForm
  //   })
  // }
  const handleDateChange = (date, field, index = null, arrayName = null) => {
    setTeamMemberInputs((prev) => {
      if (arrayName && index !== null) {
        // Handle date change for array of objects
        const updatedArray = [...prev[arrayName]];
        updatedArray[index] = {
          ...updatedArray[index],
          [field]: date
        };
        return {
          ...prev,
          [arrayName]: updatedArray
        };
      } else {
        // Handle date change for simple fields
        return {
          ...prev,
          [field]: date
        };
      }
    });
  };

  const handleBlur = (event) => {
    const { name, value } = event.target
    // Check if value is a string before calling .trim()
    if (typeof value === 'string' && !value.trim()?.length) {
      setError((pre) => ({ ...pre, [name]: errorMessage[name] }))
    }
    // !value?.trim()?.length && setError(pre => ({ ...pre, [name]: errorMessage[name] }))
  }

  // Handel Permission
  const handlePermissionChange = (category, key) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };

      if (key === 'viewSelf') {
        updatedPermissions[category] = {
          ...updatedPermissions[category],
          viewSelf: {
            ...updatedPermissions[category].viewSelf,
            value: true,
          },
          viewAll: {
            ...updatedPermissions[category].viewAll,
            value: false,
          },
        };
      } else if (key === 'viewAll') {
        updatedPermissions[category] = {
          ...updatedPermissions[category],
          viewSelf: {
            ...updatedPermissions[category].viewSelf,
            value: false,
          },
          viewAll: {
            ...updatedPermissions[category].viewAll,
            value: true,
          },
        };
      } else {
        updatedPermissions[category] = {
          ...updatedPermissions[category],
          [key]: {
            ...updatedPermissions[category][key],
            value: !prevPermissions[category][key].value,
          },
        };
      }

      return updatedPermissions;
    });
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const addMultiplePreviousExperience = (type) => {

    if (type === 'previousExperience') {
      setTeamMemberInputs((prev) => ({
        ...prev, previousExperience: [
          ...prev.previousExperience,
          {
            company: '',
            designation: '',
            startDate: '',
            endDate: '',
            duration: '',
            reasonToResign: '',
            file: []
          }
        ]
      }))
    }
    if (type === 'educationQualification') {
      setTeamMemberInputs((prev) => ({
        ...prev, educationQualification: [
          ...prev.educationQualification,
          {
            course: '',
            specialization: '',
            school_University: '',
            year: '',
            file: []

          }
        ]
      }))
    }
    if (type === 'familyDetails') {
      setTeamMemberInputs((prev) => ({
        ...prev, familyDetails: [
          ...prev.familyDetails,
          {
            name: '',
            relation: '',
            contactNo: ''
          }
        ]
      }))
    }
    if (type === 'document') {
      setTeamMemberInputs((prev) => ({
        ...prev, document: [
          ...prev.document,
          {
            docType: '',
            number: '',
            file: []
          }
        ]
      }))
    }

    // Clear errors for the newly added task
    // setErrors((prevErrors) => [
    //     ...prevErrors,
    //     {
    //         duration: "",
    //         reason: ""
    //     },
    // ]);
    // }
  }
  // const removePreviousExperience = (index, type) => {
  //   if (type === 'previousExperience') {
  //     setTeamMemberInputs((prev) => ({
  //       ...prev,
  //       previousExperience: prev.previousExperience.filter((_, i) => i !== index)
  //     }));
  //   }
  //   if (type === 'educationQualification') {
  //     setTeamMemberInputs((prev) => ({
  //       ...prev,
  //       educationQualification: prev.educationQualification.filter((_, i) => i !== index)
  //     }));
  //   }
  //   if (type === 'familyDetails') {
  //     setTeamMemberInputs((prev) => ({
  //       ...prev,
  //       familyDetails: prev.familyDetails.filter((_, i) => i !== index)
  //     }));
  //   }
  //   if (type === 'document') {
  //     setTeamMemberInputs((prev) => ({
  //       ...prev,
  //       document: prev.document.filter((_, i) => i !== index)
  //     }));
  //   }
  // };

  const removePreviousExperience = (index, type) => {
    setTeamMemberInputs((prev) => {
      const updatedState = { ...prev };

      if (type === 'previousExperience') {
        updatedState.previousExperience = (prev?.previousExperience || []).filter((_, i) => i !== index);
      }
      if (type === 'educationQualification') {
        updatedState.educationQualification = (prev?.educationQualification || []).filter((_, i) => i !== index);
      }
      if (type === 'familyDetails') {
        updatedState.familyDetails = (prev?.familyDetails || []).filter((_, i) => i !== index);
      }
      if (type === 'document') {
        updatedState.document = (prev?.document || []).filter((_, i) => i !== index);
      }

      return updatedState;
    });
  };



  // -***--------------------***-
  // Error Message
  const errorMessage = {
    empId: requiredMessage('emp Id'),
    // biometricId:requiredMessage("biometric Id"),
    firstName: requiredMessage('first name'),
    mobile_no: requiredMessage('mobile number'),
    // lastName: requiredMessage('last name'),
    email: requiredMessage('email'),
    dob: requiredSelect('date of birth'),
    teams: requiredSelect('team'),
    skills: requiredSelect('skills'),
    timezone: requiredSelect('time-zone'),
    // designation: requiredMessage('designation'),
    role_id: requiredSelect('role'),
    // department: requiredMessage('department'),
    dateOfJoining: requiredSelect('date of joining'),
    probationDays: requiredMessage('probation days'),
    // status: requiredMessage('status'),
    empType: requiredMessage('employee type'),


    shift: requiredSelect('shift'),
    perAddress1: requiredMessage('address line 1'),
    perAddress2: requiredMessage('address line 2'),
    perCountry: requiredMessage('country'),
    perState: requiredMessage('state'),
    perCity: requiredMessage('city'),
    perPincode: requiredMessage('pincode'),
    currAddress1: requiredMessage('address line 1'),
    currAddress2: requiredMessage('address line 2'),
    currCountry: requiredMessage('country'),
    currState: requiredMessage('state'),
    currCity: requiredMessage('city'),
    currPincode: requiredMessage('pincode'),
    name: requiredMessage('name'),
    relation: requiredMessage('relation'),
    contactNo: requiredMessage('contact number'),

    company: requiredMessage('company'),
    designation: requiredMessage('designation'),
    startDate: requiredSelect('start date'),
    endDate: requiredSelect('end date'),
    duration: requiredMessage('duration'),
    reasonToResign: requiredMessage('reason to resign'),

    course: requiredMessage('course'),
    specialization: requiredMessage('specialization'),
    school_University: requiredMessage('school/university'),
    year: requiredSelect('year'),

    docType: requiredMessage('document type'),
    number: requiredMessage('document number'),


  }

  const isValidEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // // Error hendling
  const isValid = () => {
    const errorObj = {}
    if (!teamMemberInputs.empId) {
      errorObj['empId'] = errorMessage['empId']
    }
    if (!teamMemberInputs.firstName) {
      errorObj['firstName'] = errorMessage['firstName']
    }
    if (!teamMemberInputs.mobile_no) {
      errorObj['mobile_no'] = errorMessage['mobile_no']
    }
    if (!teamMemberInputs.email) {
      errorObj['email'] = errorMessage['email']
    } else if (!isValidEmail(teamMemberInputs.email)) {
      errorObj['email'] = 'Invalid email format';
    }
    if (!teamMemberInputs.dob) {
      errorObj['dob'] = errorMessage['dob']
    }
    // if (!teamMemberInputs.role_id) {
    //   errorObj['role_id'] = errorMessage['role_id']
    // }
    if (!teamMemberInputs.teams?.length) {
      errorObj['teams'] = errorMessage['teams']
    }
    if (!teamMemberInputs.skills?.length) {
      errorObj['skills'] = errorMessage['skills']
    }
    if (!teamMemberInputs.timezone) {
      errorObj['timezone'] = errorMessage['timezone']
    }
    // if (!teamMemberInputs.shift) {
    //   errorObj['shift'] = errorMessage['shift']
    // }
    // if (!teamMemberInputs.reportingManager) {
    //   errorObj['reportingManager'] = errorMessage['reportingManager']
    // }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }
  const jobDetailisValid = () => {
    const errorObj = {}
    // if (!teamMemberInputs.department) {
    //   errorObj['department'] = errorMessage['department']
    // }
    // if (!teamMemberInputs.designation) {
    //   errorObj['designation'] = errorMessage['designation']
    // }
    if (!teamMemberInputs.dateOfJoining) {
      errorObj['dateOfJoining'] = errorMessage['dateOfJoining']
    }
    if (!teamMemberInputs.empType) {
      errorObj['empType'] = errorMessage['empType']
    }
    if (!teamMemberInputs.teams?.length) {
      errorObj['teams'] = errorMessage['teams']
    }
    if (!teamMemberInputs.skills?.length) {
      errorObj['skills'] = errorMessage['skills']
    }
    if (!teamMemberInputs.role_id) {
      errorObj['role_id'] = errorMessage['role_id']
    }
    // if (!teamMemberInputs.probationDays) {
    //   errorObj['probationDays'] = errorMessage['probationDays']
    // }
    if (!teamMemberInputs.probationDays && teamMemberInputs.probationDays !== 0) {
      errorObj['probationDays'] = errorMessage['probationDays'];
    }
    if (!teamMemberInputs.shift) {
      errorObj['shift'] = errorMessage['shift']
    }
    // if (!teamMemberInputs.reportingManager) {
    //   errorObj['reportingManager'] = errorMessage['reportingManager']
    // }
    setError(errorObj)
    return !Object.keys(errorObj)?.length
  }

  // const personalDetailisValid = () => {
  //   const errorObj = {}
  //   if (!teamMemberInputs.perAddress1) {
  //     errorObj['perAddress1'] = errorMessage['perAddress1']
  //   }
  //   if (!teamMemberInputs.perAddress2) {
  //     errorObj['perAddress2'] = errorMessage['perAddress2']
  //   }
  //   if (!teamMemberInputs.perCountry) {
  //     errorObj['perCountry'] = errorMessage['perCountry']
  //   }
  //   if (!teamMemberInputs.perState) {
  //     errorObj['perState'] = errorMessage['perState']
  //   }
  //   if (!teamMemberInputs.perCity) {
  //     errorObj['perCity'] = errorMessage['perCity']
  //   }
  //   if (!teamMemberInputs.perPincode) {
  //     errorObj['perPincode'] = errorMessage['perPincode']
  //   }
  //   if (!teamMemberInputs.currAddress1) {
  //     errorObj['currAddress1'] = errorMessage['currAddress1']
  //   }
  //   if (!teamMemberInputs.currAddress2) {
  //     errorObj['currAddress2'] = errorMessage['currAddress2']
  //   }
  //   if (!teamMemberInputs.currCountry) {
  //     errorObj['currCountry'] = errorMessage['currCountry']
  //   }
  //   if (!teamMemberInputs.currState) {
  //     errorObj['currState'] = errorMessage['currState']
  //   }
  //   if (!teamMemberInputs.currCity) {
  //     errorObj['currCity'] = errorMessage['currCity']
  //   }
  //   if (!teamMemberInputs.currPincode) {
  //     errorObj['currPincode'] = errorMessage['currPincode']
  //   }
  //   setError(errorObj)
  //   return !Object.keys(errorObj)?.length
  // }
  // const validateFamilyDetails = () => {
  //   const errorObjArray = [];

  //   for (let i = 0; i < teamMemberInputs.familyDetails.length; i++) {
  //     const family = teamMemberInputs.familyDetails[i];
  //     const errorObj = {};

  //     if (!family.name) {
  //       errorObj['name'] = errorMessage['name'];
  //     }
  //     if (!family.relation) {
  //       errorObj['relation'] = errorMessage['relation'];
  //     }
  //     if (!family.contactNo) {
  //       errorObj['contactNo'] = errorMessage['contactNo'];
  //     }
  //     errorObjArray.push(errorObj);
  //   }
  //   setError((prevErrors) => ({
  //     ...prevErrors,
  //     familyDetails: errorObjArray
  //   }));
  //   return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
  // };
  // const validateDocumentDetails = () => {
  //   const errorObjArray = [];

  //   for (let i = 0; i < teamMemberInputs?.document?.length; i++) {
  //     const family = teamMemberInputs?.document[i];
  //     const errorObj = {};

  //     if (!family.docType) {
  //       errorObj['docType'] = errorMessage['docType'];
  //     }
  //     if (!family.number) {
  //       errorObj['number'] = errorMessage['number'];
  //     }
  //     errorObjArray.push(errorObj);
  //   }
  //   setError((prevErrors) => ({
  //     ...prevErrors,
  //     document: errorObjArray
  //   }));
  //   return errorObjArray.every((errorObj) => Object.keys(errorObj)?.length === 0);
  // };
  // const validatePreviousExperience = () => {
  //   const errorObjArray = [];

  //   for (let i = 0; i < teamMemberInputs.previousExperience?.length; i++) {
  //     const experience = teamMemberInputs.previousExperience[i];
  //     const errorObj = {};

  //     if (!experience.company) {
  //       errorObj['company'] = errorMessage['company'];
  //     }
  //     if (!experience.designation) {
  //       errorObj['designation'] = errorMessage['designation'];
  //     }
  //     if (!experience.startDate) {
  //       errorObj['startDate'] = errorMessage['startDate'];
  //     }
  //     if (!experience.endDate) {
  //       errorObj['endDate'] = errorMessage['endDate'];
  //     }
  //     if (!experience.duration) {
  //       errorObj['duration'] = errorMessage['duration'];
  //     }
  //     if (!experience.reasonToResign) {
  //       errorObj['reasonToResign'] = errorMessage['reasonToResign'];
  //     }
  //     errorObjArray.push(errorObj);
  //   }

  //   setError((prevErrors) => ({
  //     ...prevErrors,
  //     previousExperience: errorObjArray
  //   }));

  //   return errorObjArray.every((errorObj) => Object.keys(errorObj)?.length === 0);
  // };
  // const validateEducationQualification = () => {
  //   const errorObjArray = [];
  //   for (let i = 0; i < teamMemberInputs.educationQualification?.length; i++) {
  //     const experience = teamMemberInputs.educationQualification[i];
  //     const errorObj = {};

  //     if (!experience.course) {
  //       errorObj['course'] = errorMessage['course'];
  //     }
  //     if (!experience.specialization) {
  //       errorObj['specialization'] = errorMessage['specialization'];
  //     }
  //     if (!experience.school_University) {
  //       errorObj['school_University'] = errorMessage['school_University'];
  //     }
  //     if (!experience.year) {
  //       errorObj['year'] = errorMessage['year'];
  //     }
  //     errorObjArray.push(errorObj);
  //   }

  //   setError((prevErrors) => ({
  //     ...prevErrors,
  //     educationQualification: errorObjArray
  //   }));

  //   return errorObjArray.every((errorObj) => Object.keys(errorObj)?.length === 0);
  // };
  const handleCheckSlots = (isCheckedValue, item, index) => {
    if (isCheckedValue) {
      setTeamMemberInputs((prevProps) => ({ ...prevProps, action: item.value }))
      setslotID(index)
    } else {
      setTeamMemberInputs((prevProps) => ({ ...prevProps, action: null }))
      setslotID(null)
    }
  };
  const handleFileChange = async (event, index, type) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files?.length > 5) {
      return toast.warn("You can only select up to 5 files.");
    }

    for (let i = 0; i < files?.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    if (type === 'profileImage') {
      fd.append('type', "PROFILEIMG");
    }
    else {
      fd.append('type', "PERSONALDOC");
    }
    // console.log(type, 'type')

    try {
      setSpinner(true);
      let response = await uploadFile(fd);
      toast.success(response.message);
      // setProfileImage([response?.data?.[0]?.db_name]);
      const newFiles = response.data.map(item => item.db_name);
      setTeamMemberInputs(prev => {
        if (type === 'previousExperience') {
          const updatedExperience = [...prev.previousExperience];
          updatedExperience[index].file = [
            ...(updatedExperience[index].file || []),
            ...newFiles
          ];
          return {
            ...prev,
            previousExperience: updatedExperience
          };
        }
        if (type === 'educationQualification') {
          const updatedEducation = [...prev.educationQualification];
          updatedEducation[index].file = [
            ...(updatedEducation[index].file || []),
            ...newFiles
          ];
          return {
            ...prev,
            educationQualification: updatedEducation
          };
        }
        if (type === 'document') {
          const updatedEducation = [...prev.document];
          updatedEducation[index].file = [
            ...(updatedEducation[index].file || []),
            ...newFiles
          ];
          return {
            ...prev,
            document: updatedEducation
          };
        }
        if (type === 'profileImage') {
          return {
            ...prev,
            profileImage: response.data[0].db_name
          };
        }
        return prev;
      });

      // setProfileImage(response.data.db_name);
      // fileInputRef.current.value = null;

    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  }
  const handleFileUpload = (event) => {

    const data = Array.from(event.target.files);
    const temp = [...imageFile]
    for (const file of data) {
      if (data?.length > 5) return toast.warn("You can select only 5 items.")
      if ((data?.length + imageFile?.length) > 5) return toast.warn("You can select only 5 items.")
      temp.push({ path: URL.createObjectURL(file), file: file });
    }
    setImageFile(temp)
  };
  const DeleteAttachmentFile = (index) => {
    setImageFile((pre) => {
      let temp = [...pre]
      temp.splice(index, 1)
      return temp
    })
  }

  // Handle add submit data..!
  const handleAddSubmit = async (type) => {
    // event.preventDefault()
    if (type === 0) {
      if (!isValid()) return
      handleNext()
    }
    // if (type === 1) {
    //   if (!jobDetailisValid()) return
    //   handleNext()
    // }
    if (type === 2) {
      // if (!validateDocumentDetails() || !personalDetailisValid() || !validateFamilyDetails()) return
      handleNext()
    }
    if (type === 3) {
      // if (!validatePreviousExperience() || !validateEducationQualification()) return
      handleNext()
    }
    if (type === 4) {
      if (!isValid()) {
        return toast.warn("Please Fill  Required Field ")
      }
      else {
        setSpinner(true)
        let payload = {
          // Basic Details
          empId: teamMemberInputs.empId,
          biometricId: teamMemberInputs.biometricId,
          email: teamMemberInputs.email,
          firstName: teamMemberInputs.firstName,
          lastName: teamMemberInputs.lastName,
          teams: [teamMemberInputs.teams],
          skills: teamMemberInputs.skills,
          timezone: teamMemberInputs.timezone?.timezone,
          image: teamMemberInputs?.profileImage?.[0],
          dob: moment(teamMemberInputs?.dob).format('MM-DD-YYYY'),
          mobile_no: teamMemberInputs?.mobile_no,
          // Job Details
          designation: teamMemberInputs.designation,
          dateOfJoining: moment(teamMemberInputs?.dateOfJoining).format('MM-DD-YYYY'),
          shift_id: teamMemberInputs.shift,
          role_id: teamMemberInputs.role_id,
          reportingManager_id: teamMemberInputs.reportingManager,
          probationDays: teamMemberInputs?.probationDays,
          employeeType: teamMemberInputs?.empType,
          isStartElCalculate: teamMemberInputs?.elCalculate,

          // Personal Details
          personalDocuments: teamMemberInputs?.document,
          permanentAddress: {
            addLine1: teamMemberInputs?.perAddress1,
            addLine2: teamMemberInputs?.perAddress2,
            city: teamMemberInputs?.perCity,
            pinCode: teamMemberInputs?.currPincode,
            state: teamMemberInputs?.currState,
            country: teamMemberInputs?.currCountry
          },
          sameAddress: teamMemberInputs?.sameAddress,
          currentAddress: {
            addLine1: teamMemberInputs?.currAddress1,
            addLine2: teamMemberInputs?.currAddress2,
            city: teamMemberInputs?.currCity,
            pinCode: teamMemberInputs?.currPincode,
            state: teamMemberInputs?.currState,
            country: teamMemberInputs?.currCountry,
          },
          familyDetails: teamMemberInputs?.familyDetails,

          // OtherDetails
          previousExperience: teamMemberInputs?.previousExperience,
          educationQualification: teamMemberInputs?.educationQualification,



          permission: permissions,
        }
        // console.log(payload, 'payload')
        // return
        if (teamMemberInputs?.action) {
          payload.action = teamMemberInputs.action
        }
        try {
          let response = await InviteUser(payload)
          navigate('/team-management')
          setTimeout(() => {
            toast.success(response.message)
          }, 500)
        } catch (err) {
          if (err.message === "Account found with this email, You can either recover or create new user") {
            setUserAlreadyFound(true)
          }
          toast.error(err.message)
        }
        setSpinner(false)
      }
    }

  }

  // Handle Update submit data..!
  const sendTeamsInArray = (teams) => {
    if (Array.isArray(teams)) {
      return teams;
    } else if (typeof teams === 'string') {
      return [teams];
    } else {
      return [];
    }
  }

  const handleupdateSubmit = async (type) => {
    // event.preventDefault()
    // if (!isValid()) return
    if (type === 0) {
      if (!isValid()) return
      handleNext()
    }
    if (type === 1) {
      if (!jobDetailisValid()) return
      handleNext()
    }
    if (type === 2) {
      // if (!validateDocumentDetails() || !validateFamilyDetails()) return
      handleNext()
    }
    if (type === 3) {
      // if (!validatePreviousExperience() || !validateEducationQualification()) return
      handleNext()
    }
    if (type === 4) {
      if (!jobDetailisValid() || !isValid()) {
        return toast.warn("Please Fill  Required Field ")
      }
      else {

        let payload = {
          _id: state.teamMemberId,
          // Basic Details
          empId: teamMemberInputs.empId,
          biometricId: teamMemberInputs.biometricId,
          email: teamMemberInputs.email,
          firstName: teamMemberInputs.firstName,
          lastName: teamMemberInputs.lastName,
          teams: sendTeamsInArray(teamMemberInputs.teams),
          skills: teamMemberInputs.skills,
          timezone: teamMemberInputs.timezone?.timezone,
          image: teamMemberInputs?.profileImage ? teamMemberInputs?.profileImage : '',
          dob: teamMemberInputs?.dob ? moment(teamMemberInputs?.dob).format('MM-DD-YYYY') : null,

          // Job Details
          designation: teamMemberInputs.designation,
          dateOfJoining: teamMemberInputs?.dateOfJoining ? moment(teamMemberInputs?.dateOfJoining).format('MM-DD-YYYY') : null,
          shift_id: teamMemberInputs.shift,
          role_id: teamMemberInputs.role_id,
          reportingManager_id: teamMemberInputs.reportingManager,
          probationDays: teamMemberInputs?.probationDays,
          employeeType: teamMemberInputs?.empType,
          isStartElCalculate: teamMemberInputs?.elCalculate,
          mobile_no: teamMemberInputs?.mobile_no,
          // Personal Details
          personalDocuments: teamMemberInputs?.document,
          permanentAddress: {
            addLine1: teamMemberInputs?.perAddress1,
            addLine2: teamMemberInputs?.perAddress2,
            city: teamMemberInputs?.perCity,
            pinCode: teamMemberInputs?.currPincode,
            state: teamMemberInputs?.currState,
            country: teamMemberInputs?.currCountry
          },
          sameAddress: teamMemberInputs?.sameAddress,
          currentAddress: {
            addLine1: teamMemberInputs?.currAddress1,
            addLine2: teamMemberInputs?.currAddress2,
            city: teamMemberInputs?.currCity,
            pinCode: teamMemberInputs?.currPincode,
            state: teamMemberInputs?.currState,
            country: teamMemberInputs?.currCountry,
          },
          familyDetails: teamMemberInputs?.familyDetails,

          // OtherDetails
          previousExperience: teamMemberInputs?.previousExperience,
          educationQualification: teamMemberInputs?.educationQualification,

          permission: permissions,
        }
        // console.log(payload, 'payload Update...........')
        // return
        setSpinner(true)
        try {
          let response = await updateUserProfile(payload)
          navigate('/team-management')
          toast.success(response.message)
        } catch (err) {
          toast.error(err.message)
        }
        setSpinner(false)
      }
    }

  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }

  // handle Delete
  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteUser({
        _id: deleteModal?.data,
        isDeleted: true,
      })
      setDeleteModal({ data: '', open: false })
      setTimeout(() => {
        toast.success(response.message)
      },)
      navigate('/team-management')
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  // Resens Invetation
  const resend = async (id) => {
    try {
      setSpinner(true)
      const response = await ReSendInvetation({ user_id: id })
      toast.success(response.message)
    } catch (error) {
      toast.error(error?.message)
    }
    setSpinner(false)
  }

  const handleRemoveFile = (index, fileIndex, type) => {
    setTeamMemberInputs(prev => {
      if (type === 'previousExperience') {
        const updatedExperience = [...prev.previousExperience];
        updatedExperience[index].file = updatedExperience[index].file.filter((_, i) => i !== fileIndex);
        return {
          ...prev,
          previousExperience: updatedExperience
        };
      }
      if (type === 'educationQualification') {
        const updatedEducation = [...prev.educationQualification];
        updatedEducation[index].file = updatedEducation[index].file.filter((_, i) => i !== fileIndex);
        return {
          ...prev,
          educationQualification: updatedEducation
        };
      }
      if (type === 'document') {
        const updatedDocument = [...prev.document];
        updatedDocument[index].file = updatedDocument[index].file.filter((_, i) => i !== fileIndex);
        return {
          ...prev,
          document: updatedDocument
        };
      }
      if (type === 'profileImage') {
        return {
          ...prev,
          profileImage: ''
        };
      }
      return prev;
    });
  };



  return {
    data: {
      spinner,
      error,
      teamMemberInputs,
      state,
      allRolesList,
      allTeamList,
      allSkillList,
      allShiftList,
      // ================================
      controller,
      isLoading: spinner || ticketIsFetching,
      totalCount: ticketError?.message === 'No record found' ? 0 : ticketList?.totalCount || 0,
      ticketList: ticketError?.message === 'No record found' ? [] : ticketList?.data || [],
      timeZonelist,
      userAlreadyFound,
      slotId,
      TeamPermission: user,
      deleteModal,
      permissions,
      leavePolicyCount,
      activityData,
      reportManagerData,
      activeStep,
      skipped,
      imageFile,
      profilePath,
      personalDoc,
      // profileImage,
      countryOrState,
      jobDetailsModal,
    },
    methods: {
      setJobDetailsModal,
      setTeamMemberInputs,
      handleInputChange,
      handleBlur,
      handleAddSubmit,
      handleupdateSubmit,
      navigate,
      handleCheckSlots,
      handlePagination,
      handleDelete,
      setDeleteModal,
      resend,
      handlePermissionChange,
      setLeavePolicyCount,
      getActivity,
      fetchMoreData,
      setCount,
      handleDateChange,
      setActiveStep,
      setSkipped,
      isStepOptional,
      isStepSkipped,
      handleNext,
      handleBack,
      handleFileChange,
      addMultiplePreviousExperience,
      removePreviousExperience,
      handleFileUpload,
      DeleteAttachmentFile,
      getAllStateList,
      handleRemoveFile,
      setError,

    },
  }
}

export default useTamManagementAddUpdate
