import React from 'react'
import { Button } from "@mui/material";
import TextField from "../../components/TextField"
import { Spinner } from "../Spinner/Spinner"
import { ToastContainer } from 'react-toastify'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Tabs, Tab, AppBar } from '@mui/material'
import { NavLink, Outlet } from 'react-router-dom'
import UseAdminProfile from './Hooks/useAdminProfile';
import Dummy from '../../assets/images/dummy.png'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import UseDateTimeFormat from '../../hooks/useDateTimeFormat';
const AdminProfile = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
  const { data, methods } = UseAdminProfile()
  const {
    location,
    userDetails,
    error,
    userProfileInputs,
    teamMemberInputs,
    profilePath,
    spinner,
    isEdit } = data

  const {
    handleSubmit,
    handleBlur,
    handleInputChange,
    setIsEdit,
    setCount,
    handleFileChange,
    setProfileInputs
  } = methods


  const allTabs = [
    {
      label: 'Personal Details',
      value: `/adminprofile/personal-details`,
    },
    {
      label: 'Job Details',
      value: `/adminprofile/job-details`,
    },
    {
      label: 'Other Details',
      value: `/adminprofile/other-details`,
    },
    {
      label: 'Leave',
      value: `/adminprofile/leave`,
    },
    {
      label: 'Timeline',
      value: `/adminprofile/timeline`,
    },
    {
      label: 'Activity',
      value: `/adminprofile/activity`,
    },
  ]

  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className="contact">
        <div className="container bg">
          <div className="col-lg-12">
            {/* <h4 className='pt-3 text-center'>Profile</h4> */}
            {!isEdit ?
              <>
                <div className='p-4'>
                  <div className="row py-0">
                    <div className="col-sm-12  d-flex justify-content-end">
                      <BorderColorIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => setIsEdit(true)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 pt-4 mt-2 ms-4">
                    <div className="row my-3">
                      <div className='col-lg-2'>
                        <div className=''>
                          <img src={userProfileInputs?.profileImage ? profilePath + userProfileInputs?.profileImage : Dummy} alt="" height={120} width={120} style={{ borderRadius: '50%' }} />
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <p>
                          <span className="fw-bold">EMP ID :</span> <span className="text-muted">{userDetails?.empId || "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Name :</span> <span className="text-muted">{`${userDetails?.firstName && userDetails?.firstName} ${userDetails?.lastName && userDetails?.lastName}`}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Email :</span> <span className="text-muted">{userDetails?.email || "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Mobile Number :</span> <span className="text-muted">{userDetails?.mobile_no || "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Designation :</span> <span className="text-muted">{userDetails?.designation || "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Role :</span> <span className="text-muted">{`${userDetails?.roleData?.roleTitle || "--"}`}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Date of birth :</span> <span className="text-muted">{getFormattedDateTime(teamMemberInputs?.dob, 'DD-MM-YYYY')|| "--"}</span>
                        </p>
                      </div>

                      <div className="col-lg-5">
                        <p>
                          <span className="fw-bold">Biometric Id :</span> <span className="text-muted">{`${userDetails?.biometricId || "--"}`}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Time Zone :</span> <span className="text-muted">{userDetails?.timezone || "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Year :</span> <span className="text-muted">{userDetails?.shiftData?.year || "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Shift :</span> <span className="text-muted">{userDetails?.shiftData?.name || "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">Start :</span> <span className="text-muted">{userDetails?.shiftData?.startTime ?  getFormattedDateTime(userDetails?.shiftData?.startTime, 'LT') : "--"}</span>
                        </p>
                        <p>
                          <span className="fw-bold">End :</span> <span className="text-muted">{userDetails?.shiftData?.endTime ? getFormattedDateTime(userDetails?.shiftData?.endTime, 'LT') : "--"}</span>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

              </>

              :
              <div className='row p-4'>
                <div className='col-lg-12'>
                  {
                    userProfileInputs?.profileImage ?
                      <div className='d-flex justify-content-center position-relative mt-5'>
                        <div>
                          <img src={profilePath + userProfileInputs?.profileImage} alt="" height={150} width={150} style={{ borderRadius: '50%' }} />
                          <div className="position-absolute" style={{ left: '52%', bottom: '-5px', background: '#fff', borderRadius: '50%', padding: '4px' }}>
                            <CameraAltOutlinedIcon className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" />
                            <ul class="dropdown-menu">
                              <input
                                accept="image/*"
                                id="icon-button-file"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                              <Button variant="text" sx={{ color: "black", fontSize: "12px", marginLeft: "10px" }}
                                onClick={() => document.getElementById("icon-button-file").click()} startIcon={<EditIcon />} >
                                Edit
                              </Button>
                              <br />
                              <Button variant="text" sx={{ color: "black", fontSize: "12px", marginLeft: "10px", }}
                                startIcon={<DeleteIcon />}
                                onClick={() => setProfileInputs((pre) => ({ ...pre, profileImage: '' }))}
                              >
                                Delete
                              </Button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      :
                      <div class="Neon Neon-theme-dragdropbox mt-5">
                        <input onChange={(event) => handleFileChange(event)} style={{ zIndex: '999', opacity: '0', width: '320px', height: '200px', position: 'absolute', right: '0px', left: '0px', marginRight: 'auto', marginLeft: 'auto', }} name="files[]" id="filer_input2" type="file" />
                        <div class="Neon-input-dragDrop">
                          <div class="Neon-input-inner">
                            <div class="Neon-input-icon"><CloudUploadIcon className='fs-1' />
                            </div>
                            <div class="Neon-input-text"><h3>Upload Image</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                  }
                </div>
                <div className="col-lg-3 my-5">
                  <TextField
                    Lable="Emp Id"
                    Name="empId"
                    variant="standard"
                    className="col-6"
                    // Require={true}
                    // HandleInputChange={handleInputChange}
                    // onBlur={handleBlur}
                    Value={userProfileInputs.empId}
                    width={100}
                    // Error={error.empId}
                    readOnly={true}
                  />
                </div>
                <div className="col-lg-3 my-5">
                  <TextField
                    Lable="Biometric Id"
                    Name="biometricId"
                    variant="standard"
                    className="col-6"
                    // Require={true}
                    // HandleInputChange={handleInputChange}
                    // onBlur={handleBlur}
                    Value={userProfileInputs.biometricId}
                    width={100}
                    // Error={error.biometricId}
                    readOnly={true}
                  />
                </div>

                <div className="col-lg-3 my-5">
                  <TextField
                    Lable="First Name"
                    Name="first_name"
                    variant="standard"
                    className="col-6"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={userProfileInputs.first_name}
                    width={100}
                    Error={error.first_name}
                  />
                </div>
                {/* <div className="col-lg-2 my-5"></div> */}
                <div className="col-lg-3 my-5">
                  <TextField
                    Lable="Last Name"
                    Name="last_name"
                    variant="standard"
                    className="col-6"
                    // Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={userProfileInputs.last_name}
                    width={100}
                    
                  />
                </div>
                <div className="col-lg-3 my-5">
                  <TextField
                    Lable="Email"
                    Name="email"
                    variant="standard"
                    className="col-6"
                    Require={true}
                    readOnly={true}
                    Value={userProfileInputs?.email}
                    width={100}
                  // Error={error?.email}
                  />
                </div>
                <div className="col-lg-3 my-5">
                  <TextField
                    Lable="Mobile Number"
                    Name="mobile_no"
                    variant="standard"
                    className="col-6"
                    Require={true}
                    HandleInputChange={handleInputChange}
                    onBlur={handleBlur}
                    Value={userProfileInputs?.mobile_no}
                    width={100}
                    Error={error.mobile_no}
                  />
                </div>
                <div className="col-lg-12 mb-5">
                  <div className="row">
                    <div className="text-center my-5">
                      <Button variant='contained' className="px-4 add-cc-btn mt-3" onClick={() => setIsEdit(false)}>Cancel</Button>
                      <Button variant='contained' className="px-4 add-cc-btn mt-3 ms-2" onClick={handleSubmit}>Update</Button>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
        <div className="container bg my-4">
          <div className="border" style={{ backgroundColor: 'white' }}>
            <AppBar position="static" sx={{ background: '#fff' }}>
              <Tabs value={location.pathname}>
                {allTabs.map((item, i) => (
                  <Tab key={i} label={item.label} value={item.value} component={NavLink} to={item.value} onClick={() => setCount(10)} />
                ))}
              </Tabs>
            </AppBar>
            <Outlet context={{ data, methods }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminProfile
