/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { requiredMessage, requiredSelect } from "../../../../utils"
import { toast } from "react-toastify"
import useReduxStore from "../../../../hooks/useReduxStore"
import { SCHEDULE_ADD, SCHEDULE_LIST, UPDATE_ANNOUNCEMENT } from "../../../../repository/announcementRepo"
import { getShiftList } from "../../../../repository/shiftRepo"
import moment from "moment"


const useWebsiteSceduling = () => {
    const [webScedulingModal, setWebScedulingModal] = useState({ data: {}, open: false })
    const [webScedulingInputs, setWebScedulingInputs] = useState({
        startDateTime: null,
        endDateTime: null,
        shift: '',
        slotDuration: '',
        email: [],
    })
    const [listData, setListData] = useState([])
    const [totalCount, settotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: 1 })
    const [error, setError] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [shiftList, setShiftList] = useState([])

    const { selector: user } = useReduxStore('auth')
    // console.log(user,'user')
    // console.log(listData, 'listData')

    const clearForm = () => {
        setError({})
        setWebScedulingInputs({
            startDateTime: null,
            endDateTime: null,
            shift: '',
            slotDuration: '',
            email: [],
        })
    }

    useEffect(() => {
        list()
    }, [controller])

    useEffect(() => {
        shift()
    }, [])

    const shift = async () => {
        const payLoad = {
            isDeleted: false,
            page: 1,
            count: 100
        }
        setSpinner(true)
        try {
            let response = await getShiftList(payLoad)
            setShiftList(response.data)
            setSpinner(false)

        } catch (error) {
            setSpinner(false)
        }
    }

    const list = async () => {
        try {
            const payload = {
                count: controller.rowsPerPage,
                page: controller.page,
                // shiftId: user?.userInfo?.shift_id
            }
            setSpinner(true)
            let response = await SCHEDULE_LIST(payload)
            settotalCount(response.totalCount || 1);
            setListData(response?.data)
            setSpinner(false)

        }
        catch {
            setSpinner(false)
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        setWebScedulingInputs((prevProps) => ({ ...prevProps, [name]: value }));
        // trimmedValue.length &&
        setError((pre) => {
            delete pre[name];
            return { ...pre };
        });
    }

    // const handleOptionChange = (newEmails) => {
    //     setWebScedulingInputs((prevProps) => ({
    //         ...prevProps,
    //         email: newEmails,
    //     }));

    //     setError((prev) => {
    //         delete prev.email; 
    //         return { ...prev };
    //     });
    // };
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleOptionChange = (newEmails) => {
        const validEmails = newEmails.filter(email => emailRegex.test(email));
        if (validEmails.length === newEmails.length) {
            setWebScedulingInputs((prevProps) => ({
                ...prevProps,
                email: validEmails,
            }));
            setError((prev) => {
                delete prev.email;
                return { ...prev };
            });
        } else {
            setError((prev) => ({
                ...prev,
                email: 'Please enter valid email addresses.'
            }));
        }
    };

    // Error Message
    const errorMessage = {
        startDateTime: requiredSelect('start time'),
        endDateTime: requiredSelect('end time'),
        shift: requiredSelect("shift"),
        slotDuration: requiredSelect(" slot duration"),
        email: requiredMessage("email"),
    }
    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!webScedulingInputs?.shift) {
            errorObj['shift'] = errorMessage['shift']
        }
        if (!webScedulingInputs?.slotDuration) {
            errorObj['slotDuration'] = errorMessage['slotDuration']
        }
        if (!webScedulingInputs?.startDateTime) {
            errorObj['startDateTime'] = errorMessage['startDateTime']
        }
        if (!webScedulingInputs?.endDateTime) {
            errorObj['endDateTime'] = errorMessage['endDateTime']
        }
        if (!webScedulingInputs?.email?.length) {
            errorObj['email'] = errorMessage['email']
        }
        const invalidEmails = webScedulingInputs?.email?.filter(email => !emailRegex.test(email));
        // If there are invalid emails, add an error to the errorObj
        if (invalidEmails?.length > 0) {
            errorObj['email'] = 'Please enter valid email addresses.';
        }
        setError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    // console.log(webScedulingInputs, "webnbb")
    // Handle add submit data..!
    const handleAddSubmit = async (event) => {
        event.preventDefault()
        if (!isValid()) return
        // if (moment(webScedulingInputs?.startDateTime).format("LT") < moment(webScedulingInputs?.endDateTime).format("LT")) return toast.warn('Start time must be greather than end time')
        //     console.log(moment(webScedulingInputs?.startDateTime).format("LT") < moment(webScedulingInputs?.endDateTime).format("LT"),"webScedulingInputs?.startDateTime")
        let payload = {
            startTime: webScedulingInputs?.startDateTime,
            endTime: webScedulingInputs?.endDateTime,
            shiftApplicable: webScedulingInputs?.shift,
            slotDuration: webScedulingInputs?.slotDuration,
            email: webScedulingInputs?.email
        }
        if (webScedulingModal?.data?._id) {
            payload['id'] = webScedulingModal?.data?._id
        }
        // if (webScedulingInputs?.startDateTime < webScedulingInputs?.endDateTime) return toast.warn('End time must be greather than start time')
        // if (new Date(webScedulingInputs?.startDateTime) >= new Date(webScedulingInputs?.endDateTime)) {
        //     return toast.warn('Start time must be earlier than end time');
        //   }
          
        // console.log(payload,"hitt")
        // return
        setSpinner(true)
        try {
            let response = await SCHEDULE_ADD(payload)
            list()
            setWebScedulingModal({ data: "", open: false })
            toast.success(response?.message)
            clearForm()
            setSpinner(false)
        } catch (err) {
            toast.error(err?.message)
            setSpinner(false)
        }
    }
    const handlePagination = (obj) => {
        setController(obj)
    }

    return {
        methods: {
            setWebScedulingModal,
            handleInputChange,
            clearForm,
            handleAddSubmit,
            setError,
            setWebScedulingInputs,
            setController,
            handlePagination,
            handleOptionChange,
        },
        data: {
            webScedulingModal,
            spinner,
            error,
            webScedulingInputs,
            totalCount,
            controller,
            listData,
            shiftList
        }
    }
}

export default useWebsiteSceduling
