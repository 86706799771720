import React from 'react'
import moment from 'moment'
// import { useOutletContext } from 'react-router-dom'
import EditJobDetailsModal from '../editJobDetailsModal'
import EditIcon from '@mui/icons-material/Edit';
import UseJobDetails from '../Hooks/useJobDetails';
import { useParams } from 'react-router-dom';

const AgentJobDetails = () => {
    const { data, methods, } = UseJobDetails()
    const { agentId } = useParams()

    const { jobDetailsList, user,splitLocation } = data
    const { getJobDetails } = methods
    const Edit_Permission = user?.userInfo?.permission?.team?.edit?.value

    return (
        <>
            <div className='row p-5'>
                {
                    (Edit_Permission &&splitLocation[1]==='team-management') &&
                    <div className="col-12 d-flex justify-content-end">
                        <EditIcon className='fs-4  pointer' color="primary" onClick={() => { getJobDetails() }} />
                    </div>
                }
                {
                    jobDetailsList?.map((item) =>
                        <>
                            <div className="col-lg-6">
                                <p className="">
                                    <span className="fw-bold">Designation :</span> <span className="text-muted">{item?.designation || "--"}</span>
                                </p>
                                <p className="">
                                    <span className="fw-bold">Date Of Joining :</span>
                                    <span className="text-muted ms-1">{moment(item?.dateOfJoining).format('DD-MM-YYYY')}
                                        {/* {item?.dateOfJoining 
                                ? moment(item?.dateOfJoining, 'DD-MM-YYYY', true).isValid()
                                    ? moment(item?.dateOfJoining, 'DD-MM-YYYY').format('DD-MM-YYYY')
                                    : '--'
                                : '--'} */}
                                    </span>
                                </p>
                                <p className="">
                                    <span className="fw-bold">Employee Type :</span>
                                    <span className="text-muted ms-1">{item?.employeeType === 'PERMANENT' ? 'Permanent' : item?.employeeType === 'CONTRACTUAL' ? 'Contractual' : 'Trainee'}</span>
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <p className="">
                                    <span className="fw-bold">Role :</span> <span className="text-muted">{item?.roleData?.roleTitle || "--"}</span>
                                </p>
                                <p className="">
                                    <span className="fw-bold">shift :</span> <span className="text-muted">{item?.shiftData?.name || "--"}</span>
                                </p>
                                <p className="">
                                    <span className="fw-bold">Reporting Manager :</span>
                                    <span className="text-muted ms-1">{item?.reportingManagerName || '--'}</span>
                                </p>
                            </div>
                            <hr />
                        </>
                    )
                }

                <EditJobDetailsModal data={data} methods={methods} id={agentId} />
            </div>
        </>
    )
}

export default AgentJobDetails
