import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Table from '../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import useTeamManagementList from './Hooks/useTeamManagementList'
import { Button } from '@mui/material'

import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
import Modal from '../../components/Modal'
import SearchAndStatusFilter from '../../components/SearchAndStatusFilter'
import Chip from '@mui/material/Chip'
import MultipleSelect from "../../components/MultipleSelect"
import EditJobDetailsModal from './editJobDetailsModal'
import UseJobDetails from './Hooks/useJobDetails'

const UserManagement = () => {
  const navigate = useNavigate()
  const { data, methods } = useTeamManagementList()
  // const { data, methods } = UseJobDetails()
  const { data: jobData, methods: jobMethods } = UseJobDetails();
  const { setJobDetailsModal, getJobDetails } = jobMethods
  const {jobDetailsModal}=jobData



  const { teamMemberList, controller, isLoading, totalCount, teamModal, deleteModal, search, statusType, groupList, selectedGroup, TeamPermission, deleteEL } = data
  const { handlePagination, resetFilter, setTeamModal, handleDelete, setDeleteModal, handleSearchChange, handleRemoveEl,
    setStatusType, applayFilter, resend, setSelectedGroup, Export, setDeleteEL } = methods
  const { add, edit, delete: teamDelete, export: exportFile } = TeamPermission?.userInfo?.permission?.team
  const { leavePolicy } = TeamPermission?.userInfo?.permission?.setting
  // console.log(TeamPermission.userInfo, 'userInfo')
  const TableHead = [
    { label: '#', id: '' },
    { label: 'EMP ID (Biometric ID)', id: '' },
    // { label: 'Biometric ID', id: '' },
    { label: 'Agent Name/Email ID', id: '' },
    // { label: 'Email ID', id: '' },
    { label: 'Roles/Designation', id: '' },
    // { label: 'Roles', id: '' },
    { label: 'Groups', id: '' },
    { label: 'Invitation Status', id: '' },
    { label: 'Action', id: '' },
  ]

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <SearchAndStatusFilter
                onSearchChange={handleSearchChange}
                setStatusType={setStatusType}
                search={search}
                statusType={statusType}
                reset={resetFilter}
                applayFilter={applayFilter}
                otherFilter={
                  <>
                    <div className="col-3 ms-2">
                      <MultipleSelect
                        placeholder={"Group"}
                        multiple={true}
                        options={groupList}
                        searchBy={"name"}
                        keyName={"name"}
                        name={"agent"}
                        onChange={(e) => setSelectedGroup(e)}
                        value={"_id"}
                        selectedValues={selectedGroup}
                      />
                      {/* <Autocomplete
                        multiple
                        // disableCloseOnSelect
                        // limitTags={2}
                        options={groupList}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} placeholder="Group" variant="outlined" />}
                        size="small"
                        onChange={(e, newValue) => {
                          setSelectedGroup(newValue)
                        }}
                        value={selectedGroup}
                      /> */}
                    </div>
                  </>
                }
              />

              {/* <div>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2 w-100"
                  aria-label="Search"
                />
              </div>
              <div>
                <Button variant="primary4" className="px-4 ms-3">
                  <SearchIcon />
                </Button>
              </div> */}
              {/* <div>
                <NavDropdown
                  title="All Reports"
                  className="dropDownButtons"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="#action3" className="">
                  All reports
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                  Private reports
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action5">
                  Shared reports
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action6">
                  Owned reports
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action7">
                  Curated reports
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action8">
                  Trash
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              <div>
                <NavDropdown
                  title="Sort by:"
                  className="dropDownButtons"
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="#action3" className="">
                  Name
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                  Created By
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action5">
                  Created Date
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action6">
                  Last Modified by
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action7">
                  Last modified date
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action8">
                  Ascending
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action9">
                  Descending
                  </NavDropdown.Item>
                </NavDropdown>
              </div> */}
            </div>
            <div className="d-flex align-items-end">
              {exportFile?.value && <div>
                <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
              </div>}
              {add?.value && (
                <div className="ms-2">
                  <Button className="dropDownButtons11" onClick={() => navigate(`/team-management/new-agent-form/add`)}>
                    <AddIcon /> Add New
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                    <tbody>
                      {teamMemberList?.map((item, index) => {
                        // Capitalize the 'status' string using toUpperCase()
                        const capitalizedStatus = item?.status ? item?.status.toUpperCase() : '--'
                        const capitalizedFirstName = item?.firstName ? item?.firstName.charAt(0).toUpperCase() + item?.firstName.slice(1) : '--'

                        return (
                          <tr
                            key={item._id}
                            className={item.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}
                            style={{
                              borderRadius: '50px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <td>{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                            <td>{item?.empId || "--"} ({item?.biometricId || "--"})</td>
                            <td className="table-link text-start" onClick={() => navigate(`/team-management/${item?._id}/personal-details`, { state: { agentId: item?._id } })}>
                              {`${capitalizedFirstName} ${item?.lastName}` || '--'}
                              <br />
                              {item?.email || '--'}
                            </td>
                            <td className="">{item?.role_id?.roleTitle || '--'} <br /> {item?.designation || '--'}</td>
                            <td>
                              <span className="d-flex flex-wrap">
                                {item.teams.length
                                  ? item.teams.map((team) => {
                                    return (
                                      <div key={team._id}>
                                        <Chip className="m-1" color="secondary" variant="outlined" size="small" label={team.name} />
                                      </div>
                                    )
                                  })
                                  : null}
                              </span>
                            </td>
                            <td>{capitalizedStatus}</td>
                            <td>

                              {
                                item._id === TeamPermission?.userInfo._id ?
                                  <div className="pt-3" style={{ minHeight: '50px' }}>
                                    <i className="fa-solid fa-ellipsis-vertical ps-2" style={{ cursor: 'not-allowed' }} />
                                  </div> :
                                  !item.isDeleted && (teamDelete?.value || edit?.value) ? (
                                    <div className="d-flex dropdown p-0 align-items-center" style={{ minHeight: '50px' }}>
                                      <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        {edit?.value && (
                                          <li
                                            onClick={() => navigate(`/team-management/new-agent-form/${item?._id}`, { state: { teamMemberId: item?._id } })}
                                            className="dropdown-item pointer"
                                          >
                                            Edit
                                          </li>
                                        )}
                                        {edit?.value && (
                                          <li className="dropdown-item pointer" onClick={() => { setJobDetailsModal((prev) => ({ ...prev, data: item })); getJobDetails(item?._id) }}>
                                            Edit Job Details
                                          </li>
                                        )}
                                        {item.status === 'pending' && (
                                          <li className="dropdown-item" onClick={() => resend(item._id)}>
                                            Resend
                                          </li>
                                        )}
                                        {teamDelete?.value && (
                                          <li className="dropdown-item pointer" onClick={() => setDeleteModal({ data: item, open: true })}>
                                            Delete
                                          </li>
                                        )}
                                        {
                                          leavePolicy?.value &&
                                          <li className="dropdown-item pointer" onClick={() => setDeleteEL({ data: item, open: true })}>
                                            Remove El
                                          </li>
                                        }
                                      </ul>
                                    </div>
                                  ) : (
                                    <div className="pt-3" style={{ minHeight: '50px' }}>
                                      <i className="fa-solid fa-ellipsis-vertical ps-2" style={{ cursor: 'not-allowed' }} />
                                    </div>
                                  )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!teamMemberList?.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
                {/* Group Modal */}
                <Modal
                  size="sm"
                  show={teamModal.open}
                  onHide={() => setTeamModal((prev) => ({ ...prev, open: false }))}
                  // headerContent={<h5>Group list</h5>} // Customize header content here
                  bodyContent={
                    <div className="all_List_By_category_Id">
                      <ol>
                        {teamModal.data?.teams?.map((item) => (
                          <li key={item?._id}>
                            <h6
                            // className='border rounded text-center p-2 cursor'
                            // onClick={() =>
                            //   navigate(`/clinic/add-group/add-new-group/${item?.group_id}`)
                            // }
                            >
                              {item?.name}
                            </h6>
                          </li>
                        ))}
                      </ol>
                    </div>
                  } // Customize body content here
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditJobDetailsModal data={jobData} methods={jobMethods} id={jobDetailsModal?.data?._id}/>
      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Delete?</h5>} // Customize header content here
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        } // Customize body content here
      />

      {/* Delete Earn Leaves Modal */}
      <Modal
        size="md"
        show={deleteEL.open}
        onHide={() => setDeleteEL((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Delete?</h5>} // Customize header content here
        bodyContent={
          <div className="">
            {/* <p>Are you sure you want to delete earn leaves? </p> */}
            <div>
              <label>Note <span className='text-danger'>*</span></label>
              <textarea className='form-control' onChange={(e) => setDeleteEL((prev) => ({ ...prev, note: e.target.value }))}></textarea>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleRemoveEl}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteEL((prev) => ({ ...prev, open: false }))}>
                No
              </Button>
            </div>
          </div>
        } // Customize body content here
      />
    </>
  )
}

export default UserManagement
