/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Button } from '@mui/material'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { Link, useLocation } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import zimologo from '../../assets/images/zimologo.png'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { logOutUser, profilePath } from '../../repository/auth'
import { useDispatch } from 'react-redux'
import { clearStore } from '../../Slices/AuthSlice'
import useReduxStore from '../../hooks/useReduxStore'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import io from "socket.io-client";
import { environment } from '../../env/environment'
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';

import { getnotificationList } from '../../repository/notificationRepo'
import { addCount } from '../../Slices/notificationCountSlice'

const Header = () => {

  const { selector: userPermission } = useReduxStore('auth')
  const { selector: notification } = useReduxStore('notification')

  // console.log(notification, 'notification')

  const { rolesAndPermission, setting, attendance } =
    userPermission?.userInfo?.permission ? userPermission?.userInfo?.permission : userPermission?.userInfo?.role_id?.permission

  const { pathname } = useLocation()
  const splitLocation = pathname.split('/')
  const [openprofile, setopenProfile] = React.useState(false)
  const hendelcloseprofile = () => {
    setopenProfile(true)
  }
  // console.log(setting, 'setting')
  // const [taskModel, setTaskModel] = useState({
  //   open: false,
  //   data: '',
  // })

  const dispatch = useDispatch()
  const Navigate = useNavigate()

  const userLogout = async (value) => {
    try {
      // setSpinner(true)
      await logOutUser()
      onLogout()
    } catch (error) {
      // toast.error(error.message)
    }
    // setSpinner(false)
  }
  const onLogout = () => {
    setTimeout(() => {
      dispatch(clearStore())
      // persistor?.purge()
      localStorage.clear();
      Navigate('/')
    })
  }

  const handleClosee = () => {
    setopenProfile(false)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  // const socket = io(environment.baseUrl)
  // useEffect(() => {
  //   socket.on('notification', ((data) => { notificationList(); showNotification(data) }))
  //   socket.emit("add-user", { user_Id: userPermission?.userInfo._id, })
  //   notificationList()

  //   return () => {
  //     socket.off('notification', notificationList());  // Clean up the event listener
  //     socket.disconnect();  // Close the socket connection when unmounting
  //   };
  // }, [])


  useEffect(() => {
    const socket = io(environment.baseUrl, {
      transports: ["websocket"], // Ensure WebSocket transport
      reconnectionAttempts: 3,   // Limit reconnection attempts
      timeout: 10000,            // Set connection timeout
    });

    // Handle socket connection
    socket.on("connect", () => {
      // console.log("WebSocket connected");
      socket.emit("add-user", { user_Id: userPermission?.userInfo._id });
    });

    // Handle notifications
    const handleNotification = (data) => {
      notificationList();
      showNotification(data);
    };
    socket.on("notification", handleNotification);

    // Log connection errors
    socket.on("connect_error", (err) => {
      console.error("WebSocket connection error:", err.message);
    });

    // Cleanup on unmount
    return () => {
      socket.off("notification", handleNotification); // Properly remove the specific listener
      socket.disconnect(); // Close the WebSocket connection
      console.log("WebSocket disconnected");
    };
  }, [userPermission]);

  // useEffect(()=>{
  //   notificationList()
  // },[])

  const notificationList = async () => {
    const payLoad = {
      receiver: userPermission?.userInfo?._id
    }
    try {
      let response = await getnotificationList(payLoad)
      dispatch(addCount(response?.unreadedCount))
    }
    catch (error) {

    }
  }
  const showNotification = (message) => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        sendNotification(message);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            sendNotification(message);
          }
        });
      }
    }
  };
  const sendNotification = (message) => {
    const notification = new Notification(message?.message);

    notification.onclick = function (event) {
      event.preventDefault();
      const typeToPath = {
        'TICKETREPLY': '/tickets',
        'TICKET': '/tickets',
        'MERGETICKET': '/tickets',
        'TASKASSIGNED': '/task',
        'TIMELOGSTATUS': '/time-log',
        'ATTENDANCE': '/attendance',
        'LEAVE': '/attendance/leaves-request',
        'APPROVED': '/attendance/leaves-request',
        'REJECTED': '/attendance/leaves-request',
        'PARTIALAPPROVED': '/attendance/leaves-request'
      };
      const path = typeToPath[message.type];
      if (path) {
        Navigate(path);
      }
    };
  };
  return (
    <>
      <Navbar expand="lg" className="bgcolor" style={{ padding: '10px 30px' }}>
        <Container fluid>
          <Navbar.Brand href="#">
            <img className="set-logo m-0 p-0" alt="Zimo.one" src={zimologo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav id="headerMenu" className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
              <Link to="dashboard" className={` ms-5 ${splitLocation[1] === 'dashboard' ? 'activeforprivenav' : ''}`}>
                Dashboard
              </Link>
              <Link to="tickets" className={` ${splitLocation[1] === 'tickets' ? 'activeforprivenav' : ''}`}>
                Tickets
              </Link>
              {/* <Link to="tickets" className={taskModel == true ? "activeforprivenav" : ""} onClick={() => setTaskModel(prev => ({ ...prev, open: true }))}>Task</Link> */}
              <Link className={`${splitLocation[1] === 'task' ? 'activeforprivenav' : ''}`} to="task">
                Task
              </Link>
              {/* attendance */}
              {(attendance?.viewAll?.value || attendance?.viewSelf?.value) && <Link className={`${splitLocation[1] === 'attendance' ? 'activeforprivenav' : ''}`} to="attendance">
                Attendance
              </Link>}
              <Link className={`${splitLocation[1] === 'leaves-request' ? 'activeforprivenav' : ''}`} to="leaves-request">
                Leave
              </Link>
              <Link className={`${splitLocation[1] === 'time-log' ? 'activeforprivenav' : ''}`} to="time-log">
                Time Log
              </Link>

              <Link className={`${splitLocation[1] === 'projects' ? 'activeforprivenav' : ''}`} to="projects">
                Projects
              </Link>
              <Link className={`${splitLocation[1] === 'team-management' ? 'activeforprivenav' : ''}`} to="team-management">
                Team
              </Link>
              {/* <Link className={`${splitLocation[1] === 'reporting-&-analytics' ? 'activeforprivenav' : ''}`} to="reporting-&-analytics">
                Reporting
              </Link> */}
              {/* <Link className={`${splitLocation[1] === 'leaves' ? 'activeforprivenav' : ''}`} to="leaves">
                Leave
              </Link> */}

              {rolesAndPermission?.view?.value && <Link className={`${splitLocation[1] === 'roles-&-permissions' ? 'activeforprivenav' : ''}`} to="roles-&-permissions">
                Roles
              </Link>}
              {
                setting?.report?.value &&
                <Link className={`${splitLocation[1] === 'reports' ? 'activeforprivenav' : ''}`} to="reports">
                  Reports
                </Link>
              }

              {(setting?.group?.value || setting?.workflow?.value || setting?.report?.value || setting?.shift?.value || setting?.leavePolicy?.value)
                && <Link className={`${splitLocation[1] === 'setting' ? 'activeforprivenav' : ''}`} to="setting">
                  Settings
                </Link>}
            </Nav>
            <div className="textcolor">
              <Row className="justify-content-md-center">
                {/* <Col xs lg="3">
                  <SearchIcon className="mt-2" />
                </Col> */}
                {/* <Col md="3">
                  <NotificationsNoneIcon className="mt-2 me-2" onClick={()=>Navigate('notification')}/>
                </Col> */}

                <div className='d-flex align-items-center'>
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', cursor: "pointer" }}>
                    {/* <Avatar
                      alt=''
                      sx={{ width: 40, height: 40 }}
                    /> */}
                    <div className="img-profile" style={{ cursor: 'pointer' }} onClick={handleClick}>
                      {
                        userPermission?.userInfo?.image ?
                          <img src={profilePath + userPermission?.userInfo?.image} alt="profile" height={40} width={40} style={{ borderRadius: '50%' }} />
                          :
                          <AccountCircleIcon sx={{ fontSize: 40 }} />
                      }
                    </div>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => Navigate(`adminprofile/personal-details`)}>Profile</MenuItem>
                      <MenuItem onClick={() => Navigate(`change-password`)}>Change Password</MenuItem>
                      <MenuItem onClick={hendelcloseprofile}>Logout</MenuItem>
                      <div>
                        <Dialog open={openprofile} onClose={handleClosee}>
                          <DialogTitle style={{ borderBottom: '1px solid #f2f2f2', padding: '10px 20px' }}>Logout</DialogTitle>
                          <DialogContent className="pt-4" style={{ width: '350px' }}>
                            <DialogContentText>Are you sure you want to logout?</DialogContentText>
                          </DialogContent>
                          <DialogActions style={{ borderTop: '1px solid #f2f2f2', padding: '10px' }}>
                            <Button variant="contained" style={{ width: '60px' }} onClick={() => userLogout()}>
                              Yes
                            </Button>
                            <Button variant="outlined" style={{ width: '60px' }} onClick={handleClosee}>
                              No
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </Menu>
                    <div className='ms-2 me-3' onClick={handleClick}>{userPermission?.userInfo?.firstName}</div>
                    <Badge badgeContent={notification} color="error" className='mt-2' onClick={() => Navigate('notification')}>
                      <NotificationsNoneIcon />
                    </Badge>
                  </Box>
                </div>
                {/* <Col xs lg="4">
                  <div>
                    <div className="img-profile" style={{ cursor: 'pointer' }} onClick={handleClick}>
                      <AccountCircleIcon sx={{ fontSize: 40 }} />
                    </div>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => Navigate(`adminprofile`)}>Profile</MenuItem>
                      <MenuItem onClick={() => Navigate(`change-password`)}>Change Password</MenuItem>
                      <MenuItem onClick={hendelcloseprofile}>Logout</MenuItem>
                      <div>
                        <Dialog open={openprofile} onClose={handleClosee}>
                          <DialogTitle style={{ borderBottom: '1px solid #f2f2f2', padding: '10px 20px' }}>Logout</DialogTitle>
                          <DialogContent className="pt-4" style={{ width: '350px' }}>
                            <DialogContentText>Are you sure you want to logout?</DialogContentText>
                          </DialogContent>
                          <DialogActions style={{ borderTop: '1px solid #f2f2f2', padding: '10px' }}>
                            <Button variant="contained" style={{ width: '60px' }} onClick={() => userLogout()}>
                              Yes
                            </Button>
                            <Button variant="outlined" style={{ width: '60px' }} onClick={handleClosee}>
                              No
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </Menu>
                  </div>
                </Col>
                <Col md="3">
                  <Badge badgeContent={5} color="error" className='mt-2'>
                    <NotificationsNoneIcon />
                  </Badge>
                </Col> */}
              </Row>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <Modal
        size="xl"
        show={taskModel.open}
        onHide={() => setTaskModel((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Add Task</h5>}
        bodyContent={
          <>
            <Task />
          </>
        }
      />    */}
    </>
  )
}

export default Header
