import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { TextField, InputLabel } from '@mui/material'


export default function index({ SelectedDate, handleDateChange, Label, name, variant, className, size, isRequired, value, Error,disabled }) {
    return (
        <>
            <div className={`${className}`}>
                <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>{Label}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDateTimePicker
                        format='dd/MM/yyyy hh:mm a'
                        value={SelectedDate}
                        onChange={handleDateChange}
                        onBlur={handleDateChange}
                        name={name}
                        renderInput={(params) => <TextField variant={variant ? variant : "standard"}  {...params}
                        />}
                        size={size}
                        disabled={disabled}
                    />

                    {Error && <p className="text-danger">{Error}</p>}
                </LocalizationProvider>
            </div>
        </>
    )
}