import { axiosInstance, baseUrl } from "./auth";
import { useQuery } from 'react-query'
import { ApiStatus } from "../constants";
import { environment } from "../env/environment";


// Leave Policy List
export const getLeavePolicyList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/leavepolicy/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetLeavePolicyList(params, options = {}) {
    return useQuery(['GET_LEAVE_POLICY_LIST',], () => getLeavePolicyList(params), options)
}

// All Leave Policy List
export const allLeavePolicyList = async body => {
    let response = await axiosInstance.post(`${baseUrl}/leavepolicy/fullList`, body);
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError);
    }
    return response.data;
};



// Leave Policy Add 
export const addLeavePolicy = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/leavepolicy`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};
export const addTeamLeavePolicy = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}//individualLeavepolicy`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

//  Get leavepolicy Details
export const getLeavePolicyDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/leavepolicy/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Update leavepolicy
export const updateLeavePolicy = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/leavepolicy`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Delete leavepolicy
export const deleteLeavePolicy = async (params) => {
    const response = await axiosInstance.post(
        `${baseUrl}/leavepolicy/toggleDelete`,
        params
    );
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
//Leave Policy Export
export const ExportLeavePolicyList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/leavepolicy/export`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

//   ====================================================Earn Leave Policy====================================================================
export const EarnLeavePolicy = async params => {
    const response = await axiosInstance.post(`${baseUrl}/earnedLeave`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
export const EarnLeavePolicyList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/earnedLeavelist`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
export const EarnLeavePolicyUpdate = async params => {
    const response = await axiosInstance.post(`${baseUrl}/earnedLeaveUpdate`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

                          

//   ====================================================Leave Applay Api's===================================================================
export const uploadFileUrlLeave = `${environment?.baseUrl}/upload/leave/`
// Leave Counts
export const LeaveCountList = async body => {
    let response = await axiosInstance.get(`${baseUrl}/leaveCounts`, body);
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError);
    }
    return response.data;
};
export const leavePolicyListForLeave = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/leavePolicyListForLeave`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export const LeaveCountsList = async (params) => {
    let response = await axiosInstance.get(`${baseUrl}/leaveCounts/leaveCountsByUser/${params}`);
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError);
    }
    return response.data;
};


// Leave List
export const getLeaveList = async (body) => {
    let response = await axiosInstance.post(`${baseUrl}/leave/list`, body)
    if (response.data.code !== ApiStatus.success) {
        throw new Error(response.data.message || ApiStatus.serverError)
    }
    return response.data
}
export function useToGetLeaveList(params, options = {}) {
    return useQuery(['GET_LEAVE_LIST',], () => getLeaveList(params), options)
}

// Leave Add 
export const addLeave = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/leave`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

// Leave Edit 
export const editLeave = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/leave/updateLeave`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data;
};

//  Get leave Details
export const getLeaveDetails = async (params) => {
    const response = await axiosInstance.get(`${baseUrl}/leave/${params}`);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

export const cancelLeaves = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/leave/cancelLeaves`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
export const editApprovalLevel = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/leave/editApprovalLevel`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const getLeaveTimeline = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/history/leave`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Update leave
export const updateLeave = async (params) => {
    const response = await axiosInstance.put(`${baseUrl}/leave`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Delete leave
export const deleteLeave = async (params) => {
    const response = await axiosInstance.post(
        `${baseUrl}/leave/toggleDelete`,
        params
    );
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};
//Leave Export
export const ExportLeaveList = async params => {
    const response = await axiosInstance.post(`${baseUrl}/leave/export`, params);
    if (response.data.code !== 200) {
        throw new Error(response.data.message);
    }
    return response.data;
};

// updateApprovalStatus
export const ApprovalStatus = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/leave/updateApprovalStatus`, params)
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Users Breaks
export const getUserBreaks = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/report/getUserBreaksreports`, params)
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}

// Users Breaks
export const getUserBreaksReport = async (params) => {
    const response = await axiosInstance.post(`${baseUrl}/report/getUserBreaksreportsExcel`, params)
    if (response.data.code !== 200) {
        throw new Error(response.data.message)
    }
    return response.data
}


