import React, { useState } from 'react'
import { Button, } from '@mui/material'

import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import Modal from '../../components/Modal'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SelectInput from '../../components/SelectInput'
import { useOutletContext } from 'react-router-dom'
import { CKEditorCustomToolbar, LeaveType, SubLeaveType } from '../../constants'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { uploadFileUrlLeave } from '../../repository/taskRepo'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AdjustIcon from '@mui/icons-material/Adjust';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MultipleSelect from "../../components/MultipleSelect"
import EditIcon from '@mui/icons-material/Edit';
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import DateInput from '../../components/DateInput'
import Fab from '@mui/material/Fab';
import ClearIcon from '@mui/icons-material/Clear'
import UseDateTimeFormat from '../../hooks/useDateTimeFormat'

const LeaveDetails = () => {
  const { getFormattedDateTime } = UseDateTimeFormat()
  const [showPDFModal, setShowPDFModal] = useState({ open: false, data: '' })
  const { data, methods } = useOutletContext()
  const { leaveDetails,
    leaveApprovalModal,
    leaveId,
    levePermission,
    leaveCancelModal,
    leaveEditModal,
    allRolesList,
    agentList,
    editLeaveInputs,
    leaveUpdateModal,
    leaveForm,
    leaveType,
    leavePolicyCount,
    fileInputRef,
    selectedFiles,
    leaveError,
  } = data

  const { setLeaveApprovalModal,
    UpdateStatus,
    leaveCancel,
    setLeaveCancelModal,
    setLeaveEditModal,
    setEditLeaveInputs,
    editApprovals,
    getAllRolesList,
    getAgentList,
    setLeaveUpdateModal,
    setLeaveForm,
    handleInputChange,
    checkType,
    handleDateChange,
    handleTimeLogFileChange,
    handleLogRemoveFile,
    setLeaveError,
    clear,
    submit,
    getLeaveInfo,
    getLeaveCount
  } = methods
  const { approval, editApproval } = levePermission?.userInfo?.permission?.leave
  // console.log(levePermission,'levePermission')


  const fileType = (file, fileIndex) => {
    let type = file?.split('.').pop()

    if (['png', 'jpeg', 'jpg'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={uploadFileUrlLeave + file} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['mp4'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['pdf'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['xlsx', 'xls'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['txt', 'doc', 'odt'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else {
      return (
        <>
          <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
        </>
      )
    }
  }

  const filePreview = (file) => {
    let type = file?.split('.').pop()
    if (['png', 'jpeg', 'jpg'].includes(type)) {
      setShowPDFModal({ open: true, data: file })
    } else window.open(`${uploadFileUrlLeave}${file}`)
  }

  const leavePadeType = [
    { title: "Paid", value: "PAID" },
    { title: "Un Paid", value: "UNPAID" },
  ]

  // Approval Header Function
  const approvalHeader = (item) => {
    if (item?.data === leaveId) {
      if (item?.all) {
        return "All Approve leave"
      } else {
        return "All Reject leave"
      }
    }
    else {
      if (item?.Approve) {
        return "Approve leave"
      }
      else {
        return "Reject leave"
      }
    }
  }

  // Approval Body Message
  const approvalMessage = (item) => {
    if (item?.data === leaveId) {
      if (item?.all) {
        return "Are you sure you want to approve all these leaves?"
      } else {
        return "Are you sure you want to reject all these leaves?"
      }
    }
    else {
      if (item?.Approve) {
        return "Are you sure you want to approve this leave?"
      }
      else {
        return "Are you sure you want to reject this leave?"
      }
    }
  }
  // Approvaql Modal Size
  const approvalModalSize = (item) => {
    if (item?.data === leaveId) {
      if (item?.all) {
        return "md"
      } else {
        return "lg"
      }
    }
    else {
      if (item?.Approve) {
        return "md"
      }
      else {
        return "lg"
      }
    }
  }
  // Approval Modal Close
  const closeModalFun = () => {
    setLeaveApprovalModal({ data: "", leaveType: "PAID", open: false })
  }

  //  Approval Buttons
  const approvalButtons = (item) => {

    if (item?.data === leaveId) {
      if (item?.all) {
        return <Button variant="contained" onClick={() => UpdateStatus("APPROVED", "allLeave")}>
          APPROVED
        </Button>
      } else {
        return <Button variant="contained" onClick={() => UpdateStatus("REJECTED", "allLeave")} data-bs-dismiss="modal">
          REJECTED
        </Button>
      }
    }
    else {
      if (item?.Approve) {
        return <Button variant="contained" onClick={() => UpdateStatus("APPROVED")}>
          APPROVED
        </Button>
      }
      else {
        return <Button variant="contained" onClick={() => UpdateStatus("REJECTED")} data-bs-dismiss="modal">
          REJECTED
        </Button>
      }
    }
  }
  // console.log(levePermission?.userInfo?.role_id?.roleTitle == 'HUMAN RESOURCE', 'levePermission?.userInfo')
  // console.log(levePermission?.userInfo?._id == leaveDetails?.userDetails?._id,'OOOOOOOO')


  const getApproval = (item, leaveId) => {
    if (item?.level_type === 'Role') {
      getAllRolesList(item?.name)
    }
    else {
      getAgentList(item?.approval_id?.[0])
    }
    setLeaveEditModal({ open: true, data: item, leaveId: leaveId });
  }
  const ApprovalInfo = (item) => {
    return (
      <>
        <div className="row">
          <div className='my-2 text-center' style={{ color: item.status === "APPROVED" ? "#238838eb" : "#ea2727eb" }}>
            {item.status}
            <span className='ms-2'>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                {item?.type}{<br />}
                <div dangerouslySetInnerHTML={{ __html: item?.denialReason }}></div>
              </Tooltip>}>
                <InfoRoundedIcon style={{ height: '0.8em' }} />
              </OverlayTrigger>
            </span>
          </div>
        </div>
        {item?.approvalLevels?.length ? <hr /> : null}
        {
          item?.approvalLevels?.map((cur, index) => (
            <div key={index} className='row my-2'>
              <span className='col-8 ps-4'>{cur.name}</span>
              <span className='col-4 ps-4 d-flex'>
                {cur?.approved_status === 'APPROVED' ? <CheckCircleIcon style={{ height: '0.8em', color: 'green' }} /> : cur?.approved_status === 'UNAPPROVED' ? <AdjustIcon style={{ height: '0.8em', color: 'grey' }} /> : <HighlightOffIcon style={{ height: '0.8em', color: 'red' }} />}
                {
                  // editApproval?.value && levePermission?.userInfo?._id !== leaveDetails?.userDetails?._id && leaveDetails?.status === "UNAPPROVED" &&
                  <EditIcon className='fs-4  pointer' color="primary" onClick={() => { getApproval(cur, item?._id) }} />
                }
              </span>
            </div>
          ))
        }
      </>
    );
  };


  const Leave_Edit_Permission = leaveDetails?.dates?.[0]?.approvalLevels?.some((item) => item?.approved_status === 'APPROVED')
  return (
    <div>
      <div className="row mt-2">
        <div className="col-12">
          <div className="container-fluid contact">
            <div className="row bg">
              <div className="col-lg-12">
                <h6 className="m-0 mt-4">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-end">
                      <span style={{ fontSize: '16px' }} className="table-link"
                      // onClick={() => Navigate(`/team-management/${ticketsInputs?.ticketFullDetails?.reportedByData?._id}/ticket`)}
                      >
                        {`${leaveDetails?.userDetails.name.toUpperCase()} `}
                      </span>
                    </div>
                    <div className="d-flex align-items-end">
                      <small className='me-3'>
                        {leaveDetails?.createdAt ? getFormattedDateTime(leaveDetails?.createdAt, 'calendar') : "--"}
                      </small>
                      {!Leave_Edit_Permission && <EditIcon className='fs-4  pointer' color="primary" onClick={() => { getLeaveInfo(); setLeaveUpdateModal((prev) => ({ ...prev, open: true })) }} />}
                    </div>
                  </div>
                  <hr />
                </h6>
                {/* <b className='table-link'>#{ticketsInputs?.ticketId}</b> */}
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>Leave Title :</b> {leaveDetails?.policyDetails?.name}
                </h6>
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>Leave Type :</b> {leaveDetails?.leave} {leaveDetails?.subLeaveType ? `(${leaveDetails?.subLeaveType})` : ""}
                </h6>
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>Leave Code :</b> {leaveDetails?.policyDetails?.code}
                </h6>
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>Leave :</b> {leaveDetails?.policyDetails?.leaveType}
                </h6>
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>Type :</b> {leaveDetails?.policyDetails?.type}
                </h6>
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>From :</b> {getFormattedDateTime(leaveDetails?.appliedStartDate, leaveDetails?.policyDetails?.leaveType === 'NORMAL' && leaveDetails?.leave === 'Full Day' ? "lll" : 'DD-MM-YYYY')}
                </h6>
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>To :</b> {getFormattedDateTime(leaveDetails?.appliedEndDate, leaveDetails?.policyDetails?.leaveType === 'NORMAL' && leaveDetails?.leave === 'Full Day' ? "lll" : 'DD-MM-YYYY')}
                </h6>
                <div className="mt-4">
                  <b>Description :</b>
                </div>
                <div className="my-2 text-break" style={{ minHeight: '10vh' }}
                  dangerouslySetInnerHTML={{ __html: leaveDetails?.reason }}
                ></div>
                <div className="mb-4">
                  <span>
                    <b>Attachments : </b>
                  </span>

                  {leaveDetails?.attachmentsFile?.length > 0 ? (
                    <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                      <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                        {leaveDetails?.attachmentsFile?.map((file, fileIndex) => (
                          <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative pointer" onClick={() => filePreview(file)}>
                            {fileType(file, fileIndex, "LEAVE")}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                      <span>No Attachments</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between mt-2 mb-3">
                  <span><b>Leave Day's : </b></span>
                  {/* {approval?.value && levePermission?.userInfo?._id !== leaveDetails?.userDetails?._id && leaveDetails?.status === "UNAPPROVED" && <div className="d-flex">
                    <div >
                      <Button variant="contained" className="px-4" color='success'
                        onClick={() => setLeaveApprovalModal(prev => ({ ...prev, data: leaveId, open: true, all: true }))}
                      >
                        Approve All
                      </Button>
                    </div>
                    <div className="mx-3">
                      <Button variant="contained" className="px-4" color='error'
                        onClick={() => setLeaveApprovalModal(prev => ({ ...prev, data: leaveId, open: true, all: false }))}
                      >
                        Reject All
                      </Button>
                    </div>
                  </div>} */}
                  {
                    leaveDetails?.status === "CANCELLED" ? null :
                      levePermission?.userInfo?._id === leaveDetails?.userDetails?._id &&
                      <Button variant="contained" className="px-4" color='success' onClick={() => setLeaveCancelModal(prev => ({ ...prev, open: true }))}>
                        Cancel Leave
                      </Button>
                  }

                </div>
                <div className="row">
                  {leaveDetails?.dates?.map((item, index) =>
                    <React.Fragment key={index}>
                      <div className='col-3 my-3 '>
                        <div className='border rounded '>
                          <div className='bg-light text-center '>
                            <div className='fs-4 pt-4'>
                              {getFormattedDateTime(item?.appliedStartDate, 'Do')}
                            </div>
                            <div className='fs-6 pb-4'>
                              {getFormattedDateTime(item?.appliedStartDate, 'MMM')}
                            </div>
                          </div>
                          {
                            approval.value && (levePermission?.userInfo?._id !== leaveDetails?.userDetails?._id) ?
                              // console.log(item?.alreadyApproved)
                              <>
                                {item?.loggedInUserStatus === 'UNAPPROVED' ?
                                  <div className="row text-center ">

                                    <div className='col-6 my-2'>
                                      <DoneIcon className='pointer' color='success' onClick={() => {
                                        if (leaveDetails?.permission) {
                                          setLeaveApprovalModal(prev => ({ ...prev, data: item?._id, open: true, Approve: true }))
                                        }
                                        else {
                                          UpdateStatus("APPROVED", '', item?._id)
                                          // setLeaveApprovalModal(prev => ({ ...prev, data: item?._id,Approve: true,permission:true }))
                                        }
                                      }}
                                      />
                                    </div>


                                    <div className='col-6 my-2'>
                                      <CloseIcon className='pointer' size='small' color='error' onClick={() => setLeaveApprovalModal(prev => ({ ...prev, data: item?._id, open: true, Approve: false }))} />
                                    </div>

                                  </div>
                                  : null}
                                <div className="row text-center ">

                                  <div className='col-6 my-2'>
                                    {
                                      item?.loggedInUserStatus === 'REJECTED' &&
                                      <DoneIcon className='pointer' color='success' onClick={() => {
                                        if (leaveDetails?.permission) {
                                          setLeaveApprovalModal(prev => ({ ...prev, data: item?._id, open: true, Approve: true }))
                                        }
                                        else {
                                          UpdateStatus("APPROVED", '', item?._id)
                                          // setLeaveApprovalModal(prev => ({ ...prev, data: item?._id,Approve: true,permission:true }))

                                        }
                                      }}
                                      />
                                    }
                                  </div>
                                  <div className='col-6 my-2'>
                                    {
                                      item?.loggedInUserStatus === 'APPROVED' &&
                                      <CloseIcon className='pointer' size='small' color='error' onClick={() => setLeaveApprovalModal(prev => ({ ...prev, data: item?._id, open: true, Approve: false }))} />
                                    }
                                  </div>
                                </div>
                                {ApprovalInfo(item)}
                              </>
                              :
                              ApprovalInfo(item)
                          }
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* attachment Viewer */}
      <Modal
        size="lg"
        show={showPDFModal.open}
        onHide={() => setShowPDFModal((pre) => ({ ...pre, open: false }))}
        headerContent={''}
        bodyContent={
          <div className="">
            {showPDFModal?.data ? (
              showPDFModal?.data?.split('.').pop() === 'pdf' ? (
                <iframe src={uploadFileUrlLeave + showPDFModal?.data} title={showPDFModal?.data} style={{ width: '100%', height: '100%' }} />
              ) : (
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={uploadFileUrlLeave + showPDFModal?.data} alt="" />
              )
            ) : (
              <div>No attachment available</div>
            )}
          </div>
        }
      />

      {/* Confermation Modal before approve or reject  */}
      <Modal
        size={approvalModalSize(leaveApprovalModal)}
        show={leaveApprovalModal.open}
        onHide={closeModalFun}
        headerContent={<h5 className="py-2">{approvalHeader(leaveApprovalModal)}</h5>}
        bodyContent={
          <div className="">
            <p>{approvalMessage(leaveApprovalModal)} </p>


            {(leaveApprovalModal?.Approve === false || leaveApprovalModal?.all === false) ? <div className="col-lg-12 my-2">
              <label htmlFor="">
                <sub>Reason <span className='text-danger'>*</span></sub>
              </label>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: CKEditorCustomToolbar,
                }}
                data={leaveApprovalModal?.reason} // Set CKEditor data to projectInputs.notes
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setLeaveApprovalModal((prevProps) => ({ ...prevProps, reason: data }))
                }}
              />
            </div>
              :
              <div className="col-lg-12 my-2">
                <SelectInput
                  LabelButton
                  Lable={'Leave Type'}
                  // multiple={true}
                  Options={leavePadeType}
                  keyName={'title'}
                  // extrakey={"leaveType"}
                  Name="leaveType"
                  SelectValue={leaveApprovalModal?.leaveType}
                  HandleChange={(e) => setLeaveApprovalModal((prevProps) => ({ ...prevProps, leaveType: e.target.value }))}
                  // Error={leaveError.leaveType}
                  variant={'outlined'}
                  isRequired={true}
                // disabled={true}
                />
              </div>
            }

            <div className="d-flex justify-content-end mt-4">
              {
                // leaveApprovalModal?.Approve ?
                //   <Button variant="contained" onClick={() => UpdateStatus("APPROVED")}>
                //     APPROVED
                //   </Button>
                //   :
                //   <Button variant="contained" onClick={() => UpdateStatus("REJECTED")} data-bs-dismiss="modal">
                //     REJECTED
                //   </Button>
                approvalButtons(leaveApprovalModal)
              }

              <Button variant="outlined" className="mx-2" onClick={closeModalFun}>
                Cancel
              </Button>
            </div>
          </div>
        }
      />
      {/* Leave Cancel Modal */}
      <Modal
        size='md'
        show={leaveCancelModal.open}
        onHide={() => setLeaveCancelModal({ data: null, open: false })}
        headerContent={<h5 className="py-2">Cancel Leave</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to cancel all these leaves?</p>

            <div className="col-lg-12 my-2">
              <label htmlFor="">
                <sub>Reason <span className='text-danger'>*</span></sub>
              </label>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: CKEditorCustomToolbar,
                }}
                data={leaveCancelModal?.reason} // Set CKEditor data to projectInputs.notes
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setLeaveCancelModal((prevProps) => ({ ...prevProps, reason: data }))
                }}
              />
            </div>


            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={() => leaveCancel()}>
                Submit
              </Button>

              <Button variant="outlined" className="mx-2" onClick={() => setLeaveCancelModal({ data: null, open: false })}>
                Cancel
              </Button>
            </div>
          </div>
        }
      />

      {/* Leave Approval Edit Modal  */}
      <Modal
        size='md'
        show={leaveEditModal.open}
        onHide={() => setLeaveEditModal({ open: false, data: {} })}
        headerContent={<h5 className="py-2">Edit Approval</h5>}
        bodyContent={
          <div className="">
            <div className="col-lg-12 my-2">
              <div className="col-lg-12  mb-2 ">
                {
                  leaveEditModal?.data?.level_type === 'Role' ?
                    <MultipleSelect
                      label={"Role"}
                      // multiple={true}
                      options={allRolesList}
                      searchBy={"roleTitle"}
                      keyName={"roleTitle"}
                      name={"priority"}
                      onChange={(e) => setEditLeaveInputs((prevProps) => ({ ...prevProps, role: e }))}
                      value={"_id"}
                      selectedValues={editLeaveInputs?.role}
                      isRequired={true}
                    />
                    :
                    <MultipleSelect
                      label={"Agent"}
                      // multiple={true}
                      options={agentList}
                      searchBy={"name"}
                      keyName={"name"}
                      name={"agent"}
                      onChange={(e) => setEditLeaveInputs((prevProps) => ({ ...prevProps, agent: e }))}
                      value={"_id"}
                      selectedValues={editLeaveInputs?.agent}
                      isRequired={true}
                    />
                }
              </div>
            </div>


            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={editApprovals}>
                Update
              </Button>

              <Button variant="outlined" className="mx-2" onClick={() => setLeaveEditModal({ open: false, data: {} })}>
                Cancel
              </Button>
            </div>
          </div>
        }
      />


      {/* Leave Edit Form */}
      < Modal
        size="lg"
        show={leaveUpdateModal.open}
        onHide={() => {
          setLeaveUpdateModal((prev) => ({ ...prev, open: false }));
          clear()
        }}
        headerContent={< h5 className="py-2" > {"Edit Leave"}</h5 >}
        bodyContent={
          <div className="" >
            <div className='row'>
              <div className="col-lg-4 my-2">
                {leaveType === 'HALF' ? (
                  <DesktopDateTimePicker
                    className="w-100 form-field-design"
                    isRequired={true}
                    Label={'Start Date '}
                    handleDateChange={(date) => { handleDateChange(date, 'appliedStartDate'); getLeaveCount(date); setLeaveForm((pre) => ({ ...pre, leaveType: '', leave: null, subLeaveType: null })) }}
                    SelectedDate={leaveForm?.appliedStartDate}
                  />
                ) : (
                  <DateInput
                    className="w-100 form-field-design"
                    isRequired={true}
                    Label={'Start Date '}
                    handleDateChange={(date) => { handleDateChange(date, 'appliedStartDate'); getLeaveCount(date); setLeaveForm((pre) => ({ ...pre, leaveType: '', leave: null, subLeaveType: null })) }}
                    SelectedDate={leaveForm?.appliedStartDate}
                  />
                )}

                {leaveError?.appliedStartDate && <span className='text-danger'>{leaveError?.appliedStartDate}</span>}
              </div>
              <div className="col-lg-4 my-2">
                {leaveType === 'HALF' ? (
                  <DesktopDateTimePicker
                    className="w-100 form-field-design"
                    isRequired={true}
                    Label={'End Date'}
                    handleDateChange={(date) => handleDateChange(date, 'appliedEndDate')}
                    SelectedDate={leaveForm?.appliedEndDate}
                  />
                ) : (
                  <DateInput
                    className="w-100 form-field-design"
                    isRequired={true}
                    Label={'End Date'}
                    handleDateChange={(date) => handleDateChange(date, 'appliedEndDate')}
                    SelectedDate={leaveForm?.appliedEndDate}
                  />
                )}
                {leaveError?.appliedEndDate && <span className='text-danger'>{leaveError?.appliedEndDate}</span>}
              </div>
              <div className="col-lg-4 my-2">
                <SelectInput
                  LabelButton
                  Lable={'Leave'}
                  // multiple={true}
                  Options={leavePolicyCount}
                  keyName={'name'}
                  // extrakey={"leaveType"}
                  Name="leaveType"
                  SelectValue={leaveForm.leaveType}
                  HandleChange={(e) => { handleInputChange(e); checkType(e.target.value); setLeaveForm((pre) => ({ ...pre, leave: null, subLeaveType: null })) }}
                  Error={leaveError.leaveType}
                  variant={'outlined'}
                  isRequired={true}
                  ClassName={"auto"}
                  disabled={!leaveForm?.appliedStartDate && true}
                />
              </div>
              {
                leaveType === 'NORMAL' &&
                <div className="col-lg-4 my-2">
                  <SelectInput
                    LabelButton
                    Lable={'Leave Type'}
                    // multiple={true}
                    Options={LeaveType}
                    keyName={'title'}
                    extrakey={"leaveType"}
                    Name="leave"
                    SelectValue={leaveForm.leave}
                    HandleChange={(e) => { handleInputChange(e); setLeaveForm((pre) => ({ ...pre, subLeaveType: null })) }}
                    Error={leaveError.leave}
                    variant={'outlined'}
                    isRequired={true}
                    ClassName={"auto"}
                  // disabled={true}
                  />
                </div>
              }
              {
                leaveForm.leave === 'HALF' || leaveType === 'SHORT' ?
                  <div className="col-lg-4 my-2">
                    <SelectInput
                      LabelButton
                      Lable={'Sub Leave Type'}
                      // multiple={true}
                      Options={SubLeaveType}
                      keyName={'title'}
                      extrakey={"subLeaveType"}
                      Name="subLeaveType"
                      SelectValue={leaveForm.subLeaveType}
                      HandleChange={(e) => { handleInputChange(e); }}
                      Error={leaveError.subLeaveType}
                      variant={'outlined'}
                      isRequired={true}
                      ClassName={"auto"}
                    // disabled={true}
                    />
                  </div>
                  : null
              }


              <div className="col-lg-12 my-2">
                <label htmlFor="">
                  <sub>Description <span className='text-danger'>*</span></sub>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: CKEditorCustomToolbar,
                  }}
                  data={leaveForm.reason}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    handleInputChange({ target: { name: 'reason', value: data } });

                    setLeaveError((prevErrors) => {
                      let updatedErrors = { ...prevErrors };
                      updatedErrors = { reason: '' };
                      return updatedErrors;
                    });
                  }}
                  onBlur={(event, editor) => {
                    if (!leaveForm.reason) {
                      // Set the reason error if it's empty on blur
                      setLeaveError((prevErrors) => {
                        let updatedErrors = { ...prevErrors };
                        updatedErrors = { reason: 'Please enter reason' };
                        return updatedErrors;
                      });
                    }
                  }}
                />
                {leaveError?.reason && <p className="text-danger">{leaveError?.reason}</p>}
              </div>
            </div>
            <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
              <h5 className="">Attachments</h5>
              <Button variant="contained" onClick={() => fileInputRef?.current?.click()}>choose file</Button>
              <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTimeLogFileChange} style={{ display: 'none' }} />
            </div>
            {selectedFiles?.length > 0 && <div className="col-lg-12 ">
              <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                <div>
                  <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                    {selectedFiles?.map((file, fileIndex) => (
                      <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                        {fileType(file, fileIndex, "LEAVE")}
                        <Fab
                          className="m-2"
                          sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                          onClick={() => handleLogRemoveFile(fileIndex)}
                        >
                          <ClearIcon sx={{ fontSize: '15px' }} />
                        </Fab>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            }
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => { setLeaveUpdateModal((prev) => ({ ...prev, open: false })); clear() }} data-bs-dismiss="modal">
                Cancel
              </Button>
              <Button variant="contained" className="ms-2" onClick={submit}>
                Update
              </Button>
            </div>
          </div >
        }
      />

    </div>
  )
}

export default LeaveDetails
