import React from 'react'
import TextInput from '../../../components/TextField'
import SelectInput from '../../../components/SelectInput'
import { InputLabel, Autocomplete, TextField, FormControlLabel, FormGroup, Button } from '@mui/material'
import DateInput from '../../../components/DateInput'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const BasicDetails = ({ data, methods }) => {
  const { error, teamMemberInputs, allTeamList, allSkillList, timeZonelist, profilePath } = data
  const { handleInputChange, handleBlur, setTeamMemberInputs, handleDateChange, handleFileChange, handleRemoveFile, setError } = methods
  return (
    <>
      <div className="row mt-4">
        <div className='col-lg-12'>
          {
            teamMemberInputs?.profileImage ?
              <div className='d-flex justify-content-center position-relative'>
                <div>
                  <img src={profilePath + teamMemberInputs?.profileImage} alt="" height={150} width={150} style={{ borderRadius: '50%' }} />
                  <div className="position-absolute" style={{ left: '52%', bottom: '-5px', background: '#fff', borderRadius: '50%', padding: '4px' }}>
                    <CameraAltOutlinedIcon className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" />
                    <ul class="dropdown-menu">
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        // ref={fileInputRef}
                        style={{ display: "none" }}
                        // onChange={(e) =>handleFileChange(e)}

                        onChange={(event) => handleFileChange(event, 0, 'profileImage')} />
                      <Button variant="text" sx={{ color: "black", fontSize: "12px", marginLeft: "10px" }}
                        onClick={() => document.getElementById("icon-button-file").click()} startIcon={<EditIcon />} >
                        Edit
                      </Button>

                      <br />

                      <Button variant="text" sx={{ color: "black", fontSize: "12px", marginLeft: "10px", }}
                        startIcon={<DeleteIcon />}
                        onClick={() => handleRemoveFile(0, 0, 'profileImage')}
                      >
                        Delete
                      </Button>
                    </ul>
                  </div>

                  {/* <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Dropup
                    </button> */}

                </div>
              </div>
              :
              <div class="Neon Neon-theme-dragdropbox">
                <input onChange={(event) => handleFileChange(event, 0, 'profileImage')} style={{ zIndex: '999', opacity: '0', width: '320px', height: '200px', position: 'absolute', right: '0px', left: '0px', marginRight: 'auto', marginLeft: 'auto', }} name="files[]" id="filer_input2" type="file" />
                <div class="Neon-input-dragDrop">
                  <div class="Neon-input-inner">
                    <div class="Neon-input-icon"><CloudUploadIcon className='fs-1' />
                    </div>
                    <div class="Neon-input-text"><h3>Upload Image</h3>
                      {/* <span style={{display:'inline-block', margin: '15px 0'}}>or</span> */}
                    </div>
                    {/* <a class="Neon-input-choose-btn blue">Browse Files</a> */}
                  </div>
                </div>
              </div>
          }
        </div>
        <div className="col-lg-4 mt-4">
          <TextInput
            Lable="EMP ID"
            Name="empId"
            variant="outlined"
            ClassName="form-field-design"
            Require={true}
            HandleInputChange={handleInputChange}
            onBlur={handleBlur}
            Value={teamMemberInputs.empId}
            width={100}
            Error={error.empId}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <TextInput
            Lable="Biometric ID"
            Name="biometricId"
            variant="outlined"
            ClassName="form-field-design"
            HandleInputChange={handleInputChange}
            onBlur={handleBlur}
            Value={teamMemberInputs.biometricId}
            width={100}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <TextInput
            Lable="Email"
            Name="email"
            variant="outlined"
            ClassName="form-field-design"
            Require={true}
            HandleInputChange={handleInputChange}
            onBlur={handleBlur}
            Value={teamMemberInputs.email}
            width={100}
            Error={error.email}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <TextInput
            Lable="First Name"
            Name="firstName"
            variant="outlined"
            ClassName="form-field-design"
            Require={true}
            HandleInputChange={handleInputChange}
            onBlur={handleBlur}
            Value={teamMemberInputs.firstName}
            width={100}
            Error={error.firstName}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <TextInput
            Lable="Last Name"
            Name="lastName"
            variant="outlined"
            ClassName="form-field-design"
            // Require={true}
            HandleInputChange={handleInputChange}
            onBlur={handleBlur}
            Value={teamMemberInputs.lastName}
            width={100}
            Error={error.lastName}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <TextInput
            Lable="Mobile Number"
            Name="mobile_no"
            variant="outlined"
            ClassName="form-field-design"
            Require={true}
            HandleInputChange={handleInputChange}
            onBlur={handleBlur}
            Value={teamMemberInputs?.mobile_no}
            width={100}
            Error={error.mobile_no}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <DateInput
            className="w-100 form-field-design"
            isRequired={true}
            Label={'Date of Birth'}
            width={100}
            handleDateChange={(date) => { handleDateChange(date, 'dob'); setError((pre) => ({ ...pre, dob: '' })) }}
            SelectedDate={teamMemberInputs?.dob}
            name={'dob'}
          />
          {error?.dob && <span className='text-danger' style={{ fontSize: "11px" }}>{error?.dob}</span>}
        </div>
        <div className="col-lg-4 mt-4">
          <SelectInput
            Lable={'Team'}
            width={'310px'}
            Options={allTeamList}
            className="form-field-design"
            keyName={'name'}
            Name="teams"
            SelectValue={teamMemberInputs.teams}
            HandleChange={handleInputChange}
            onBlur={handleBlur}
            Error={error.teams}
            variant={'outlined'}
            isRequired={true}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <SelectInput
            Lable={'Skills'}
            multiple={true}
            width={'310px'}
            Options={allSkillList}
            keyName={'skillName'}
            className="form-field-design"
            Name="skills"
            SelectValue={teamMemberInputs.skills}
            HandleChange={handleInputChange}
            onBlur={handleBlur}
            Error={error.skills}
            variant={'outlined'}
            isRequired={true}
          />
        </div>
        <div className="col-lg-4 mt-4">
          <InputLabel id="demo-simple-select-label" sx={{ fontSize: '13px' }}>
            Time zone<span className="text-danger"> *</span>
          </InputLabel>
          <Autocomplete
            onChange={(e, newValue) => {
              setTeamMemberInputs((prevProps) => ({ ...prevProps, timezone: newValue }));
              setError((pre) => ({ ...pre, timezone: '' }))
            }}
            disableClearable={true}
            value={teamMemberInputs.timezone || null}
            size="small"
            options={timeZonelist}
            getOptionLabel={(option) => `${option.text}`}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            renderOption={(props, option) => {
              return <li {...props}>{`${option?.text}`}</li>
            }}
          />
          {error.timezone && <p className="text-danger">{error.timezone}</p>}
        </div>
      </div>
    </>
  )
}

export default BasicDetails
