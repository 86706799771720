import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import Private from '../layout/Private'
import Public from '../layout/Public'
import Login from '../Pages/credentials/Login'
import ForgotPassword from '../Pages/credentials/ForgotPassword'
import CreateNewPassword from '../Pages/credentials/CreateNewPassword'
import Tickets from '../Pages/Tickets/Tickets'
import Projects from '../Pages/Projects/Projects'
import Setting from '../Pages/Settings/Setting'
import ReportingAnalytics from '../Pages/Reporting&Analytics/ReportingAnalytics'
import RolesPermissions from '../Pages/Roles&Permissions/RolesPermissions'
import UserManagement from '../Pages/UserManagement/UserManagement'
import Contacts from '../Pages/Tickets/Contacts'
import Email from '../Pages/Tickets/Email'
import NewTicketForm from '../Pages/Tickets/NewTicketForm'
import AllProject from '../Pages/Projects/AllProject'
import NewProjectForm from '../Pages/Projects/NewProjectForm'
import AgentsProfile from '../Pages/UserManagement/AgentsProfile'
import NewAgentform from '../Pages/UserManagement/NewAgentform'
import NewReport from '../Pages/Reporting&Analytics/NewReport'
import NewRoleForm from '../Pages/Roles&Permissions/NewRoleForm'
import Team from '../Pages/Settings/Team/Team'
// import Channel from '../Pages/Settings/Channel/Channel'
import Workflow from '../Pages/Settings/Workflow/Workflow'
import Accounts from '../Pages/Settings/Accounts/Accounts'
import AddBusinessHours from '../Pages/Settings/Team/AddBusinessHours'
import AdminProfile from '../components/Headers/AdminProfile'
import CreateSkill from '../Pages/Settings/Team/CreateSkill'
import CreateNewShift from '../Pages/Settings/Team/CreateNewShift'
import useReduxStore from '../hooks/useReduxStore'
import NewSupportEmail from '../Pages/Settings/Channel/NewSupportEmail'
import NewSupportPhone from '../Pages/Settings/Channel/NewSupportPhone'
import NewTicketWorkflow from '../Pages/Settings/Workflow/NewTicketWorkflow'
import FeedbackForm from '../Pages/Settings/Workflow/FeedbackForm'
import TicketCreationForm from '../Pages/Settings/Workflow/TicketCreationForm'
import TimeTriggersForm from '../Pages/Settings/Workflow/TimeTriggersForm'
import TicketUpdatesForms from '../Pages/Settings/Workflow/TicketUpdatesForms'
import TeamMainPage from '../Pages/Settings/Team/TeamMainPage'
import Skills from '../Pages/Settings/Team/Skills'
import SkillForm from '../Pages/Settings/Team/SkillForm'
// import ShiftList from '../Pages/Settings/Team/ShiftList'
// import ShiftForm from '../Pages/Settings/Team/ShiftForm'
import StatusList from '../Pages/Settings/Team/StatusList'
import StatusForm from '../Pages/Settings/Team/StatusForm'
import TagList from '../Pages/Settings/Team/TagList'
import TagForm from '../Pages/Settings/Team/TagForm'
import ChannelMainPage from '../Pages/Settings/Channel/ChannelMainPage'
import EmailList from '../Pages/Settings/Channel/EmailList'
import PhoneList from '../Pages/Settings/Channel/PhoneList'
import ChatList from '../Pages/Settings/Channel/ChatList'
import EmailForm from '../Pages/Settings/Channel/EmailForm'
import PhoneForm from '../Pages/Settings/Channel/PhoneForm'
import ChatForm from '../Pages/Settings/Channel/ChatForm'
import WorkFlowMainPage from '../Pages/Settings/Workflow/WorkFlowMainPage'
import TicketFormList from '../Pages/Settings/Workflow/TicketFormList'
import FeedBackForm from '../Pages/Settings/Workflow/FeedbackForm'
import AutomationMainPage from '../Pages/Settings/Workflow/Automation/AutomationMainPage'
import TicketCreation from '../Pages/Settings/Workflow/Automation/TicketCreation'
import TimeTrigger from '../Pages/Settings/Workflow/Automation/TimeTrigger'
import TicketUpdates from '../Pages/Settings/Workflow/Automation/TicketUpdates'
import NotificationMainPage from '../Pages/Settings/Workflow/Notification/NotificationMainPage'
import AgentNotification from '../Pages/Settings/Workflow/Notification/AgentNotification'
import RequesterNotification from '../Pages/Settings/Workflow/Notification/RequesterNotification'
import CCNotification from '../Pages/Settings/Workflow/Notification/CCNotification'
import TemPlates from '../Pages/Settings/Workflow/Notification/TemPlates'
import TicketsDetails from '../Pages/Tickets/ReportedByUser/TicketsDetails'
import Notes from '../Pages/Tickets/ReportedByUser/Notes'
import ReportedByUserMainPage from '../Pages/Tickets/ReportedByUser/ReportedByUserMainPage'
import ProjectDetailsMainPage from '../Pages/Projects/ProjectDetailsMainPage'
import ProjectTicketsDetails from '../Pages/Projects/ProjectTicketsDetails'
import ProjectNotes from '../Pages/Projects/ProjectNotes'
import AgentMainPage from '../Pages/UserManagement/AgentMainPage'
import AgentDetails from '../Pages/UserManagement/AgentDetails'
import Task from '../Pages/Task/Task'
import TaskForm from '../Pages/Task/TaskForm'
import TaskDetails from '../Pages/Task/TaskDetails'
import TimeLog from '../Pages/Time Log/TimeLog'
import Dashboard from '../components/Dashboard/Dashboard'
import { Navigate } from 'react-router-dom/dist'
import TicketReply from '../Pages/Tickets/TicketReply'
import TicketNotes from '../Pages/Tickets/TicketNotes'
import { ApproveTask } from '../Pages/Task/ApproveTask'
import ErrorPage from '../components/ErrorComponent/ErrorPage'
import TicketTimeLine from "../Pages/Tickets/TicketTimeLine"
import ResetPassword from '../Pages/credentials/ResetPassword'
import ChangePassword from '../Pages/credentials/ChangePassword'
import UserTimeLog from '../Pages/Time Log/UserTimeLog'
import AttendanceList from '../Pages/Attendance/AttendanceList'
import UploadAttendance from '../Pages/Attendance/UploadAttendance'
import EditAttendance from '../Pages/Attendance/EditAttendance'
import LeaveForm from '../Pages/Attendance/LeaveForm'
import TaskTimeLine from '../Pages/Task/TaskTimeLine'
import ViewTimeLog from '../Pages/Task/ViewTimeLog'
import BreakTime from '../Pages/Task/BreakTime'
import TimeLogList from '../Pages/Time Log/TimeLogList'
import ShiftForm from '../Pages/Settings/Shifts/ShiftForm'
import ShiftDetails from '../Pages/Settings/Shifts/shiftDetails'
import AddCalendar from '../Pages/Settings/Shifts/calendarForm'
import ShiftList from '../Pages/Settings/Shifts/ShiftList'
import ShiftHistory from '../Pages/Settings/Shifts/shiftHistoryList'
import ShiftMainPage from '../Pages/Settings/Shifts/ShiftMainPage'
import LeavePolicyForm from '../Pages/Settings/LeavePolicy/LeavePolicyForm'
import LeavePolicyMainPage from '../Pages/Settings/LeavePolicy/LeavePolicyMainPage'
import LeavePolicyList from '../Pages/Settings/LeavePolicy/LeavePolicyList'
import LeavesList from '../Pages/Leaves/LeavesList'
// import LeaveForm from '../Pages/Leaves/LeaveForm'
import ReportMainPage from '../Pages/Settings/Reports/ReportMainPage'
import ReportsList from '../Pages/Settings/Reports/ReportsList'
import LeavesDetailsMainPage from '../Pages/Leaves/LeavesDetailsMainPage'
import LeaveDetails from '../Pages/Leaves/LeaveDetails'
import LeaveTimeLine from '../Pages/Leaves/LeaveTimeLine'
import IdleUsers from '../Pages/Task/idleUsers'

import AgentActivity from '../Pages/UserManagement/AgentActivity'
import AgentTimeline from '../Pages/UserManagement/agentTimeline'
import Notification from '../Pages/Notifications/notification'
import AddAttendance from '../Pages/Attendance/addAttendance'
import BreakReport from '../Pages/Settings/Reports/breakReport'
import EarnLeave from '../Pages/Settings/LeavePolicy/earnLeave'

import AgentJobDetails from '../Pages/UserManagement/Details View/AgentJobDetails'
import AgentPersonalDetails from '../Pages/UserManagement/Details View/AgentPersonalDetails'
import AgentOtherDetails from '../Pages/UserManagement/Details View/AgentOtherDetails'
import Announcement from '../Pages/Settings/Announcement/announcement'
import WebsiteSceduling from '../Pages/Settings/WebsiteSceduling'


const Allroutes = () => {
  // const navigate = useNavigate()
  const { selector: user } = useReduxStore('auth')
  // console.log(user, "user");
  return (
    <>
      <Routes>
        {!user?.token ? (
          <Route path="/" element={<Public />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="create-new-password/:id" element={<CreateNewPassword />} />
            <Route path="reset-password/:id" element={<ResetPassword />} />
          </Route>
        ) : (
          <Route path="/" element={<Private />}>
            <Route path="/" element={<Navigate replace to={'/dashboard'} />} />
            <Route path="tickets" element={<Tickets />} />
            <Route path="dashboard" element={<Dashboard />} />

            <Route path="task" element={<Task />} />
            <Route path="taskform" element={<TaskForm />} />
            {/* <Route path="/task/taskdetails/:id" element={<TaskDetails />} /> */}
            <Route path="/task/approve-task" element={<ApproveTask />} />

            <Route path="/task/taskdetails/:id" element={<TaskDetails />}>
              <Route index element={<TaskTimeLine />} />
              <Route path="time-log" element={<ViewTimeLog />} />
              <Route path="break-time" element={<BreakTime />} />
            </Route>

            <Route path="idle-user" element={<IdleUsers />} />



            {/* Attendance Routs */}
            <Route path="attendance" element={<AttendanceList />} />
            <Route path="/attendance/upload-attendance" element={<UploadAttendance />} />
            <Route path="/attendance/upload-attendance/:id" element={<EditAttendance />} />
            <Route path="/attendance/leave-form" element={<LeaveForm />} />
            <Route path="/attendance/add-attendance" element={<AddAttendance />} />

            {/* leaves */}
            {/* <Route path="/attendance/leaves-request" element={<LeavesList />} />
            <Route path='/attendance/leaves-request/leave-details/:id' element={<LeavesDetailsMainPage />}>
              <Route index element={<LeaveDetails />} />
              <Route path='time-line' element={<LeaveTimeLine />} /
              >
            </Route> */}


            <Route path="/leaves-request" element={<LeavesList />} />
            <Route path='/leaves-request/leave-details/:id' element={<LeavesDetailsMainPage />}>
              <Route index element={<LeaveDetails />} />
              <Route path='time-line' element={<LeaveTimeLine />} />
            </Route>


            <Route path="time-log" element={<UserTimeLog />} />
            {/* <Route path="/time-log/:userTimeLog" element={<TimeLog />} /> */}


            <Route path="/time-log/:userTimeLog" element={<TimeLog />}>
              <Route index element={<TimeLogList />} />
            </Route>


            {/* Tickets Reporting by User Route */}
            <Route path="/tickets/user/:id" element={<ReportedByUserMainPage />}>
              <Route path="ticket" element={<TicketsDetails />} />
              <Route path="note" element={<Notes />} />
            </Route>

            <Route path="contacts/:id" element={<Contacts />} />
            <Route path="/tickets/new-ticket-form" element={<NewTicketForm />} />
            <Route path="tickets/email/:id" element={<Email />} />
            <Route path="projects" element={<Projects />} />

            <Route path="tickets/email/:id" element={<Email />}>
              <Route index element={<TicketReply />} />
              <Route path="note" element={<TicketNotes />} />
              <Route path="time-line" element={<TicketTimeLine />} />
            </Route>

            {/* Tickets projects User Route */}
            <Route path="projects/:projectId" element={<ProjectDetailsMainPage />}>
              <Route path="ticket" element={<ProjectTicketsDetails />} />
              <Route path="note" element={<ProjectNotes />} />
            </Route>

            <Route path="AllProject/:id" element={<AllProject />} />
            <Route path="/projects/project-form/:projectId" element={<NewProjectForm />} />
            <Route path="team-management" element={<UserManagement />} />
            {/* Tickets projects User Route */}
            <Route path="team-management/:agentId" element={<AgentMainPage />}>
              <Route path="leave" element={<AgentDetails />} />
              <Route path="timeline" element={<AgentTimeline />} />
              <Route path="activity" element={<AgentActivity />} />
              <Route path="job-details" element={<AgentJobDetails />} />
              <Route path="personal-details" element={<AgentPersonalDetails />} />
              <Route path="other-details" element={<AgentOtherDetails />} />
              {/* <Route path="note" element={<ProjectNotes />} /> */}
            </Route>
            <Route path="team-management/:agentId/leave/:id" element={<LeavePolicyForm />} />


            <Route path="agentsprofile/:id" element={<AgentsProfile />} />
            <Route path="/team-management/new-agent-form/:agentId" element={<NewAgentform />} />
            <Route path="roles-&-permissions" element={<RolesPermissions />} />
            <Route path="/roles-&-permissions/role-form/:roleId" element={<NewRoleForm />} />
            <Route path="reporting-&-analytics" element={<ReportingAnalytics />} />
            <Route path="newreport/:id" element={<NewReport />} />
            <Route path="setting" element={<Setting />} />

            <Route path='notification' element={<Notification />} />

            {/* Team Add/Edit Form Route*/}
            <Route path="/setting/group/business-hours-form/:groupId" element={<AddBusinessHours />} />
            <Route path="/setting/group/skill-form" element={<SkillForm />} />
            {/* <Route path="/setting/group/shift-form" element={<ShiftForm />} /> */}
            <Route path="/setting/group/status-form" element={<StatusForm />} />
            <Route path="/setting/group/tag-form" element={<TagForm />} />

            {/* Setting Team Route */}
            <Route path="setting" element={<TeamMainPage />}>
              <Route path="group" element={<Team />} />
              <Route path="skill-list" element={<Skills />} />
              {/* <Route path="shift-list" element={<ShiftList />} /> */}
              <Route path="status-list" element={<StatusList />} />
              <Route path="tag-list" element={<TagList />} />

            </Route>
            <Route path="setting/announcement" element={<Announcement />} />
            <Route path="setting/website-sceduling" element={<WebsiteSceduling />} />


            {/* Shifts Route's */}
            <Route path="/setting/shifts/shift-form/:id/:action" element={<ShiftForm />} />
            <Route path="/setting/shifts/shift-form/:id/" element={<ShiftForm />} />
            <Route path="/setting/shifts/shift-details/:id/" element={<ShiftDetails />} />
            <Route path="/setting/shifts/shift-details/:id/:calendarId/" element={<AddCalendar />} />
            {/* <Route path="/setting/shifts/shift-details/:id/edit-calendar/" element={<AddCalendar />} /> */}
            <Route path="setting" element={<ShiftMainPage />}>
              <Route path="shifts" element={<ShiftList />} />
              <Route path="shifts-history" element={<ShiftHistory />} />
            </Route>

            {/* Leave Policy Route's */}
            <Route path="/setting/leave-policy/leave-policy-form/:id" element={<LeavePolicyForm />} />
            <Route path="/setting/leave-policy/leave-policy-form/:id/:action" element={<LeavePolicyForm />} />
            <Route path="setting" element={<LeavePolicyMainPage />}>
              <Route path="leave-policy" element={<LeavePolicyList />} />
              <Route path="earn-leave" element={<EarnLeave />} />
            </Route>

            {/* Shifts Route's */}
            <Route path="/setting/reports/reports-form/:id" element={<ShiftForm />} />
            {/* <Route path="setting" element={<ReportMainPage />}>
              <Route path="reports" element={<ReportsList />} />
            </Route> */}
            {/* <Route path="reports" element={<ReportsList />} /> */}
            <Route path="reports" element={<ReportMainPage />}>
              <Route index element={<ReportsList />} />
              <Route path="break-report" element={<BreakReport />} />
            </Route>


            {/* Channel Add/Edit Form Route*/}
            <Route path="/setting/channel/email-form" element={<EmailForm />} />
            <Route path="/setting/channel/phone-form" element={<PhoneForm />} />
            <Route path="/setting/channel/chat-form" element={<ChatForm />} />

            <Route path="/setting/channel" element={<ChannelMainPage />}>
              <Route path="email" element={<EmailList />} />
              <Route path="phone" element={<PhoneList />} />
              <Route path="chat" element={<ChatList />} />
            </Route>

            {/* WorkFlow Add/Edit Form Route*/}
            <Route path="/setting/workflow/email-form" element={<EmailForm />} />
            <Route path="/setting/workflow/phone-form" element={<PhoneForm />} />
            <Route path="/setting/workflow/chat-form" element={<ChatForm />} />
            {/* Workflow Routes */}
            <Route path="/setting/workflow" element={<WorkFlowMainPage />}>
              <Route path="ticket-form-list" element={<TicketFormList />} />
              {/* Automation Routs */}
              <Route path="automation" element={<AutomationMainPage />}>
                <Route path="ticket-creation" element={<TicketCreation />} />
                <Route path="time-trigger" element={<TimeTrigger />} />
                <Route path="ticket-updates" element={<TicketUpdates />} />
              </Route>

              {/* Notification Routs */}
              <Route path="notification" element={<NotificationMainPage />}>
                <Route path="agent-notification" element={<AgentNotification />} />
                <Route path="requester-notification" element={<RequesterNotification />} />
                <Route path="CC-notification" element={<CCNotification />} />
                <Route path="templates" element={<TemPlates />} />
              </Route>
              <Route path="feedBack-form" element={<FeedBackForm />} />
            </Route>

            {/* <Route path='addbusinesshours/:id' element={<AddBusinessHours />} /> */}
            <Route path="createskill/:id" element={<CreateSkill />} />
            <Route path="createnewshift/:id" element={<CreateNewShift />} />
            <Route path="newsupportemail/:id" element={<NewSupportEmail />} />
            <Route path="newsupportphone/:id" element={<NewSupportPhone />} />
            <Route path="setting/workflow/:id" element={<Workflow />} />
            <Route path="newticketworkflow/:id" element={<NewTicketWorkflow />} />
            <Route path="feedbackform/:id" element={<FeedbackForm />} />
            <Route path="ticketcreationform/:id" element={<TicketCreationForm />} />
            <Route path="timetriggersform/:id" element={<TimeTriggersForm />} />
            <Route path="ticketupdatesforms/:id" element={<TicketUpdatesForms />} />
            <Route path="accounts/:id" element={<Accounts />} />
            {/* <Route path="adminprofile" element={<AdminProfile />} /> */}

            <Route path="adminprofile" element={<AdminProfile />}>
              <Route path="leave" element={<AgentDetails />} />
              <Route path="timeline" element={<AgentTimeline />} />
              <Route path="activity" element={<AgentActivity />} />
              <Route path="job-details" element={<AgentJobDetails />} />
              <Route path="personal-details" element={<AgentPersonalDetails />} />
              <Route path="other-details" element={<AgentOtherDetails />} />
              {/* <Route path="note" element={<ProjectNotes />} /> */}
            </Route>

            <Route path="change-password" element={<ChangePassword />} />

            <Route path="login" element={<Navigate replace to={'/dashboard'} />} />
            <Route path="forgotpassword" element={<Navigate replace to={'/dashboard'} />} />
            <Route path="create-new-password/:id" element={<Navigate replace to={'/dashboard'} />} />
          </Route>
        )}
        <Route path={'*'} element={<ErrorPage />} />
      </Routes>
    </>
  )
}

export default Allroutes
