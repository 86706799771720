import React from 'react'
import useShiftAddUpdateHook from './Hooks/useShiftAddUpdateHook'
import { CKEditorCustomToolbar, HolidayType, YearList } from '../../../constants'
import SelectInput from '../../../components/SelectInput'
import { Button } from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Modal from '../../../components/Modal'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../../components/Spinner/Spinner'

const CalendarForm = () => {
    const { data, methods } = useShiftAddUpdateHook()
    const {
        spinner,
        shiftForm,
        shiftError,
        CompDaysObj,
        pubHoliDaysObj,
        restDaysObj,
        offDaysObj,
        calendarData,
        shiftId,
        errors,
        breakHoursInput,
        dayDetailModal,
        year,
        calendarId
    } = data

    const {
        setShiftError,
        handleShiftInputChange,
        handleDateChange,
        handleCheckDays,
        focusHandle,
        bgHandle,
        handleOffDays,
        submit,
        Update,
        removeBreak,
        addMultipleBreak,
        setErrors,
        handleBreakInput,
        handleDayDetails,
        setDayDetailModal,
        setRenderYear,
        monthWorking,
        setYear,
        addCalendar,
        updateCalendar
    } = methods

    const renderData = (dataType) => {
        let hasRecords = false;
        // console.log(calendarData, 'calendarData')
        return (
            <div>
                {calendarData?.map((item, index) =>
                    item?.days?.map((inner, innerIndex) => {
                        if (inner?.details &&
                            ((dataType === 'publicHoliday' && inner?.publicHoliday) ||
                                (dataType === 'compensationDay' && inner?.compensationDay))) {
                            hasRecords = true;
                            return (
                                <div key={`${index}-${innerIndex}`}>
                                    <b dangerouslySetInnerHTML={{ __html: `${inner.date} <sup>${inner.dayOfWeek}</sup> ${item.monthName} ${item.year}` }} />
                                    <p dangerouslySetInnerHTML={{ __html: inner.details }}></p>
                                </div>
                            );
                        }
                        return null;
                    })
                )}
                {!hasRecords && (<div><b>N/A</b></div>)}
            </div>
        );
    };
    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    // console.log(shiftForm,'shiftForm')
    return (
        <div>
             {spinner && <Spinner />}
            <ToastContainer />
            <div className='bg-white p-4 mx-4'>
                <div className='row'>
                    <div className="col-lg-6 my-2">
                        <SelectInput
                            LabelButton
                            Lable={'Year'}
                            // multiple={true}
                            Options={YearList}
                            keyName={'title'}
                            Name="year"
                            SelectValue={year}
                            HandleChange={(e) => { setYear(e.target.value); setRenderYear(true); }}
                            // onBlur={handleBlur}
                            // Error={shiftError.year}
                            variant={'outlined'}
                            isRequired={true}
                        // disabled={shiftForm?.graceInAction == false}
                        />
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-6">
                        <h4>Public holiday</h4>
                        {renderData('publicHoliday')}
                    </div>
                    <div className="col-6">
                        <h4>Compensatory Off</h4>
                        {renderData('compensationDay')}
                    </div>
                </div>


                <div className='row'>
                    <div className="col-10 ">
                        <div className="row">
                            {
                                calendarData?.map((item, index) =>
                                    <>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xlg-4 p-3">
                                            <div className="border rounded h-100 pb-5">
                                                <div className='calendar-header p-2'>
                                                    <span className='fw-bold'>{item?.year}</span>
                                                    <span className='fw-bold'>{item?.monthName}</span>
                                                </div>
                                                <div className='d-flex flex-column justify-content-center'>
                                                    <div className='calendar-week-header mt-2'>
                                                        {
                                                            weekdays.map((weekday, index) => {
                                                                return <div className="text-center fw-bold" key={index}><p className='m-0 p-0'>{weekday}</p></div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className='d-flex flex-wrap'>
                                                        {
                                                            item?.days?.map((innerItem, innerIndex) => (
                                                                <div className='calendar-boxx' key={`checkbox-${index}-${innerIndex}`}>
                                                                    {innerItem.isDay ? <div></div> :
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`checkbox-${index}-${innerIndex}`}
                                                                                onChange={(e) => {
                                                                                    if (!innerItem?.publicHoliday && pubHoliDaysObj.pubHoliDay) {
                                                                                        if (!innerItem?.compensationDay && !innerItem?.restDay && !innerItem?.offDay) {
                                                                                            setDayDetailModal((prev) => ({
                                                                                                ...prev,
                                                                                                open: true,
                                                                                                type: "PublicHoliDay",
                                                                                                index,
                                                                                                innerIndex,
                                                                                                event: e,
                                                                                            }));
                                                                                        }
                                                                                    }
                                                                                    if (!innerItem?.compensationDay && CompDaysObj.compDay) {
                                                                                        if (!innerItem?.publicHoliday && !innerItem?.restDay && !innerItem?.offDay) {
                                                                                            setDayDetailModal((prev) => ({
                                                                                                ...prev,
                                                                                                open: true,
                                                                                                type: "CompensationDay",
                                                                                                index,
                                                                                                innerIndex,
                                                                                                event: e,
                                                                                            }));
                                                                                        }
                                                                                    }
                                                                                    handleCheckDays(e, index, innerIndex)
                                                                                }}
                                                                                style={{ display: 'none' }}
                                                                                disabled={
                                                                                    !(
                                                                                        restDaysObj.restDay ||
                                                                                        offDaysObj.offDay ||
                                                                                        CompDaysObj.compDay ||
                                                                                        pubHoliDaysObj.pubHoliDay
                                                                                    )
                                                                                }
                                                                                checked={(
                                                                                    (innerItem?.compensationDay || CompDaysObj.compDay) ||
                                                                                    (innerItem?.publicHoliday || pubHoliDaysObj.pubHoliDay) ||
                                                                                    innerItem?.restDay ||
                                                                                    innerItem?.offDay
                                                                                )}
                                                                            />
                                                                            <label
                                                                                htmlFor={`checkbox-${index}-${innerIndex}`}
                                                                                className="calendar-custom-date-box"
                                                                                style={{
                                                                                    backgroundColor: bgHandle(innerItem),
                                                                                    border: focusHandle(),
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                            >
                                                                                <span className=''>{innerItem.date}</span>
                                                                            </label>
                                                                        </>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='position-relative'>
                                                <div className='fw-bold position-absolute bottom-0 start-0 ms-2 mb-2'>Working Days - {monthWorking(item?.monthName)}</div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-2 position-relative">
                        <div className=' border px-2' style={{ position: 'sticky', top: "11%", right: "2%" }}>
                            <div className="row">
                                <div className="col-12">
                                    <h4 className='text-center my-2'>Set Off Days</h4>
                                </div>
                                <div className="col-12">
                                    <Button sx={{ minWidth: "100%", background: "#f3963bdb" }} variant="contained" onClick={() => { { handleOffDays(HolidayType.OffDays) } }}>Off Days</Button>
                                </div>
                                <div className="col-12">
                                    <h3 className='text-center m-3'>{offDaysObj.selectedOffDays}</h3>
                                </div>
                                <div className="col-12">
                                    <Button sx={{ minWidth: "100%", background: "#70afdde0" }} variant="contained" onClick={() => { { handleOffDays(HolidayType.RestDays) } }} color='inherit'>Rest Days</Button>
                                </div>
                                <div className="col-12">
                                    <h3 className='text-center m-3'>{restDaysObj.selectedRestDays}</h3>
                                </div>
                                <div className="col-12">
                                    <Button sx={{ minWidth: "100%", background: "#78c223c4" }} variant="contained" onClick={() => { { handleOffDays(HolidayType.PublicHolidays) } }} color='warning'>Public Holidays</Button>
                                </div>
                                <div className="col-12">
                                    <h3 className='text-center m-3'>{pubHoliDaysObj.selectedPubHoliDays}</h3>
                                </div>
                                <div className="col-12">
                                    <Button sx={{ minWidth: "100%", background: "#aa3b19cc" }} variant="contained" onClick={() => { { handleOffDays(HolidayType.Compensatoryoff) } }} color='info'> Compensatory off</Button>
                                </div>
                                <div className="col-12">
                                    <h3 className='text-center m-3'>{CompDaysObj.selectedCompDays}</h3>
                                </div>
                            </div>
                            {
                                calendarId !=='add-calendar' ?
                                    <Button sx={{ minWidth: "100%", }} variant="contained" onClick={updateCalendar} color='info'> Update</Button>
                                    :
                                    <Button className='my-3' sx={{ minWidth: "100%", }} variant="contained" onClick={addCalendar} color='info'> submit</Button>


                                //   <div className="col-12 my-3">
                                //     {
                                //       shiftId !== "add" ?
                                //         <Button sx={{ minWidth: "100%", }} variant="contained" onClick={Update} color='info'> Update</Button>
                                //         :
                                //         <Button sx={{ minWidth: "100%", }} variant="contained" onClick={submit} color='info'> submit</Button>
                                //     }
                                //   </div>
                            }
                        </div>
                    </div>
                </div>

                {/* Days Detail Modal */}
                <Modal
                    size="md"
                    show={dayDetailModal.open}
                    onHide={() => setDayDetailModal((prev) => ({ ...prev, open: false }))}
                    headerContent={<h5 className="py-2">{"Holiday Description"}</h5>}
                    bodyContent={
                        <div className="">
                            <div className="col-lg-12 my-2">
                                <label htmlFor="">
                                    <sub>Description </sub>
                                </label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: CKEditorCustomToolbar,
                                    }}
                                    // data={dayDetailModal?.description}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        // handleInputChange({ target: { name: 'description', value: data } })
                                        setDayDetailModal((prev) => ({ ...prev, description: data }))
                                    }}
                                />
                            </div>
                            <div className="d-flex justify-content-end mt-4">
                                <Button variant="outlined" onClick={() => setDayDetailModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                                    No
                                </Button>
                                <Button variant="contained" className="ms-2" onClick={handleDayDetails}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default CalendarForm