/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Allroutes from './routes/Allroutes';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-time-picker/assets/index.css';
import { UserTokenRefresh, axiosInstance } from './repository/auth';
import authHeader from './repository/auth-header';
import { useDispatch } from 'react-redux';
import { add } from './Slices/AuthSlice';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Function to refresh the access token
    const refreshAccessToken = async () => {
      const token = authHeader();
      const data = { refreshToken: token?.refreshToken };
      try {
        const response = await UserTokenRefresh(data);
        const newAccessToken = response.data;
        dispatch(add(newAccessToken));
        return newAccessToken;
      } catch (error) {
        console.error('Error refreshing token:', error.message);
        throw error;
      }
    };

    // Axios interceptor to handle errors and token refresh
    axiosInstance.interceptors.response.use(
      (response) => response, // Pass through successful responses
      async (error) => {
        const originalRequest = error.config;

        if (error.code === 'ECONNREFUSED') {
          console.error('Connection refused:', error.message);
          return Promise.reject(error);
        }

        // Handle 401 Unauthorized errors
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newAccessToken = await refreshAccessToken();
            if (newAccessToken) {
              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken?.token}`;
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken?.token}`;
              return axiosInstance(originalRequest); // Retry the original request
            }
          } catch (error) {
            console.error('Failed to refresh token:', error.message);
            localStorage.clear();
            window.location = '/';
            sessionStorage.setItem(
              'authError',
              JSON.stringify('Your login session has timed out. Please log in again to continue.')
            );
          }
        }

        // Handle 403 Forbidden errors
        if (error.response?.status === 403) {
          localStorage.clear();
          sessionStorage.setItem(
            'authError',
            JSON.stringify('Your login session has timed out. Please log in again to continue.')
          );
          setTimeout(() => {
            window.location = '/';
          }, 1500);
        }

        // Handle network errors
        if (error.code === 'ERR_NETWORK') {
          error.message = 'Server is offline. Please try again later.';
        }

        return Promise.reject(error); // Reject the error for further handling
      }
    );
  }, [dispatch]);

  return <Allroutes />;
};

export default App;
