/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { getUserProfile, profilePath, updateUserProfile } from "../../../repository/auth";
import { requiredMessage } from "../../../utils";
import { toast } from 'react-toastify'
import useReduxStore from '../../../hooks/useReduxStore';
import { useLocation, } from 'react-router-dom'
import { LeaveCountsList } from '../../../repository/leavePolicyRepo';
import { getTeamActivity } from '../../../repository/teamRepo';
import { uploadFile } from '../../../repository/attendanceRepo';


const UseAdminProfile = () => {
    const { selector: user } = useReduxStore("auth")
    const [isEdit, setIsEdit] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [userProfileInputs, setProfileInputs] = useState({
        biometricId: "",
        empId: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "",
        skills: [],
        teams: [],
        role: "",
        designation: '',
        timeZone: "",
        profileImage:''
    })
    const [error, setError] = useState({})
    const [userDetails, setUserDetaild] = useState({})
    const [leavePolicyCount, setLeavePolicyCount] = useState([])
    const [activityData, setActivityData] = useState([])
    const [count, setCount] = useState(10)
    const [teamMemberInputs, setTeamMemberInputs] = useState({
        // Job Details
        designation: "",
        dateOfJoining: '',
        // status: '',
        shift: "",
        // empType: '',
        role_id: '',
        reportingManager: null,
        action: "",

        // Personal Details
        perAddress1: '',
        perAddress2: '',
        perCountry: '',
        perState: '',
        perCity: '',
        perPincode: '',
        sameAddress: false,

        currAddress1: '',
        currAddress2: '',
        currCountry: '',
        currState: '',
        currCity: '',
        currPincode: '',

        document: [{
            docType: '',
            number: '',
            file: []
        }],
        familyDetails: [{
            name: '',
            relation: '',
            contactNo: ''
        }],

        // Other Details
        previousExperience: [{
            company: '',
            designation: '',
            startDate: '',
            endDate: '',
            duration: '',
            reasonToResign: '',
            file: []
        }],

        educationQualification: [{
            course: '',
            specialization: '',
            school_University: '',
            year: '',
            file: []
        }],
        allDetails: []

    })
    const location = useLocation()
    const splitLocation = location.pathname.split('/')
    useEffect(() => {
        getUserDetails()
        getLeaveCount()
    }, [])

    // console.log(userProfileInputs, 'userProfileInputs')

    useEffect(() => {
        if (splitLocation[2] === 'timeline' || splitLocation[2] === 'activity') {
            getActivity(splitLocation[2])
        }
    }, [splitLocation[2] === 'timeline', splitLocation[2] === 'activity', count])


    const getLeaveCount = async () => {
        try {
            setSpinner(true)
            let response = await LeaveCountsList(user?.userInfo?._id)
            setLeavePolicyCount(response?.data)
        } catch (error) {
            //  toast.error(error.message)
        }
        setSpinner(false)
    }
    const getActivity = async (type) => {
        // const type=splitLocation[3]=='timeline'?'timeline':'activity'
        const payLoad = {
            page: 1,
            count: count,
            user_id: user?.userInfo?._id,
            types: type,

        }
        try {
            setSpinner(true)
            let response = await getTeamActivity(payLoad)
            setActivityData(response.data)
        } catch (error) {
            // toast.error(error.message)
        }
        setSpinner(false)
    }
    const fetchMoreData = () => setCount((prev) => prev + 10);

    const getUserDetails = async () => {
        try {
            setSpinner(true)
            let response = await getUserProfile(user?.userInfo?._id)
            // const { firstName, lastName, email, mobile_no, biometricId, empId, skills, teams, timezone, roleData, designation } = response.data
            const { email, firstName, lastName, role_id, teams, skills, timezone, empId, biometricId, designation, reportingManager_id, dob, dateOfJoining,
                employeedetails, sameAddress, image, roleData, mobile_no } = response.data
            const teamName = teams.map((team) => team.name)
            const skillIds = skills.map((skill) => skill._id)
            setUserDetaild(response.data)
            setProfileInputs({
                biometricId: biometricId,
                empId: empId,
                first_name: firstName,
                last_name: lastName,
                email: email,
                mobile_no: mobile_no,
                skills: skills,
                teams: teams,
                role: roleData,
                designation: designation,
                timeZone: timezone,
                profileImage:image
            })
            setTeamMemberInputs((pre) => ({
                ...pre,
                designation: designation,
                profileImage: image,
                dob: new Date(dob),
                dateOfJoining: new Date(dateOfJoining),
                role_id: role_id,
                skills: skillIds,
                groupName: teamName,
                // timezone: currentTimeZone,
                fullDetails: response?.data,
                reportingManager: reportingManager_id,

                // Personal Details
                // document: employeedetails?.personalDocuments,
                document: employeedetails?.personalDocuments?.map(({ _id, ...rest }) => rest),

                currAddress1: employeedetails?.currentAddress?.addLine1,
                currAddress2: employeedetails?.currentAddress?.addLine2,
                currCountry: employeedetails?.currentAddress?.country,
                currState: employeedetails?.currentAddress?.state,
                currCity: employeedetails?.currentAddress?.city,
                currPincode: employeedetails?.currentAddress?.pinCode,

                perAddress1: employeedetails?.permanentAddress?.addLine1,
                perAddress2: employeedetails?.permanentAddress?.addLine2,
                perCountry: employeedetails?.permanentAddress?.country,
                perState: employeedetails?.permanentAddress?.state,
                perCity: employeedetails?.permanentAddress?.city,
                perPincode: employeedetails?.permanentAddress?.pinCode,
                sameAddress: sameAddress,

                //  Other Details
                // previousExperience: employeedetails?.previousExperience,
                previousExperience: employeedetails?.previousExperience?.map(({ _id, ...rest }) => rest),
                // educationQualification: employeedetails?.educationQualification
                educationQualification: employeedetails?.educationQualification?.map(({ _id, ...rest }) => rest),
                familyDetails: employeedetails?.familyDetails?.map(({ _id, ...rest }) => rest),
                allDetails: response?.data,

            }))
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }

    //Handle Input value
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProfileInputs((prevProps) => ({ ...prevProps, [name]: value }))
        value?.trim().length && setError(pre => {
            delete pre[name]
            return ({ ...pre })
        })
    }

    const handleBlur = (event) => {
        const { name, value } = event.target
        !value?.trim()?.length && setError(pre => ({ ...pre, [name]: errorMessage[name] }))
    }
    // -***--------------------***-
    // Error Message
    const errorMessage = {
        first_name: requiredMessage("first name"),
        last_name: requiredMessage("last name"),
        email: requiredMessage("email"),
        mobile_no: requiredMessage("mobile number")
    }

    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!userProfileInputs.first_name) {
            errorObj['first_name'] = errorMessage['first_name']
        }
        // if (!userProfileInputs.last_name) {
        //     errorObj['last_name'] = errorMessage['last_name']
        // }
        // if (!userProfileInputs.email) {
        //   errorObj['email'] = errorMessage['email']
        // }
        if (!userProfileInputs.mobile_no?.trim()?.length) {
            errorObj['mobile_no'] = errorMessage['mobile_no']
        }
        setError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    const handleFileChange = async (event) => {
        const files = event.target.files;
        const fd = new FormData();
        if (files?.length > 5) {
          return toast.warn("You can only select up to 5 files.");
        }
    
        for (let i = 0; i < files?.length; i++) {
          const fileData = files[i];
          if (fileData.size > 100 * 1024 * 1024) {
            return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
          }
          fd.append(`files`, fileData);
        }
        fd.append('type', "PROFILEIMG");
        try {
          setSpinner(true);
          let response = await uploadFile(fd);
          toast.success(response.message);
          setProfileInputs(prev =>({...prev,profileImage: response.data[0].db_name}));
        } catch (error) {
          toast.error(error.message);
        }
        setSpinner(false);
      }
    // Handle submit data..!
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!isValid()) return
        setSpinner(true)
        try {
            let payload = {
                _id: user.userInfo._id,
                firstName: userProfileInputs.first_name,
                lastName: userProfileInputs.last_name,
                // email: userProfileInputs.email,
                mobile_no: userProfileInputs.mobile_no,
                image:userProfileInputs?.profileImage
            }
            // console.log(payload,'payload')
            // return 
            let response = await updateUserProfile(payload)
            // navigate('/')
            setIsEdit(false)
            getUserDetails()
            toast.success(response.message)
        } catch (err) {
            toast.error(err.message)
        }
        setSpinner(false)
    }
    return {
        data: {
            location,
            userDetails,
            error,
            userProfileInputs,
            spinner,
            isEdit,
            leavePolicyCount,
            activityData,
            teamMemberInputs,
            profilePath

        },
        methods: {
            handleSubmit,
            handleBlur,
            handleInputChange,
            setIsEdit,
            fetchMoreData,
            setCount,
            handleFileChange,
            setProfileInputs


        }
    }
}

export default UseAdminProfile
