import React from 'react';
import "./Calender.css"
import { Button, Switch } from '@mui/material';
import { HolidayType, ReminderList, CKEditorCustomToolbar, timeZonelist, YearList } from '../../../constants';
import useShiftAddUpdateHook from "./Hooks/useShiftAddUpdateHook"
import SelectInput from '../../../components/SelectInput'
import TextInput from '../../../components/TextField'
import MultipleSelect from "../../../components/MultipleSelect"
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import TimeInput from "../../../components/TimeInput"
import { Spinner } from '../../../components/Spinner/Spinner';
import { ToastContainer } from 'react-toastify';
import Modal from '../../../components/Modal'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import { useParams } from 'react-router-dom';

const ShiftForm = () => {
  const { data, methods } = useShiftAddUpdateHook()
  const { action } = useParams()
  console.log(action, 'action')
  const {
    spinner,
    shiftForm,
    shiftError,
    CompDaysObj,
    pubHoliDaysObj,
    restDaysObj,
    offDaysObj,
    calendarData,
    shiftId,
    errors,
    breakHoursInput,
    dayDetailModal
  } = data

  const {
    setShiftError,
    handleShiftInputChange,
    handleDateChange,
    handleCheckDays,
    focusHandle,
    bgHandle,
    handleOffDays,
    submit,
    Update,
    removeBreak,
    addMultipleBreak,
    setErrors,
    handleBreakInput,
    handleDayDetails,
    setDayDetailModal,
    setRenderYear,
    monthWorking
  } = methods

  // console.log(calendarData, 'calendarData')

  const renderData = (dataType) => {
    let hasRecords = false;
    // console.log(calendarData, 'calendarData')
    return (
      <div>
        {calendarData?.map((item, index) =>
          item?.days?.map((inner, innerIndex) => {
            if (inner?.details &&
              ((dataType === 'publicHoliday' && inner?.publicHoliday) ||
                (dataType === 'compensationDay' && inner?.compensationDay))) {
              hasRecords = true;
              return (
                <div key={`${index}-${innerIndex}`}>
                  <b dangerouslySetInnerHTML={{ __html: `${inner.date} <sup>${inner.dayOfWeek}</sup> ${item.monthName} ${item.year}` }} />
                  <p dangerouslySetInnerHTML={{ __html: inner.details }}></p>
                </div>
              );
            }
            return null;
          })
        )}
        {!hasRecords && (<div><b>N/A</b></div>)}
      </div>
    );
  };
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (<>
    {spinner && <Spinner />}
    {<ToastContainer />}
    {/* False update code */}
    <div className='bg-white p-4 mx-4'>
      <div className='row'>
        <div className="col-lg-12 my-2">
          <TextInput
            Lable="Shift Name"
            Name="shiftName"
            variant="outlined"
            ClassName="form-field-design "
            Require={true}
            HandleInputChange={(e) => handleShiftInputChange(e)}
            // onBlur={handleBlur}
            // disabled={action==='upgrade'}
            Disable={action === 'view'}
            Value={shiftForm.shiftName}
            width={100}
          //   Error={error.ticketId}
          />
          {shiftError?.shiftName && <span className='text-danger'>{shiftError.shiftName}</span>}
        </div>

        <div className="col-lg-12 my-2">
          <label htmlFor="">
            <sub>Description <span className='text-danger'>*</span></sub>
          </label>
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: CKEditorCustomToolbar,
            }}
            disabled={action === 'view'}
            data={shiftForm?.description}
            onChange={(event, editor) => {
              const data = editor.getData()
              handleShiftInputChange({ target: { name: 'description', value: data } });

              setShiftError((prevErrors) => {
                let updatedErrors = { ...prevErrors };
                updatedErrors = { description: '' };
                return updatedErrors;
              });
            }}
            onBlur={(event, editor) => {
              if (!shiftForm.description) {
                // Set the description error if it's empty on blur
                setShiftError((prevErrors) => {
                  let updatedErrors = { ...prevErrors };
                  updatedErrors = { description: 'Please enter description' };
                  return updatedErrors;
                });
              }
            }}
          />
          {shiftError?.description && <p className="text-danger">{shiftError?.description}</p>}
        </div>
          {/* <div className="col-lg-6 my-2">
            <SelectInput
              LabelButton
              Lable={'Year'}
              // multiple={true}
              Options={YearList}
              keyName={'title'}
              Name="year"
              SelectValue={shiftForm.year}
              HandleChange={(e) => { handleShiftInputChange(e); setRenderYear(true); }}
              // onBlur={handleBlur}
              Error={shiftError.year}
              variant={'outlined'}
              isRequired={true}
              disabled={action === 'view'}
            // disabled={shiftForm?.graceInAction == false}
            />
          </div> */}
        <div className="col-lg-6 my-2">
          <MultipleSelect
            label="Time Zone"
            isRequired={true}
            // multiple={true}
            options={timeZonelist}
            searchBy={"text"}
            keyName={"text"}
            name={"timeZone"}
            onChange={(e) => handleShiftInputChange({ target: { name: "timeZone", value: e } })}
            value={"text"}
            disabled={action === 'view'}
            selectedValues={shiftForm?.timeZone}
          // disabled={true}
          // error={error.timeZone}
          />
          {shiftError?.timeZone && <span className='text-danger'>{shiftError?.timeZone}</span>}
        </div>
        <div className="col-lg-6 my-2">
          <TimeInput
            disabled={action === 'view'}
            className="w-100 form-field-design"
            label={"Start Time"}
            value={shiftForm?.startTiming}
            handleChange={(date) => handleDateChange(date, 'startTiming')}
            isRequired={true}
          // disabled={true}
          />

          {/* <DesktopDateTimePicker
            className="w-100 form-field-design"
            isRequired={true}
            Label={'Start Time '}
            handleDateChange={(date) => handleDateChange(date, 'startTiming')}
            SelectedDate={shiftForm?.startTiming}
          /> */}
          {shiftError?.startTiming && <span className='text-danger'>{shiftError?.startTiming}</span>}
        </div>
        <div className="col-lg-6 my-2">

          <TimeInput
            disabled={action === 'view'}
            className="w-100 form-field-design"
            label={"End Time"}
            value={shiftForm?.endTiming}
            handleChange={(date) => handleDateChange(date, 'endTiming')}
            isRequired={true}
          // disabled={true}
          />
          {/* <DesktopDateTimePicker
            className="w-100 form-field-design"
            isRequired={true}
            Label={'End Time'}
            handleDateChange={(date) => handleDateChange(date, 'endTiming')}
            SelectedDate={shiftForm?.endTiming}
          /> */}
          {shiftError?.endTiming && <span className='text-danger'>{shiftError?.endTiming}</span>}
        </div>

        <div className="col-lg-6 my-2 position-relative mt-3">
          <div className="col-4 position-absolute text-end pe-3 pb-3 " style={{ right: '0', bottom: '20px', zIndex: '1' }}>
            <Switch name="graceInAction" checked={shiftForm?.graceInAction}
              onChange={(e) => handleShiftInputChange(e)} disabled={action === 'view'}
            />
          </div>
          <SelectInput
            LabelButton
            Lable={'Grace In'}
            // multiple={true}
            Options={ReminderList}
            keyName={'title'}
            Name="graceIn"
            SelectValue={shiftForm.graceIn}
            HandleChange={(e) => handleShiftInputChange(e)}
            // onBlur={handleBlur}
            // Error={error.tags}
            variant={'outlined'}
            // isRequired={true}
            disabled={shiftForm?.graceInAction === false || action === 'view'}
          />
        </div>
        <div className="col-lg-6 my-2 position-relative mt-3">
          <div className="col-4 position-absolute text-end pe-3 pb-3 " style={{ right: '0', bottom: '20px', zIndex: '1' }}>
            <Switch name="graceOutAction" checked={shiftForm?.graceOutAction}
              onChange={(e) => handleShiftInputChange(e)} disabled={action === 'view'}
            />
          </div>
          <SelectInput
            LabelButton
            Lable={'Grace Out'}
            // multiple={true}
            Options={ReminderList}
            keyName={'title'}
            Name="graceOut"
            SelectValue={shiftForm.graceOut}
            HandleChange={(e) => handleShiftInputChange(e)}
            // onBlur={handleBlur}
            // Error={error.tags}
            variant={'outlined'}
            // isRequired={true}
            disabled={shiftForm?.graceOutAction === false || action === 'view'}
          />
        </div>


        {
          action !== 'view' &&
          <div className="col-lg-12 my-3">
            <Button size='small' variant='contained' color='success' className='' onClick={() => { addMultipleBreak(0) }}>
              <AddIcon />add breaks
            </Button>
          </div>
        }

      </div>
      <div className="row">
        {/* <div style={{ height: `${breakHoursInput?.length > 0 ? "225px" : "0px"}`, width: "100%", overflowY: "auto" }}> */}
        {breakHoursInput?.map((item, index) => <>
          {<><div className="row">
            <div className="col-lg-12 my-2">
              <SelectInput
                LabelButton
                Lable={'Break Duration'}
                // multiple={true}
                Options={ReminderList}
                keyName={'title'}
                Name="duration"
                SelectValue={breakHoursInput[index].duration}
                HandleChange={(e) => handleBreakInput(e, index)}
                // onBlur={handleBlur}
                // Error={error.tags}
                variant={'outlined'}
                // isRequired={true}
                disabled={action === 'view'}
              />

              {/* <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  // isRequired={true}
                  Label={'Break Start '}
                  handleDateChange={(date) => handleBrakeTime(date, 'startTime', index)}
                  SelectedDate={breakHoursInput[index]?.startTime}
                /> */}
              {errors[index]?.duration && <span className='text-danger'>{errors[index]?.duration}</span>}
            </div>
            {/* <div className="col-lg-6 my-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  // isRequired={true}
                  Label={'Break End'}
                  handleDateChange={(date) => handleBrakeTime(date, 'endTime', index)}
                  SelectedDate={breakHoursInput[index]?.endTime}
                />
                {errors[index]?.endTime && <span className='text-danger'>{errors[index]?.endTime}</span>}
              </div> */}
            <div className="col-lg-12 my-2">
              <label htmlFor="">
                <sub>Description{/* <span className='text-danger'>*</span> */}
                </sub>
              </label>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: CKEditorCustomToolbar,
                }}
                disabled={action === 'view'}
                data={item?.reason}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleBreakInput({ target: { name: 'reason', value: data } }, index);
                  // Clear the reason error when there is a change
                  setErrors((prevErrors) => {
                    const updatedErrors = [...prevErrors];
                    updatedErrors[index] = { reason: '' };
                    return updatedErrors;
                  });
                }}
                onBlur={(event, editor) => {
                  if (!item.reason) {
                    // Set the reason error if it's empty on blur
                    setErrors((prevErrors) => {
                      const updatedErrors = [...prevErrors];
                      updatedErrors[index] = { reason: 'Please enter reason' };
                      return updatedErrors;
                    });
                  }
                }}
              />
              {errors[index]?.reason && <p className="text-danger">{errors[index]?.reason}</p>}
            </div>
          </div>
            <div className="col-lg-12 text-end mt-3">
              {/* <Button size='samll' className="dropDownButtons11 me-2" onClick={() => addMultipleBreak(index)}>
                    <AddIcon />
                  </Button> */}
              {/* {breakHoursInput.length > 1 && ( // Only show the button if there is more than one task */}
              {
                action !== 'view' &&
                <Button variant="outlined" color="error" onClick={() => removeBreak(index, item?._id)}>
                  <ClearIcon />
                </Button>
              }
              {/* )} */}
            </div>
          </>}
        </>)}
        {/* </div> */}
        {
          action === 'clone' ?
          <div className='col-12 d-flex justify-content-end my-2 '>
            <div  style={{width:'10%'}}>
            <Button className='my-3' sx={{ minWidth: "100%", }} variant="contained" onClick={submit} color='info'> submit</Button>

            </div>
          </div>
            :
            action !== 'view' &&
            <div className="col-12 my-3 d-flex justify-content-end my-2">
              <div style={{width:'10%'}}>
              {
                shiftId !== "add" ?
                  <Button sx={{ minWidth: "100%", }} variant="contained" onClick={Update} color='info'> Update</Button>
                  :
                  <Button sx={{ minWidth: "100%", }} variant="contained" onClick={submit} color='info'> submit</Button>
              }
              </div>
            </div>
        }
      </div>
      {/* <div className="row">
        <div className="col-6">
          <h4>Public holiday</h4>
          {renderData('publicHoliday')}
        </div>
        <div className="col-6">
          <h4>Compensatory Off</h4>
          {renderData('compensationDay')}
        </div>
      </div>
      <div >
        <div className='row mt-2'>
          <div className="col-10 ">
            <div className="row">
              {
                calendarData?.map((item, index) =>
                  <>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xlg-4 p-3">
                      <div className="border rounded h-100 pb-5">
                        <div className='calendar-header p-2'>
                          <span className='fw-bold'>{item?.year}</span>
                          <span className='fw-bold'>{item?.monthName}</span>
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                          <div className='calendar-week-header mt-2'>
                            {
                              weekdays.map((weekday) => {
                                return <div className="text-center fw-bold"><p className='m-0 p-0'>{weekday}</p></div>
                              })
                            }
                          </div>
                          <div className='d-flex flex-wrap'>
                            {
                              item?.days?.map((innerItem, innerIndex) => (
                                <div className='calendar-boxx' key={`checkbox-${index}-${innerIndex}`}>
                                  {innerItem.isDay ? <div></div> :
                                    <>
                                      <input
                                        type="checkbox"
                                        id={`checkbox-${index}-${innerIndex}`}
                                        onChange={(e) => {
                                          if (!innerItem?.publicHoliday && pubHoliDaysObj.pubHoliDay) {
                                            if (!innerItem?.compensationDay && !innerItem?.restDay && !innerItem?.offDay) {
                                              setDayDetailModal((prev) => ({
                                                ...prev,
                                                open: true,
                                                type: "PublicHoliDay",
                                                index,
                                                innerIndex,
                                                event: e,
                                              }));
                                            }
                                          }
                                          if (!innerItem?.compensationDay && CompDaysObj.compDay) {
                                            if (!innerItem?.publicHoliday && !innerItem?.restDay && !innerItem?.offDay) {
                                              setDayDetailModal((prev) => ({
                                                ...prev,
                                                open: true,
                                                type: "CompensationDay",
                                                index,
                                                innerIndex,
                                                event: e,
                                              }));
                                            }
                                          }
                                          handleCheckDays(e, index, innerIndex)
                                        }}
                                        style={{ display: 'none' }}
                                        disabled={
                                          !(
                                            restDaysObj.restDay ||
                                            offDaysObj.offDay ||
                                            CompDaysObj.compDay ||
                                            pubHoliDaysObj.pubHoliDay
                                          )
                                        }
                                        checked={(
                                          (innerItem?.compensationDay || CompDaysObj.compDay) ||
                                          (innerItem?.publicHoliday || pubHoliDaysObj.pubHoliDay) ||
                                          innerItem?.restDay ||
                                          innerItem?.offDay
                                        )}
                                      />
                                      <label
                                        htmlFor={`checkbox-${index}-${innerIndex}`}
                                        className="calendar-custom-date-box"
                                        style={{
                                          backgroundColor: bgHandle(innerItem),
                                          border: focusHandle(),
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <span className=''>{innerItem.date}</span>
                                      </label>
                                    </>
                                  }
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                      <div className='position-relative'>
                        <div className='fw-bold position-absolute bottom-0 start-0 ms-2 mb-2'>Working Days - {monthWorking(item?.monthName)}</div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
          <div className="col-2 position-relative">
            <div className=' border px-2' style={{ position: 'sticky', top: "11%", right: "2%" }}>
              <div className="row">
                <div className="col-12">
                  <h4 className='text-center my-2'>Set Off Days</h4>
                </div>
                <div className="col-12">
                  <Button sx={{ minWidth: "100%", background: "#f3963bdb" }} variant="contained" onClick={() => { if (action !== 'view') { handleOffDays(HolidayType.OffDays) } }}>Off Days</Button>
                </div>
                <div className="col-12">
                  <h3 className='text-center m-3'>{offDaysObj.selectedOffDays}</h3>
                </div>
                <div className="col-12">
                  <Button sx={{ minWidth: "100%", background: "#70afdde0" }} variant="contained" onClick={() => { if (action !== 'view') { handleOffDays(HolidayType.RestDays) } }} color='inherit'>Rest Days</Button>
                </div>
                <div className="col-12">
                  <h3 className='text-center m-3'>{restDaysObj.selectedRestDays}</h3>
                </div>
                <div className="col-12">
                  <Button sx={{ minWidth: "100%", background: "#78c223c4" }} variant="contained" onClick={() => { if (action !== 'view') { handleOffDays(HolidayType.PublicHolidays) } }} color='warning'>Public Holidays</Button>
                </div>
                <div className="col-12">
                  <h3 className='text-center m-3'>{pubHoliDaysObj.selectedPubHoliDays}</h3>
                </div>
                <div className="col-12">
                  <Button sx={{ minWidth: "100%", background: "#aa3b19cc" }} variant="contained" onClick={() => { if (action !== 'view') { handleOffDays(HolidayType.Compensatoryoff) } }} color='info'> Compensatory off</Button>
                </div>
                <div className="col-12">
                  <h3 className='text-center m-3'>{CompDaysObj.selectedCompDays}</h3>
                </div>
              </div>
              {
                action === 'clone' ?
                  <Button className='my-3' sx={{ minWidth: "100%", }} variant="contained" onClick={submit} color='info'> submit</Button>
                  :
                  action !== 'view' &&
                  <div className="col-12 my-3">
                    {
                      shiftId !== "add" ?
                        <Button sx={{ minWidth: "100%", }} variant="contained" onClick={Update} color='info'> Update</Button>
                        :
                        <Button sx={{ minWidth: "100%", }} variant="contained" onClick={submit} color='info'> submit</Button>
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      </div> */}
    </div>

    {/* Days Detail Modal */}
    {/* <Modal
      size="md"
      show={dayDetailModal.open}
      onHide={() => setDayDetailModal((prev) => ({ ...prev, open: false }))}
      headerContent={<h5 className="py-2">{"Holiday Description"}</h5>}
      bodyContent={
        <div className="">
          <div className="col-lg-12 my-2">
            <label htmlFor="">
              <sub>Description </sub>
            </label>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: CKEditorCustomToolbar,
              }}
              // data={dayDetailModal?.description}
              onChange={(event, editor) => {
                const data = editor.getData()
                // handleInputChange({ target: { name: 'description', value: data } })
                setDayDetailModal((prev) => ({ ...prev, description: data }))
              }}
            />
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button variant="outlined" onClick={() => setDayDetailModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
              No
            </Button>
            <Button variant="contained" className="ms-2" onClick={handleDayDetails}>
              Yes
            </Button>
          </div>
        </div>
      }
    /> */}

  </>
  );
}
export default ShiftForm